import styled from 'styled-components';

const BaseSidebar = styled.div`
  background-color: #fff;
  border-right: 1px solid #e5e5e5;
  height: 100%;
  position: fixed;
  top: var(--global-header-height);
  width: var(--global-sidebar-width);
  z-index: 999;
`;

const BaseSidebarCard = styled.div<{selected: boolean}>`
  align-items: center;
  background-color: ${props => props.selected? '#f7f7f7' : '#fff' };
  cursor: pointer;
  display: flex;
  height: 56px;
  padding-left: 20px;
  transition: background-color 0.3s ease-out;
  width: 180px;

  &:hover {
    background-color: #f7f7f7;
  }

  &:active {
    background-color: ${props => props.selected? '#f7f7f7' : '#a0a0a0' };
  }
`;

const SidebarCardLabel = styled.div`
  color: var(--di-text-primary-color);
  font-size: 15.5px;
`;

const SidebarCardIcon = styled.div`
  height: 24px;
  margin-right: 11px;
  width: 24px;
`;

export {
  BaseSidebar,
  BaseSidebarCard,
  SidebarCardLabel,
  SidebarCardIcon,
};
