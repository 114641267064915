import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useHistory,
} from 'react-router-dom';

import {
  DatabaseOrderGroup,
  ErrorTitle,
  ModelError,
  User,
  UserRole,
} from '../../api-client';

import {
  OrderGroupOperationType,
} from '../../constants';

import {
  useDatabaseOrderGroups,
} from '../../hooks';

import {
  Button,
  Dropdown,
  DropdownOption,
  LoadingSpinner,
  NotificationField,
  PageHeader,
  TextBox,
  Toast,
} from '../../components/atoms';

import {
  DeleteConfirmationModal,
  OrderGroupsListTable,
} from '../../components/molecules';

import {
  ButtonContainer,
  LoadingSpinnerContainer,
  NotificationFieldContainer,
  OrderGroupsListTableContainer,
  SearchFormContainer,
  SearchFormInputLabel,
  SearchFormsContainer,
  ToastContainer,
} from './style';

type HistoryLocationState = {
  orderGroupOperationType: OrderGroupOperationType | undefined
};

export interface ManageOrderGroupProps {
  /**
   * ログイン中のユーザー情報
   */
  currentUser: User,
}

export const ManageOrderGroup: React.FC<ManageOrderGroupProps> = ({
  currentUser,
}) => {

  const history = useHistory<HistoryLocationState>();

  const getToastMessage = (operationType: OrderGroupOperationType | undefined | null) => {
    switch (operationType) {
      case OrderGroupOperationType.Create:
        return '登録しました。';
      default:
        return '';
    }
  };

  const [deleteTargetOrderGroups, setDeleteTargetOrderGroups] = useState<Array<DatabaseOrderGroup>>([]);
  const [deleteOrderGroupsConfirmationVisible, setDeleteOrderGroupsConfirmationVisible] = useState(false);

  const orderGroupOperationType = history.location.state != null? history.location.state.orderGroupOperationType : null;
  const [toastMessage, setToastMessage] = useState(getToastMessage(orderGroupOperationType));
  const [toastVisible, setToastVisible] = useState(orderGroupOperationType != null);
  useEffect(() => {
    history.push({
      pathname: '/manage-order-group',
      state: {
        orderGroupOperationType: undefined,
      },
    });
  }, [history]);

  const [orderGroupsListReadonly, setOrderGroupsListReadonly] = useState(false);
  const [updateOrderGroupErrorMessage, setUpdateOrderGroupErrorMessage] = useState<string | undefined | null>(undefined);

  const {
    orderGroups,
    selectableOperators,
    getOrderGroups,
    putOrderGroups,
    deleteOrderGroups,
  } = useDatabaseOrderGroups();

  const dropdownOptions = (operators: Array<User>): Array<DropdownOption<number>>  => [
    {
      label: 'すべて',
      value: -1,
    },
    ...operators.map((operator) => ({
      label: operator.name,
      value: operator.id,
    }))
  ];

  const orderGroupNameSearchValue = useRef<string | null>(null);
  const operatorIdSearchValue = useRef<number | null>(null);

  return (
    <>
      <PageHeader
        pageTitle='発注グループ管理'
        backLink={false}
      />

      {currentUser.role === UserRole.Operator? (
        <ButtonContainer>
          <Button
            styleType='secondary'
            label='発注グループ登録'
            width={172}
            plusIcon={true}
            onClickHandler={() => history.push('/order-groups/new')}
            data-testid='create-order-group-button'
          />
        </ButtonContainer>
      ) : null}

      {updateOrderGroupErrorMessage != null? (
        <NotificationFieldContainer>
          <NotificationField
            type='error'
            bodyMessage={updateOrderGroupErrorMessage}
          />
        </NotificationFieldContainer>
      ) : null}

      {toastVisible === true? (
        <ToastContainer>
          <Toast
            message={toastMessage}
            onCloseHandler={() => setTimeout(() => setToastVisible(false), 300)}
          />
        </ToastContainer>
      ) : null}
      {deleteOrderGroupsConfirmationVisible === true? (
        <DeleteConfirmationModal
          message={`${deleteTargetOrderGroups.length}件の発注グループを削除しますか？`}
          disabled={false}
          onSubmitHandler={() => {
            setOrderGroupsListReadonly(true);
            setDeleteOrderGroupsConfirmationVisible(false);
            deleteOrderGroups(
              deleteTargetOrderGroups,
              orderGroupNameSearchValue.current != null? orderGroupNameSearchValue.current : undefined,
              operatorIdSearchValue.current != null && operatorIdSearchValue.current !== -1? operatorIdSearchValue.current : undefined,
            )
              ?.then(() => {
                setToastMessage('削除しました。');
                setToastVisible(true);
                setOrderGroupsListReadonly(false);
              })
              .catch((error: Response) => {
                if (error.status === 400) {
                  error.json()
                    .then((e: ModelError) => {
                      if (e.title === ErrorTitle._400BadRequestCanNotDeleteOrderGroupsHavingSkus) {
                        setUpdateOrderGroupErrorMessage(`${e.detail}は、SKUが紐付いている為削除できません。`);
                      } else if (e.title === ErrorTitle._400BadRequestCanNotDeleteOrderGroupsBelongingToOthers) {
                        // TODO: 担当者名を入れるようにする
                        setUpdateOrderGroupErrorMessage(`${e.detail}は、別担当者に紐付いている為削除できません。`);
                      } else {
                        setUpdateOrderGroupErrorMessage('不明なエラーです。システム管理者にお問い合わせください。');
                      }
                    });
                } else {
                  setUpdateOrderGroupErrorMessage('不明なエラーです。システム管理者にお問い合わせください。');
                }
                setOrderGroupsListReadonly(false);
              });
          }}
          onCloseHandler={() => {
            setDeleteOrderGroupsConfirmationVisible(false);
            setDeleteTargetOrderGroups([]);
          }}
        />
      ) : null}
      <SearchFormsContainer>
        <SearchFormContainer>
          <SearchFormInputLabel>発注グループ</SearchFormInputLabel>
          <TextBox
            id='search-input-order-group-name'
            type='text'
            width={200}
            height={40}
            onChangeHandler={(value) => {
              if (value === '') {
                orderGroupNameSearchValue.current = null;
              } else {
                orderGroupNameSearchValue.current = value;
              }
            }}
          />
        </SearchFormContainer>
        {currentUser.role === UserRole.Manager? (
          <SearchFormContainer>
            <SearchFormInputLabel>担当者</SearchFormInputLabel>
            <Dropdown
              options={dropdownOptions(selectableOperators != null? selectableOperators : [])}
              width={200}
              defaultValue={-2}
              onChangeHandler={(value) => operatorIdSearchValue.current = value}
            />
          </SearchFormContainer>
        ) : null}
        <Button
          styleType='tertiary'
          label='検索'
          width={76}
          onClickHandler={() => {
            getOrderGroups(
              orderGroupNameSearchValue.current != null? orderGroupNameSearchValue.current : undefined,
              operatorIdSearchValue.current != null && operatorIdSearchValue.current !== -1? operatorIdSearchValue.current : undefined,
            );
          }}
          data-testid='search-order-group-button'
        />
      </SearchFormsContainer>

      <OrderGroupsListTableContainer>
        {orderGroups != null && selectableOperators != null? (
          <>
            {orderGroups.length > 0? (
              <OrderGroupsListTable
                orderGroups={orderGroups}
                selectableOperators={selectableOperators}
                readonly={orderGroupsListReadonly}
                onlyOperatorsChangeable={currentUser.role === UserRole.Manager}
                onClickUpdateButtonHandler={(orderGroups) => {
                  setUpdateOrderGroupErrorMessage(null);
                  setOrderGroupsListReadonly(true);
                  putOrderGroups(
                    orderGroups,
                    orderGroupNameSearchValue.current != null? orderGroupNameSearchValue.current : undefined,
                    operatorIdSearchValue.current != null && operatorIdSearchValue.current !== -1? operatorIdSearchValue.current : undefined,
                  )
                    ?.then(() => {
                      setToastMessage('更新しました。');
                      setToastVisible(true);
                      setOrderGroupsListReadonly(false);
                    })
                    .catch((error: Response) => {
                      if (error.status === 400) {
                        error.json()
                          .then((e: ModelError) => {
                            if (e.title === ErrorTitle._400BadRequestOrderGroupNameIsDuplicated) {
                              setUpdateOrderGroupErrorMessage(`「${e.detail}」は既に登録されている為、別の発注グループ名で登録してください。`);
                            } else if (e.title === ErrorTitle._400BadRequestOrderGroupNameInParametersIsDuplicated) {
                              setUpdateOrderGroupErrorMessage(`同じ発注グループ名では登録できません。（${e.detail}）`);
                            } else {
                              setUpdateOrderGroupErrorMessage('不明なエラーです。システム管理者にお問い合わせください。');
                            }
                          });
                      } else {
                        setUpdateOrderGroupErrorMessage('不明なエラーです。システム管理者にお問い合わせください。');
                      }
                      setOrderGroupsListReadonly(false);
                    });
                }}
                onClickDeleteButtonHandler={(orderGroups) => {
                  setDeleteTargetOrderGroups(orderGroups);
                  setDeleteOrderGroupsConfirmationVisible(true);
                }}
              />
            ): (
              <span>検索結果がありません。</span>
            )}
          </>
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
      </OrderGroupsListTableContainer>
    </>
  );
};
