import styled from 'styled-components';

const DescriptionContainer = styled.div`
  height: 40px;
  margin-left: auto;
  margin-bottom: 30px;
  margin-right: auto;
  margin-top: 45px;
  width: 436px;
`;

const ErrorMessageContainer = styled.div`
  color: var(--di-text-error-color);
  margin-left: auto;
  margin-right: auto;
  width: 427px;
`;

const LinkContainer = styled.div`
  margin-top: 20px;
  text-align: right;
`;

const LogoContainer = styled.div`
  margin: auto;
  width: 339px;
`;

const MailSentMessageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 427px;
`;

const PasswordResetMailFormContainer = styled.div`
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 427px;
`;

export {
  DescriptionContainer,
  ErrorMessageContainer,
  LinkContainer,
  LogoContainer,
  MailSentMessageContainer,
  PasswordResetMailFormContainer,
};
