import firebase from 'firebase/app';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { updateSKUs } from './useSKUs';
import {
  SKU,
  ProductWithDetail,
  ProductWithDetailShortageToleranceRankEnum,
  SKUShortageToleranceRankEnum,
} from '../api-client';

const convShortageToleranceRankType = (rank: ProductWithDetailShortageToleranceRankEnum): SKUShortageToleranceRankEnum => {
  switch (rank) {
    case ProductWithDetailShortageToleranceRankEnum.S:
      return SKUShortageToleranceRankEnum.S;
    case ProductWithDetailShortageToleranceRankEnum.A:
      return SKUShortageToleranceRankEnum.A;
    case ProductWithDetailShortageToleranceRankEnum.B:
      return SKUShortageToleranceRankEnum.B;
    case ProductWithDetailShortageToleranceRankEnum.C:
      return SKUShortageToleranceRankEnum.C;
  }
};

const getThisMonday = () => {
  return dayjs().day(1);
};

function useSKU(defaultStartDate: Date | null, defaultEndDate: Date | null) {
  const [start, setStart] = useState<Date|null>(defaultStartDate);
  const [end, setEnd] = useState<Date|null>( defaultEndDate);
  const demandAggregation = useMemo(() => ({
    startDate: start,
    endDate: end
  }), [start, end]);

  const isDemandAggregationValid = useMemo(() => {
    const isDemandAggregationNull = start === null && end === null;
    const isValidOrder = (start !== null &&
      end !== null &&
      start < end);
    return isDemandAggregationNull || isValidOrder;
  }, [start, end]);

  const startDateMin = () => {
    return getThisMonday().subtract(7*53, 'days').format('YYYY-MM-DD');
  };

  const startDateMax = useMemo(() => {
    if (end) {
      return dayjs(end).subtract(7, 'days').format('YYYY-MM-DD');
    } else {
      return undefined;
    }
  }, [end]);

  const endDateMin = useMemo(() => {
    if (start) {
      return dayjs(start).add(7, 'days').format('YYYY-MM-DD');
    } else {
      return getThisMonday().subtract(7*53, 'days').format('YYYY-MM-DD');
    }
  }, [start]);

  const endDateMax = () => {
    return getThisMonday().add(7*53, 'days').format('YYYY-MM-DD');
  };

  const errorMessage = useMemo(() => {
    const requiredMsg='日付を選択して下さい';
    return {
      start: (end != null && start === null) ? requiredMsg : null,
      end: (start != null && end === null) ? requiredMsg : null
    };
  }, [start, end]);

  const changeStartDate = (startDate: string | null) => {
    if (startDate) {
      setStart(new Date(startDate));
    } else {
      setStart(null);
    }
  };

  const changeEndDate = (endDate: string | null) => {
    if (endDate) {
      setEnd(new Date(endDate));
    } else {
      setEnd(null);
    }
  };

  const putSKU = async (product: ProductWithDetail, orderGroupID: number) => {
    if (!isDemandAggregationValid) return;
    const token =  await firebase.auth().currentUser?.getIdToken(true);
    if (!token) return;
    const sku: SKU  = {
      code: product.code,
      name: product.name,
      specName: product.specName,
      supplierName: product.specName,
      quantityPerCase: product.quantityPerCase,
      m3: product.m3,
      orderGroupID,
      minNumberOfProductsPerDelivery: product.minNumberOfProductsPerDelivery,
      price: product.price,
      shortageToleranceRank: convShortageToleranceRankType(product.shortageToleranceRank),
      endOfSalesAt: product.endOfSalesAt,
      numberOfStores: product.numberOfStores,
      demandAggregation: {
        startDate: start,
        endDate: end,
      }
    };
    const skus: SKU[] = [sku];
    await updateSKUs(skus, token);
  };

  return {
    demandAggregation,
    start,
    end,
    errorMessage,
    isDemandAggregationValid,
    startDateMax,
    startDateMin,
    endDateMin,
    endDateMax,
    changeStartDate,
    changeEndDate,
    putSKU,
  };
}

export {
  useSKU,
};
