/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MdWeekDate,
    MdWeekDateFromJSON,
    MdWeekDateFromJSONTyped,
    MdWeekDateToJSON,
    ShipmentReason,
    ShipmentReasonFromJSON,
    ShipmentReasonFromJSONTyped,
    ShipmentReasonToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutDestockingOrderGroupRequestInventoryPlanPerMdWeek
 */
export interface PutDestockingOrderGroupRequestInventoryPlanPerMdWeek {
    /**
     * 
     * @type {MdWeekDate}
     * @memberof PutDestockingOrderGroupRequestInventoryPlanPerMdWeek
     */
    mdWeekDate: MdWeekDate;
    /**
     * 
     * @type {ShipmentReason}
     * @memberof PutDestockingOrderGroupRequestInventoryPlanPerMdWeek
     */
    shipmentReason: ShipmentReason;
    /**
     * 出荷加減数
     * @type {number}
     * @memberof PutDestockingOrderGroupRequestInventoryPlanPerMdWeek
     */
    shipmentQuantity: number | null;
    /**
     * 発注確定数
     * @type {number}
     * @memberof PutDestockingOrderGroupRequestInventoryPlanPerMdWeek
     */
    confirmedOrderQuantity: number | null;
    /**
     * 店頭在庫基準数
     * @type {number}
     * @memberof PutDestockingOrderGroupRequestInventoryPlanPerMdWeek
     */
    storeInventoryQuantityStandard: number | null;
    /**
     * 調整アラートを停止するか
     * @type {boolean}
     * @memberof PutDestockingOrderGroupRequestInventoryPlanPerMdWeek
     */
    isAdjustmentAlertStopped: boolean;
    /**
     * 販売予測数(ユーザー)
     * @type {number}
     * @memberof PutDestockingOrderGroupRequestInventoryPlanPerMdWeek
     */
    expectedSalesAmountByUser?: number | null;
}

export function PutDestockingOrderGroupRequestInventoryPlanPerMdWeekFromJSON(json: any): PutDestockingOrderGroupRequestInventoryPlanPerMdWeek {
    return PutDestockingOrderGroupRequestInventoryPlanPerMdWeekFromJSONTyped(json, false);
}

export function PutDestockingOrderGroupRequestInventoryPlanPerMdWeekFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutDestockingOrderGroupRequestInventoryPlanPerMdWeek {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mdWeekDate': MdWeekDateFromJSON(json['mdWeekDate']),
        'shipmentReason': ShipmentReasonFromJSON(json['shipmentReason']),
        'shipmentQuantity': json['shipmentQuantity'],
        'confirmedOrderQuantity': json['confirmedOrderQuantity'],
        'storeInventoryQuantityStandard': json['storeInventoryQuantityStandard'],
        'isAdjustmentAlertStopped': json['isAdjustmentAlertStopped'],
        'expectedSalesAmountByUser': !exists(json, 'expectedSalesAmountByUser') ? undefined : json['expectedSalesAmountByUser'],
    };
}

export function PutDestockingOrderGroupRequestInventoryPlanPerMdWeekToJSON(value?: PutDestockingOrderGroupRequestInventoryPlanPerMdWeek | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mdWeekDate': MdWeekDateToJSON(value.mdWeekDate),
        'shipmentReason': ShipmentReasonToJSON(value.shipmentReason),
        'shipmentQuantity': value.shipmentQuantity,
        'confirmedOrderQuantity': value.confirmedOrderQuantity,
        'storeInventoryQuantityStandard': value.storeInventoryQuantityStandard,
        'isAdjustmentAlertStopped': value.isAdjustmentAlertStopped,
        'expectedSalesAmountByUser': value.expectedSalesAmountByUser,
    };
}


