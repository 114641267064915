import React from 'react';

import {
  BaseLabel
} from './style';

type LabelColor = 'green' | 'yellow' | 'grey' | 'blue' | 'red';

export interface LabelProps {
  /**
   * 色
   */
  color: LabelColor

  /**
   * テキスト
   */
  text: string,

  /**
   * 幅
   */
  width: number
}

export const Label: React.FC<LabelProps> = ({
  color,
  text,
  width
}) => (
  <BaseLabel color={color} width={width}>{text}</BaseLabel>
);
