import React from 'react';

import {
  BaseLoadingSpinner
} from './style';

export interface LoadingSpinnerProps {}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = () => (
  <BaseLoadingSpinner data-testid='loader' />
);
