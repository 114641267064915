import styled from 'styled-components';

const BaseUsersTable = styled.table`
  border: solid 1px var(--di-table-border-color);
  width: 100%;
`;

const UserName = styled.div`
  overflow-wrap: anywhere;
  width: 200px;
`;

const UsersTableBodyRow = styled.tr<{clickable: boolean}>`
  background-color: #fff;
  border-top: solid 1px var(--di-table-border-color);
  cursor: ${props => props.clickable === true? 'pointer' : 'default'};
  transition: background-color 0.3s 0s ease;

  &:hover {
    background-color: var(--di-table-body-bg-hover-color);
  }
`;

const UsersTableData = styled.td`
  height: 49px;
  padding-bottom: 0;
  padding-left: 30px;
  padding-top: 0;
`;

const UsersTableHeader = styled.th<{width?: number}>`
  height: 49px;
  padding-bottom: 0;
  padding-left: 30px;
  padding-top: 0;
  text-align: left;
  width: ${props => props.width? `${props.width}px` : 'auto'};
`;

export {
  BaseUsersTable,
  UserName,
  UsersTableBodyRow,
  UsersTableData,
  UsersTableHeader,
};
