import styled from 'styled-components';

const BaseLoadingSpinner = styled.div`
  animation: loading 1.2048192771084336s infinite linear;
  -webkit-animation: loading 1.2048192771084336s infinite linear;
  border-color: #ccc #ccc #ccc transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  height: 54px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  width: 54px;

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export {
  BaseLoadingSpinner,
};
