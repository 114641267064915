import styled from 'styled-components';

const BaseGlobalHeader = styled.div`
  align-items: center;
  background-color: #0f273e;
  display: flex;
  height: var(--global-header-height);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;
`;

const LogoContainer = styled.div`
  height: 25px;
  margin-left: 20px;
  width: 202.5px;
`;

const UserIconContainer = styled.div`
  height: 24px;
  width: 24px;
`;

const UserIconNameContainerOuter = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 36px;
  justify-content: center;
  margin-left: auto;
  margin-right: 16px;
  position: relative;
  transition: background-color 0.3s ease-out;
  width: 170px;

  &:hover {
    background-color: #23384d;
  }

  &:active {
    background-color: #4a5b6c;
  }
`;

const UserIconNameContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const UserMenuContainer = styled.div`
  position: absolute;
  right: 0;
  top: 40px;
`;

const UserNameContainer = styled.div`
  color: #fff;
  margin-left: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
`;

export {
  BaseGlobalHeader,
  LogoContainer,
  UserIconContainer,
  UserIconNameContainer,
  UserIconNameContainerOuter,
  UserMenuContainer,
  UserNameContainer
};
