/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderMdWeekDate,
    OrderMdWeekDateFromJSON,
    OrderMdWeekDateFromJSONTyped,
    OrderMdWeekDateToJSON,
    ProductWithOrder,
    ProductWithOrderFromJSON,
    ProductWithOrderFromJSONTyped,
    ProductWithOrderToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderSummary
 */
export interface OrderSummary {
    /**
     * 
     * @type {Array<ProductWithOrder>}
     * @memberof OrderSummary
     */
    products: Array<ProductWithOrder>;
    /**
     * 
     * @type {Array<OrderMdWeekDate>}
     * @memberof OrderSummary
     */
    orderMdWeekDates: Array<OrderMdWeekDate>;
}

export function OrderSummaryFromJSON(json: any): OrderSummary {
    return OrderSummaryFromJSONTyped(json, false);
}

export function OrderSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'products': ((json['products'] as Array<any>).map(ProductWithOrderFromJSON)),
        'orderMdWeekDates': ((json['orderMdWeekDates'] as Array<any>).map(OrderMdWeekDateFromJSON)),
    };
}

export function OrderSummaryToJSON(value?: OrderSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'products': ((value.products as Array<any>).map(ProductWithOrderToJSON)),
        'orderMdWeekDates': ((value.orderMdWeekDates as Array<any>).map(OrderMdWeekDateToJSON)),
    };
}


