import firebase from 'firebase/app';
import * as Sentry from "@sentry/react";
import { useEffect, useState } from 'react';

import {
  DefaultApi,
  Configuration,
  GetDestockingOrderGroupCsvRequest,
  GetDestockingOrderGroupRequest,
  OrderGroup,
  OrderSummary,
  OrderDetail,
  ProductWithDetailShortageToleranceRankEnum,
  PutDestockingOrderGroupOperationRequest,
  PutDestockingOrderGroupOrderOperationRequest,
  PutDestockingOrderGroupOrderRequest,
  PutDestockingOrderGroupRequest,
  PutDestockingOrderGroupRequestInventoryPlanPerMdWeek,
  PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum,
  PutDestockingOrderGroupIncomingMdWeekBackwardRequest,
  PutDestockingOrderGroupIncomingMdWeekForwardRequest,
} from '../api-client';

import { putSkuMeta } from '../requests/putSkuMeta';

type Destocking = {
  orderGroup: OrderGroup | undefined,
  orderSummary: OrderSummary | undefined,
  orderDetail: OrderDetail | undefined
};

export const useDestocking = (orderGroupId: number) => {
  const [statusCode, setStatusCode] = useState<number | undefined>(undefined);
  const [destocking, setDestocking] = useState<Destocking>({
    orderGroup: undefined,
    orderSummary: undefined,
    orderDetail: undefined
  });

  const convShortageToleranceRankType = (rank: ProductWithDetailShortageToleranceRankEnum): PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum => {
    switch (rank) {
      case ProductWithDetailShortageToleranceRankEnum.S:
        return PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum.S;
      case ProductWithDetailShortageToleranceRankEnum.A:
        return PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum.A;
      case ProductWithDetailShortageToleranceRankEnum.B:
        return PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum.B;
      case ProductWithDetailShortageToleranceRankEnum.C:
        return PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum.C;
    }
  };

  useEffect(() => {
    firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const param: GetDestockingOrderGroupRequest = {
          orderGroupId
        };
        return api.getDestockingOrderGroup(param);
      })
      .then((response) => {
        setDestocking({
          orderGroup: response.orderGroup,
          orderSummary: response.orderSummary,
          orderDetail: response.orderDetail
        });

        // 正常系は200にしておく.
        setStatusCode(200);
      })
      .catch((err: Response) => {
        Sentry.captureException(err);
        setStatusCode(err.status);
      });
  }, [orderGroupId]);

  const putDestocking = (orderGroup: OrderGroup, orderDetail: OrderDetail) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const bodyParam: PutDestockingOrderGroupRequest = {
          orderGroup: {
            leadtime: orderGroup.leadtime,
            numberOfOrderCycles: orderGroup.numberOfOrderCycles,
            numberOfOrderCycleWeeks: orderGroup.numberOfOrderCycleWeeks,
            plannedAt: orderGroup.plannedAt,
            useSubcategoryPrediction: orderGroup.useSubcategoryPrediction,
          },
          products: orderDetail.products.map((product) => {
            const inventoryPlanPerMdWeek: { [key: string]: PutDestockingOrderGroupRequestInventoryPlanPerMdWeek; } = {};
            Object.keys(product.inventoryPlanPerMdWeek).forEach((mdWeek) => {
              inventoryPlanPerMdWeek[mdWeek] = {
                mdWeekDate: product.inventoryPlanPerMdWeek[mdWeek].mdWeekDate,
                shipmentReason: product.inventoryPlanPerMdWeek[mdWeek].shipmentReason,
                shipmentQuantity: product.inventoryPlanPerMdWeek[mdWeek].shipmentQuantity,
                confirmedOrderQuantity: product.inventoryPlanPerMdWeek[mdWeek].confirmedOrderQuantity,
                storeInventoryQuantityStandard: product.inventoryPlanPerMdWeek[mdWeek].storeInventoryQuantityStandard,
                isAdjustmentAlertStopped: product.inventoryPlanPerMdWeek[mdWeek].isAdjustmentAlertStopped,
                expectedSalesAmountByUser: product.inventoryPlanPerMdWeek[mdWeek].expectedSalesAmountByUser
              };
            });
            return {
              code: product.code,
              shortageToleranceRank: convShortageToleranceRankType(product.shortageToleranceRank),
              inventoryPlanPerMdWeek,
            };
          })
        };
        const param: PutDestockingOrderGroupOperationRequest = {
          orderGroupId,
          bodyParam,
        };
        return api.putDestockingOrderGroup(param)
          .then(() => {
            const conf = new Configuration({
              basePath: process.env.REACT_APP_API_PATH,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const api = new DefaultApi(conf);
            const param: GetDestockingOrderGroupRequest = {
              orderGroupId
            };
            return api.getDestockingOrderGroup(param);
          }).then((response) => {
            setDestocking({
              orderGroup: response.orderGroup,
              orderSummary: response.orderSummary,
              orderDetail: response.orderDetail
            });

            // 正常系は200にしておく.
            setStatusCode(200);
          })
          .catch((error: Response) => {
            Sentry.captureException(error);
            setStatusCode(error.status);
          });
      })
      .catch((err: Response) => {
        Sentry.captureException(err);
        setStatusCode(err.status);
      });
  };

  const putDestockingOrder = (orderGroup: OrderGroup) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const bodyParam: PutDestockingOrderGroupOrderRequest = {
          plannedAt: orderGroup.plannedAt,
        };
        const param: PutDestockingOrderGroupOrderOperationRequest = {
          orderGroupId,
          bodyParam,
        };

        return api.putDestockingOrderGroupOrder(param)
          .then(() => {
            const conf = new Configuration({
              basePath: process.env.REACT_APP_API_PATH,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const api = new DefaultApi(conf);
            const param: GetDestockingOrderGroupRequest = {
              orderGroupId
            };
            return api.getDestockingOrderGroup(param);
          }).then((response) => {
            setDestocking({
              orderGroup: response.orderGroup,
              orderSummary: response.orderSummary,
              orderDetail: response.orderDetail
            });

            // 正常系は200にしておく.
            setStatusCode(200);
          })
          .catch((err: Response) => {
            Sentry.captureException(err);
            setStatusCode(err.status);
          });
      })
      .catch((err: Response) => {
        Sentry.captureException(err);
        setStatusCode(err.status);
      });
  };

  const getCSVFile = (orderGroupId: number) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const params: GetDestockingOrderGroupCsvRequest = {
          orderGroupId,
        };
        const api = new DefaultApi(conf);
        return api.getDestockingOrderGroupCsvRaw(params)
          .then((response) => {
            return response.value()
              .then((blob) => {
                const disposition = response.raw.headers.get('content-disposition');
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition != null ? disposition : '');

                let filename;
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, '');
                  filename = decodeURI(filename);
                }

                return { filename, blob };
              });
          });
      });
  };

  const putDestockingOrderGroupIncomingMdWeekBackward = (orderGroup: OrderGroup, incomingMdWeek: number, incomingMdYear: number, incomingDate: Date) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const bodyParam: PutDestockingOrderGroupIncomingMdWeekBackwardRequest = {
          targetMdWeekDate: {
            mdWeekNum: incomingMdWeek,
            mdYear: incomingMdYear,
            date: incomingDate,
          },
          plannedAt: orderGroup.plannedAt,
        };
        const param = {
          orderGroupId,
          bodyParam,
        };
        return api.putDestockingOrderGroupIncomingMdWeekBackward(param)
          .then(() => {
            const conf = new Configuration({
              basePath: process.env.REACT_APP_API_PATH,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const api = new DefaultApi(conf);
            const param: GetDestockingOrderGroupRequest = {
              orderGroupId
            };
            return api.getDestockingOrderGroup(param);
          }).then((response) => {
            setDestocking({
              orderGroup: response.orderGroup,
              orderSummary: response.orderSummary,
              orderDetail: response.orderDetail
            });

            // 正常系は200にしておく.
            setStatusCode(200);
          })
          .catch((error: Response) => {
            setStatusCode(error.status);
          });
      });
  };

  const putDestockingOrderGroupIncomingMdWeekForward = (orderGroup: OrderGroup, incomingMdWeek: number, incomingMdYear: number, incomingDate: Date) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const bodyParam: PutDestockingOrderGroupIncomingMdWeekForwardRequest = {
          targetMdWeekDate: {
            mdWeekNum: incomingMdWeek,
            mdYear: incomingMdYear,
            date: incomingDate,
          },
          plannedAt: orderGroup.plannedAt,
        };
        const param = {
          orderGroupId,
          bodyParam,
        };
        return api.putDestockingOrderGroupIncomingMdWeekForward(param)
          .then(() => {
            const conf = new Configuration({
              basePath: process.env.REACT_APP_API_PATH,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const api = new DefaultApi(conf);
            const param: GetDestockingOrderGroupRequest = {
              orderGroupId
            };
            return api.getDestockingOrderGroup(param);
          }).then((response) => {
            setDestocking({
              orderGroup: response.orderGroup,
              orderSummary: response.orderSummary,
              orderDetail: response.orderDetail
            });

            // 正常系は200にしておく.
            setStatusCode(200);
          })
          .catch((error: Response) => {
            setStatusCode(error.status);
          });
      });
  };

  const getDestocking = async () => {
    const token = await firebase.auth().currentUser?.getIdToken(true);
    const conf = new Configuration({
      basePath: process.env.REACT_APP_API_PATH,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const api = new DefaultApi(conf);
    const response = await api.getDestockingOrderGroup({ orderGroupId }).catch((err: Response) => {
      Sentry.captureException(err);
      setStatusCode(err.status);
    });
    if (response) {
      setDestocking(response);
      setStatusCode(200);
    }
  };

  const updateSkuMeta = async (productCode: string, comment: string) => {
    await putSkuMeta(productCode, comment);
    await getDestocking();
  };

  return {
    destocking,
    statusCode,
    setDestocking,
    putDestocking,
    putDestockingOrder,
    getCSVFile,
    putDestockingOrderGroupIncomingMdWeekBackward,
    putDestockingOrderGroupIncomingMdWeekForward,
    getDestocking,
    updateSkuMeta,
  };
};
