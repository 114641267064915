import styled from 'styled-components';

const AlertPanelContainer = styled.div`
  margin-left: 48px;
  margin-top: 10px;
`;

const CurrentMdWeek = styled.div`
  color: #999;
  margin-top: 17px;
  padding-right: 32px;
  text-align: right;
`;

const ErrorMessagePanelContainer = styled.div`
  height: calc(100% - 60px);
  padding-bottom: 30px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 30px;
`;

const LoadingSpinnerContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  height: 145px;
  width: 560px;
`;

const AlertPanelTitle = styled.h2`
  margin-bottom: 8px;
`;

export {
  AlertPanelContainer,
  AlertPanelTitle,
  CurrentMdWeek,
  ErrorMessagePanelContainer,
  LoadingSpinnerContainer,
};
