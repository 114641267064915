/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MdWeekDate,
    MdWeekDateFromJSON,
    MdWeekDateFromJSONTyped,
    MdWeekDateToJSON,
    OrderMdWeekDateAllOf,
    OrderMdWeekDateAllOfFromJSON,
    OrderMdWeekDateAllOfFromJSONTyped,
    OrderMdWeekDateAllOfToJSON,
} from './';

/**
 * 未発注・発注済み週
 * @export
 * @interface OrderMdWeekDate
 */
export interface OrderMdWeekDate {
    /**
     * 
     * @type {number}
     * @memberof OrderMdWeekDate
     */
    mdYear: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMdWeekDate
     */
    mdWeekNum: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderMdWeekDate
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderMdWeekDate
     */
    totalNumberOfOrders: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMdWeekDate
     */
    totalM3: number;
    /**
     * 入庫週を前倒しできるかどうか
     * @type {boolean}
     * @memberof OrderMdWeekDate
     */
    canBeMovedForward: boolean;
    /**
     * 入庫週を後ろ倒しできるかどうか
     * @type {boolean}
     * @memberof OrderMdWeekDate
     */
    canBeMovedBackward: boolean;
}

export function OrderMdWeekDateFromJSON(json: any): OrderMdWeekDate {
    return OrderMdWeekDateFromJSONTyped(json, false);
}

export function OrderMdWeekDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMdWeekDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mdYear': json['mdYear'],
        'mdWeekNum': json['mdWeekNum'],
        'date': (new Date(json['date'])),
        'totalNumberOfOrders': json['totalNumberOfOrders'],
        'totalM3': json['totalM3'],
        'canBeMovedForward': json['canBeMovedForward'],
        'canBeMovedBackward': json['canBeMovedBackward'],
    };
}

export function OrderMdWeekDateToJSON(value?: OrderMdWeekDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mdYear': value.mdYear,
        'mdWeekNum': value.mdWeekNum,
        'date': (value.date.toISOString().substr(0,10)),
        'totalNumberOfOrders': value.totalNumberOfOrders,
        'totalM3': value.totalM3,
        'canBeMovedForward': value.canBeMovedForward,
        'canBeMovedBackward': value.canBeMovedBackward,
    };
}


