import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { User } from '../../../api-client';

import {
  Logo,
  UserMenu
} from '../../atoms';

import {
  BaseGlobalHeader,
  LogoContainer,
  UserIconContainer,
  UserIconNameContainer,
  UserIconNameContainerOuter,
  UserMenuContainer,
  UserNameContainer,
} from './style';

export interface GlobalHeaderProps  {
  /**
   * ログイン中のユーザー
   */
  user: User;

  /**
   * ログアウトボタンがクリックされた時に呼び出されるハンドラー
   */
  logout: () => void;
}

export const GlobalHeader: React.FC<GlobalHeaderProps> = ({
  user,
  logout
}) => {

  const [userMenuVisible, setUserMenuVisible] = useState(false);

  const closeUserMenu = () => setUserMenuVisible(false);

  useEffect(() => {
    document.addEventListener('click', closeUserMenu);
    return function cleanup() {
      document.removeEventListener('click', closeUserMenu);
    };
  }, []);

  return (
    <BaseGlobalHeader>
      <Link to='/'>
        <LogoContainer>
          <Logo logoColor='white' />
        </LogoContainer>
      </Link>
      <UserIconNameContainerOuter
        onClick={(event) => {
          setUserMenuVisible(!userMenuVisible);
          event.stopPropagation();
        }}
      >
        <UserIconNameContainer>
          <UserIconContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#87939F"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
              />
            </svg>
          </UserIconContainer>
          <UserNameContainer>{user.name}</UserNameContainer>
        </UserIconNameContainer>
        <UserMenuContainer>
          {userMenuVisible?
            <UserMenu
              user={user}
              logout={() => logout()}
            />
            : null}
        </UserMenuContainer>
      </UserIconNameContainerOuter>
    </BaseGlobalHeader>
  );
};
