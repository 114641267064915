import React from 'react';

import {
  LoginPanelContainer,
  LoginPanel
} from './style';

export interface BeforeLoginProps {}

export const BeforeLogin: React.FC<BeforeLoginProps> = ({...props}) => (
  <LoginPanelContainer>
    <LoginPanel
      data-testid="login-panel"
      {...props}
    />
  </LoginPanelContainer>
);
