/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MdWeekDate,
    MdWeekDateFromJSON,
    MdWeekDateFromJSONTyped,
    MdWeekDateToJSON,
    OrderAllOf,
    OrderAllOfFromJSON,
    OrderAllOfFromJSONTyped,
    OrderAllOfToJSON,
} from './';

/**
 * 発注数
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    mdYear: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    mdWeekNum: number;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    numberOfOrders: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    m3: number;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    isNeedAdjustment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    isNeedOrder: boolean;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mdYear': json['mdYear'],
        'mdWeekNum': json['mdWeekNum'],
        'date': (new Date(json['date'])),
        'numberOfOrders': json['numberOfOrders'],
        'm3': json['m3'],
        'isNeedAdjustment': json['isNeedAdjustment'],
        'isNeedOrder': json['isNeedOrder'],
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mdYear': value.mdYear,
        'mdWeekNum': value.mdWeekNum,
        'date': (value.date.toISOString().substr(0,10)),
        'numberOfOrders': value.numberOfOrders,
        'm3': value.m3,
        'isNeedAdjustment': value.isNeedAdjustment,
        'isNeedOrder': value.isNeedOrder,
    };
}


