import React from 'react';

import {
  BaseTooltip
} from './style';


export interface TooltipProps {
  /**
   * テスト用の id に使用する
   */
  id?: string

  /**
   * 表示するテキスト
   */
  text: string

  /**
   * 上からの絶対位置
   */
  top: number

  /**
   * 横からの絶対位置
   */
  left?: number

  /**
   * 幅
   */
  width: number
}

export const Tooltip: React.FC<TooltipProps> = ({
  id = '',
  text,
  top,
  left = 0,
  width
}) => (
  <BaseTooltip
    top={top}
    left={left}
    width={width}
    data-testid={id}
  >{text}</BaseTooltip>
);
