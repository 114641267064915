import React from 'react';
import {
  Link,
  useLocation
} from 'react-router-dom';
import firebase from 'firebase/app';

import {
  Logo,
  NotificationField,
} from '../../components/atoms';

import {
  PasswordResetForm,
} from '../../components/molecules';

import {
  BeforeLogin
} from '../../templates';

import {
  useVerifyPasswordResetCode,
} from '../../hooks';

import {
  DescriptionContainer,
  LinkContainer,
  LogoContainer,
  NotificationFieldContainer,
  PasswordResetFormContainer
} from './style';

export interface PasswordResetProps {}

export const PasswordReset: React.FC<PasswordResetProps> = () => {

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const oobCode = query.get('oobCode');
  const mode = query.get('mode');

  const {
    isLoading,
    isPasswordResetFinished,
    setIsPasswordResetFinished,
    errorMessage,
    setErrorMessage
  } = useVerifyPasswordResetCode(oobCode? oobCode : '', mode? mode : '');

  // TODO: Provide loading UI
  return isLoading === true? <div /> : (
    <BeforeLogin>
      <LogoContainer>
        <Logo logoColor='black' />
      </LogoContainer>
      <PasswordResetFormContainer>
        {errorMessage !== ''? (
          <div>
            <NotificationFieldContainer>
              <NotificationField type='error' bodyMessage={errorMessage} />
            </NotificationFieldContainer>
            <LinkContainer>
              <Link to='/password-reset'>設定用メールの再送信</Link>
            </LinkContainer>
          </div>
        ): (
          <div>
            {
              isPasswordResetFinished? (
                <NotificationFieldContainer>
                  <NotificationField type='success' bodyMessage={"パスワードをリセットしました。再度ログインして下さい。"} />
                </NotificationFieldContainer>
              ) : (
                <div>
                  <DescriptionContainer>
                  新しいパスワードを設定します。
                  </DescriptionContainer>
                  <PasswordResetForm
                    onPasswordResetClick={(password) => {
                      if (oobCode == null) {
                        // oobCode が無かったら Firebase からエラー返ってくるので実際にはここの処理は通らない想定
                        return;
                      }
                      firebase.auth().languageCode = 'ja';
                      firebase.auth().confirmPasswordReset(oobCode, password)
                        .then(() => {
                          setIsPasswordResetFinished(true);
                        })
                        .catch(() => {
                          setErrorMessage("パスワード再設定URLのリンクが無効です。\n再度パスワード設定用メールを送信して下さい。");
                        });
                    }}
                  />
                </div>
              )
            }
            <LinkContainer>
              <Link to='/login'>ログイン</Link>
            </LinkContainer>
          </div>
        )}
      </PasswordResetFormContainer>
    </BeforeLogin>
  );
};
