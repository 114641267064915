/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MdWeekDate
 */
export interface MdWeekDate {
    /**
     * 
     * @type {number}
     * @memberof MdWeekDate
     */
    mdYear: number;
    /**
     * 
     * @type {number}
     * @memberof MdWeekDate
     */
    mdWeekNum: number;
    /**
     * 
     * @type {Date}
     * @memberof MdWeekDate
     */
    date: Date;
}

export function MdWeekDateFromJSON(json: any): MdWeekDate {
    return MdWeekDateFromJSONTyped(json, false);
}

export function MdWeekDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MdWeekDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mdYear': json['mdYear'],
        'mdWeekNum': json['mdWeekNum'],
        'date': (new Date(json['date'])),
    };
}

export function MdWeekDateToJSON(value?: MdWeekDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mdYear': value.mdYear,
        'mdWeekNum': value.mdWeekNum,
        'date': (value.date.toISOString().substr(0,10)),
    };
}


