import styled from 'styled-components';

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const NewUserButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-bottom: 15px;
`;

const NumberOfUsersLabel = styled.span`
  margin-left: 24px;
`;

const ToastContainer = styled.div`
  position: absolute;
  right: 22px;
  top: 107px;
  z-index: 399;
`;

const UsersTableContainer = styled.div`
  margin-bottom: 30px;
  margin-left: 49px;
  margin-right: 49px;
  margin-top: 40px;
`;

export {
  LoadingSpinnerContainer,
  NewUserButtonContainer,
  NumberOfUsersLabel,
  ToastContainer,
  UsersTableContainer,
};
