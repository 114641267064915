/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatabaseOrderGroup,
    DatabaseOrderGroupFromJSON,
    DatabaseOrderGroupFromJSONTyped,
    DatabaseOrderGroupToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetDatabaseOrderGroupsResponse
 */
export interface GetDatabaseOrderGroupsResponse {
    /**
     * 
     * @type {Array<DatabaseOrderGroup>}
     * @memberof GetDatabaseOrderGroupsResponse
     */
    orderGroups: Array<DatabaseOrderGroup>;
    /**
     * 
     * @type {Array<User>}
     * @memberof GetDatabaseOrderGroupsResponse
     */
    operators: Array<User>;
}

export function GetDatabaseOrderGroupsResponseFromJSON(json: any): GetDatabaseOrderGroupsResponse {
    return GetDatabaseOrderGroupsResponseFromJSONTyped(json, false);
}

export function GetDatabaseOrderGroupsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatabaseOrderGroupsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderGroups': ((json['orderGroups'] as Array<any>).map(DatabaseOrderGroupFromJSON)),
        'operators': ((json['operators'] as Array<any>).map(UserFromJSON)),
    };
}

export function GetDatabaseOrderGroupsResponseToJSON(value?: GetDatabaseOrderGroupsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderGroups': ((value.orderGroups as Array<any>).map(DatabaseOrderGroupToJSON)),
        'operators': ((value.operators as Array<any>).map(UserToJSON)),
    };
}


