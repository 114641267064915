import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import firebase from 'firebase/app';

import {
  AfterLogin
} from './templates';

import {
  Login,
  PasswordReset,
  PasswordResetMail,
} from './pages';

interface PrivateRouteProps {
  path: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  ...rest
}) => (
  <Route
    {...rest}
    render={( { location }) => (
      firebase.auth().currentUser? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      )
    )}
  />
);


export interface AppProps {}

export const App: React.FC<AppProps> = () => (
  <Router>
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/password-reset">
        <PasswordResetMail />
      </Route>
      <Route path="/email-auth">
        <PasswordReset />
      </Route>
      <PrivateRoute path="/">
        <AfterLogin />
      </PrivateRoute>
    </Switch>
  </Router>
);

export default App;
