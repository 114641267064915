/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * SKUごとの評価モデルの種類
 * @export
 * @enum {string}
 */
export enum ModelType {
    LongTermModel = 'longTermModel',
    MiddleTermModel = 'middleTermModel',
    ShortTermModel = 'shortTermModel',
    Undefined = 'undefined'
}

export function ModelTypeFromJSON(json: any): ModelType {
    return ModelTypeFromJSONTyped(json, false);
}

export function ModelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelType {
    return json as ModelType;
}

export function ModelTypeToJSON(value?: ModelType | null): any {
    return value as any;
}

