import styled from 'styled-components';

import { ReactComponent as BaseArrowLeftIcon } from '../../../assets/images/arrow-left.svg';
import { ReactComponent as BaseDisableArrowLeftIcon } from '../../../assets/images/disable-arrow-left.svg';
import { ReactComponent as BaseArrowRightIcon } from '../../../assets/images/arrow-right.svg';
import { ReactComponent as BaseDisableArrowRightIcon } from '../../../assets/images/disable-arrow-right.svg';

const BaseOrderSummaryTable = styled.table<{width: number}>`
  table-layout: fixed;
  white-space: nowrap;
  width: ${props => `${props.width}px`};
`;

const OrderSummaryTableBodyRow = styled.tr`
  background-color: #fff;

  &:hover {
    background-color: var(--di-table-body-bg-hover-color);
  }
`;

const OrderSummaryTableCol = styled.col<{width: number}>`
  width: ${props => `${props.width}px`};
`;

const OrderSummaryTableContainer = styled.div`
  max-height: 494px;
  overflow-y: scroll;
  overflow-x: scroll;
`;

const OrderSummaryTableData = styled.td<{adjustment?: boolean}>`
  border-right: solid 1px var(--di-table-border-color);
  height: 44px;
  padding-right: 7px;
  position: relative;
  text-align: right;

  &::before {
    color: var(--di-text-primary-color);
    content: '${props => props.adjustment? '*' : ''}';
    left: 5px;
    position: absolute;
    top: 16px;
  }

  &:first-child {
    padding-left: 14px;
    text-align: left;
  }
`;

const OrderSummaryTableDataNumberLabel = styled.span<{order?: string}>`
  color: ${props => props.order === 'true'? 'var(--di-text-error-color)' : 'var(--di-text-primary-color)'};
`;

const OrderSummaryTableDataTotal = styled.td<{colSpan?: number}>`
  background-color: #ebf2f6;
  border-right: solid 1px var(--di-table-border-color);
  bottom: 0;
  font-weight: bold;
  height: 62px;
  padding-right: 14px;
  position: sticky;
  text-align: right;
  width: 160px;

  &::before {
    border-top: 1px solid #51889b;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const OrderSummaryTableHeader = styled.th<{colSpan?: number}>`
  background-color: #f1f1f1;
  border-right: solid 1px var(--di-table-border-color);
  font-weight: normal;
  height: 37.5px;
  padding-left: 14px;
  padding-right: 7px;
  position: sticky;
  text-align: right;
  top: 0;
  z-index: 2;
`;

const ArrowLeftIcon = styled(BaseArrowLeftIcon)`
  cursor: pointer;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
`;

const DisableArrowLeftIcon = styled(BaseDisableArrowLeftIcon)`
  cursor: not-allowed;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
`;

const ArrowRightIcon = styled(BaseArrowRightIcon)`
  cursor: pointer;
  float: left;
  margin-left: 10px;
  margin-top: 5px;

  &:nth-child(2) {
    margin-left: 9.65px;
  }
`;

const DisableArrowRightIcon = styled(BaseDisableArrowRightIcon)`
  cursor: not-allowed;
  float: left;
  margin-left: 10px;
  margin-top: 5px;

  &:nth-child(2) {
    margin-left: 9.65px;
  }
`;

const OrderSummaryTableHeaderBorderBottom = styled.div`
  border-bottom: solid 1px var(--di-table-border-color);
  margin-bottom: 0;
  margin-top: 7px;
`;

const OrderSummaryTableSubHeader = styled.th`
  background-color: #f1f1f1;
  border-right: solid 1px var(--di-table-border-color);
  font-size: 13px;
  font-weight: normal;
  height: 24.5px;
  padding-bottom: 3px;
  padding-right: 7px;
  position: sticky;
  text-align: right;
  top: 37.5px;
  z-index: 2;
`;


const ProductCodeLabel = styled.span`
  margin-right: 14px;
`;

const ProductNameLabel = styled.span`
  margin-right: 14px;
`;

const SKUName = styled.span`
  color: var(--di-text-link-color);
  cursor: pointer;
`;

export {
  ArrowLeftIcon,
  ArrowRightIcon,
  BaseOrderSummaryTable,
  DisableArrowLeftIcon,
  DisableArrowRightIcon,
  OrderSummaryTableBodyRow,
  OrderSummaryTableCol,
  OrderSummaryTableContainer,
  OrderSummaryTableData,
  OrderSummaryTableDataNumberLabel,
  OrderSummaryTableDataTotal,
  OrderSummaryTableHeader,
  OrderSummaryTableHeaderBorderBottom,
  OrderSummaryTableSubHeader,
  ProductCodeLabel,
  ProductNameLabel,
  SKUName,
};
