/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutDestockingOrderGroupRequestOrderGroup
 */
export interface PutDestockingOrderGroupRequestOrderGroup {
    /**
     * 
     * @type {number}
     * @memberof PutDestockingOrderGroupRequestOrderGroup
     */
    leadtime: number;
    /**
     * 
     * @type {number}
     * @memberof PutDestockingOrderGroupRequestOrderGroup
     */
    numberOfOrderCycles: number;
    /**
     * 
     * @type {number}
     * @memberof PutDestockingOrderGroupRequestOrderGroup
     */
    numberOfOrderCycleWeeks: number;
    /**
     * 
     * @type {string}
     * @memberof PutDestockingOrderGroupRequestOrderGroup
     */
    plannedAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutDestockingOrderGroupRequestOrderGroup
     */
    useSubcategoryPrediction: boolean;
}

export function PutDestockingOrderGroupRequestOrderGroupFromJSON(json: any): PutDestockingOrderGroupRequestOrderGroup {
    return PutDestockingOrderGroupRequestOrderGroupFromJSONTyped(json, false);
}

export function PutDestockingOrderGroupRequestOrderGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutDestockingOrderGroupRequestOrderGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leadtime': json['leadtime'],
        'numberOfOrderCycles': json['numberOfOrderCycles'],
        'numberOfOrderCycleWeeks': json['numberOfOrderCycleWeeks'],
        'plannedAt': json['plannedAt'],
        'useSubcategoryPrediction': json['useSubcategoryPrediction'],
    };
}

export function PutDestockingOrderGroupRequestOrderGroupToJSON(value?: PutDestockingOrderGroupRequestOrderGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leadtime': value.leadtime,
        'numberOfOrderCycles': value.numberOfOrderCycles,
        'numberOfOrderCycleWeeks': value.numberOfOrderCycleWeeks,
        'plannedAt': value.plannedAt,
        'useSubcategoryPrediction': value.useSubcategoryPrediction,
    };
}


