/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutDestockingOrderGroupRequestOrderGroup,
    PutDestockingOrderGroupRequestOrderGroupFromJSON,
    PutDestockingOrderGroupRequestOrderGroupFromJSONTyped,
    PutDestockingOrderGroupRequestOrderGroupToJSON,
    PutDestockingOrderGroupRequestProducts,
    PutDestockingOrderGroupRequestProductsFromJSON,
    PutDestockingOrderGroupRequestProductsFromJSONTyped,
    PutDestockingOrderGroupRequestProductsToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutDestockingOrderGroupRequest
 */
export interface PutDestockingOrderGroupRequest {
    /**
     * 
     * @type {PutDestockingOrderGroupRequestOrderGroup}
     * @memberof PutDestockingOrderGroupRequest
     */
    orderGroup: PutDestockingOrderGroupRequestOrderGroup;
    /**
     * 
     * @type {Array<PutDestockingOrderGroupRequestProducts>}
     * @memberof PutDestockingOrderGroupRequest
     */
    products: Array<PutDestockingOrderGroupRequestProducts>;
}

export function PutDestockingOrderGroupRequestFromJSON(json: any): PutDestockingOrderGroupRequest {
    return PutDestockingOrderGroupRequestFromJSONTyped(json, false);
}

export function PutDestockingOrderGroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutDestockingOrderGroupRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderGroup': PutDestockingOrderGroupRequestOrderGroupFromJSON(json['orderGroup']),
        'products': ((json['products'] as Array<any>).map(PutDestockingOrderGroupRequestProductsFromJSON)),
    };
}

export function PutDestockingOrderGroupRequestToJSON(value?: PutDestockingOrderGroupRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderGroup': PutDestockingOrderGroupRequestOrderGroupToJSON(value.orderGroup),
        'products': ((value.products as Array<any>).map(PutDestockingOrderGroupRequestProductsToJSON)),
    };
}


