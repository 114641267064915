/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShipmentReason {
    NotSelected = 'notSelected',
    StoreInventoryExpansion = 'storeInventoryExpansion',
    SpecialPromotion = 'specialPromotion'
}

export function ShipmentReasonFromJSON(json: any): ShipmentReason {
    return ShipmentReasonFromJSONTyped(json, false);
}

export function ShipmentReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentReason {
    return json as ShipmentReason;
}

export function ShipmentReasonToJSON(value?: ShipmentReason | null): any {
    return value as any;
}

