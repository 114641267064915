import firebase from 'firebase/app';
import {
  getApi
} from './base';

async function putSkuMeta(productCode: string, comment: string)  {
  const token =  await firebase.auth().currentUser?.getIdToken(true);
  if (!token) return;
  const api = getApi(token);
  const param = {
    productCode,
    bodyParam: {
      comment
    }
  };
  await api.putSKUMeta(param);
}

export {
  putSkuMeta,
};
