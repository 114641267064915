import React from 'react';

import {
  ErrorMessagePanel,
} from '../../components/atoms';

import {
  ErrorMessagePanelContainer,
} from './style';

export interface NotFoundProps {}

export const NotFound: React.FC<NotFoundProps> = () => (
  <ErrorMessagePanelContainer>
    <ErrorMessagePanel statusCode={404} />
  </ErrorMessagePanelContainer>
);
