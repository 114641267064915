import styled from 'styled-components';

const BaseCheckBox = styled.input<{ disabled: boolean, size: number, setUncheckedMarkColor: boolean }>`
  appearance: none;
  -moz-appearance: none;
  background-color: ${props => props.disabled === true ? '#fbfafa' : '#fff'};
  border-color: #ccc;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: block;
  height:  ${props => props.size}px;
  margin: 0;
  pointer-events: ${props => props.disabled === true ? 'none' : 'auto'};
  transition: border-color 0.3s ease-out;
  -webkit-appearance: none;
  width:  ${props => props.size}px;

  &:hover {
    border-color: ${props => props.disabled === true ? '' : 'var(--di-primary-color)'};
  }

  &:checked {
    background-color: var(--di-primary-color);
    border-color: var(--di-primary-color);
  }

  &:before {
    color: ${props => props.setUncheckedMarkColor === true ? '#ccc' : '#fff'};
    content: '✔';
    display: block;
    font-size: ${props => props.size / 2}px;
    text-align: center;
    line-height: ${props => props.size}px;
  }

  &:checked::before {
    color: #fff;
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
`;

const Label = styled.label`
  margin-left: 6px;
`;

export {
  BaseCheckBox,
  Container,
  Label,
};
