import styled from 'styled-components';

const BaseBodyMessage = styled.div`
  white-space: pre-wrap;
`;

const BaseNotificationFieldContainer = styled.div`
  border-radius: 3px;
  padding: 13px 16px;
`;

const BaseTitle = styled.div`
  font-weight: bold;
`;

const BodyMessageError = styled(BaseBodyMessage)`
  color: var(--di-text-error-color);
`;

const BodyMessageSuccess = styled(BaseBodyMessage)`
  color: var(--di-text-success-color);
`;

const NotificationFieldContainerError = styled(BaseNotificationFieldContainer)`
  background-color: var(--di-background-error-color);
  border: 1px solid #eed5d6;
`;

const NotificationFieldContainerSuccess = styled(BaseNotificationFieldContainer)`
  background-color: var(--di-background-success-color);
`;

const TitleError = styled(BaseTitle)`
  color: var(--di-text-error-color);
`;

export {
  BodyMessageError,
  BodyMessageSuccess,
  NotificationFieldContainerError,
  NotificationFieldContainerSuccess,
  TitleError,
};
