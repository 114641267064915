/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostDatabaseOrderGroupRequest
 */
export interface PostDatabaseOrderGroupRequest {
    /**
     * 発注グループの名前
     * @type {string}
     * @memberof PostDatabaseOrderGroupRequest
     */
    name: string;
    /**
     * リードタイム（日）
     * @type {number}
     * @memberof PostDatabaseOrderGroupRequest
     */
    leadtime: number;
    /**
     * サイクル回数(週)
     * @type {number}
     * @memberof PostDatabaseOrderGroupRequest
     */
    numberOfOrderCycles: number;
    /**
     * サイクル週数(週)
     * @type {number}
     * @memberof PostDatabaseOrderGroupRequest
     */
    numberOfOrderCycleWeeks: number;
}

export function PostDatabaseOrderGroupRequestFromJSON(json: any): PostDatabaseOrderGroupRequest {
    return PostDatabaseOrderGroupRequestFromJSONTyped(json, false);
}

export function PostDatabaseOrderGroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDatabaseOrderGroupRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'leadtime': json['leadtime'],
        'numberOfOrderCycles': json['numberOfOrderCycles'],
        'numberOfOrderCycleWeeks': json['numberOfOrderCycleWeeks'],
    };
}

export function PostDatabaseOrderGroupRequestToJSON(value?: PostDatabaseOrderGroupRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'leadtime': value.leadtime,
        'numberOfOrderCycles': value.numberOfOrderCycles,
        'numberOfOrderCycleWeeks': value.numberOfOrderCycleWeeks,
    };
}


