import styled from 'styled-components';

import { ReactComponent as BaseCrosslIcon } from '../../../assets/images/cross.svg';
import { ReactComponent as BaseDisableCrosslIcon } from '../../../assets/images/disable_cross.svg';

const CrossIcon = styled(BaseCrosslIcon)`
  cursor: pointer;
  float: left;
  margin-left: 7px;
  margin-top: 7px;
`;

const DisableCrossIcon = styled(BaseDisableCrosslIcon)`
  cursor: not-allowed;
  float: left;
  margin-left: 7px;
  margin-top: 7px;
`;

export {
  CrossIcon,
  DisableCrossIcon,
};
