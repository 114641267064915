/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DemandAggregation,
    DemandAggregationFromJSON,
    DemandAggregationFromJSONTyped,
    DemandAggregationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutSKUsRequestSkus
 */
export interface PutSKUsRequestSkus {
    /**
     * JANコード
     * @type {string}
     * @memberof PutSKUsRequestSkus
     */
    code: string;
    /**
     * 発注グループのID
     * @type {number}
     * @memberof PutSKUsRequestSkus
     */
    orderGroupID: number;
    /**
     * 店発単
     * @type {number}
     * @memberof PutSKUsRequestSkus
     */
    minNumberOfProductsPerDelivery: number | null;
    /**
     * 売価
     * @type {number}
     * @memberof PutSKUsRequestSkus
     */
    price: number | null;
    /**
     * 欠品許容ランク
     * @type {string}
     * @memberof PutSKUsRequestSkus
     */
    shortageToleranceRank: PutSKUsRequestSkusShortageToleranceRankEnum;
    /**
     * 終売日
     * @type {Date}
     * @memberof PutSKUsRequestSkus
     */
    endOfSalesAt: Date | null;
    /**
     * 
     * @type {DemandAggregation}
     * @memberof PutSKUsRequestSkus
     */
    demandAggregation: DemandAggregation;
}

/**
* @export
* @enum {string}
*/
export enum PutSKUsRequestSkusShortageToleranceRankEnum {
    S = 'S',
    A = 'A',
    B = 'B',
    C = 'C'
}

export function PutSKUsRequestSkusFromJSON(json: any): PutSKUsRequestSkus {
    return PutSKUsRequestSkusFromJSONTyped(json, false);
}

export function PutSKUsRequestSkusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutSKUsRequestSkus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'orderGroupID': json['orderGroupID'],
        'minNumberOfProductsPerDelivery': json['minNumberOfProductsPerDelivery'],
        'price': json['price'],
        'shortageToleranceRank': json['shortageToleranceRank'],
        'endOfSalesAt': (json['endOfSalesAt'] === null ? null : new Date(json['endOfSalesAt'])),
        'demandAggregation': DemandAggregationFromJSON(json['demandAggregation']),
    };
}

export function PutSKUsRequestSkusToJSON(value?: PutSKUsRequestSkus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'orderGroupID': value.orderGroupID,
        'minNumberOfProductsPerDelivery': value.minNumberOfProductsPerDelivery,
        'price': value.price,
        'shortageToleranceRank': value.shortageToleranceRank,
        'endOfSalesAt': (value.endOfSalesAt === null ? null : value.endOfSalesAt.toISOString().substr(0,10)),
        'demandAggregation': DemandAggregationToJSON(value.demandAggregation),
    };
}


