import React, {
  useState,
} from 'react';

import {
  DatabaseOrderGroup,
  DemandAggregation,
  PostSKURequestShortageToleranceRankEnum,
} from '../../../api-client';

import {
  Button,
  Dropdown,
  DropdownOption,
  TextBox,
  Tooltip,
} from '../../atoms';

import {
  ButtonsContainer,
  CancelButtonContainer,
  InputFormContainer,
  InputFormContainerInner,
  InputFormLabel,
  InputFromLabelRequiredIcon,
  InputFormSubLabel,
} from './style';

import { useSKU }from '../../../hooks/useSKU';

import './SKUForm.css';

export interface SKUFormProps {
  /**
   * 選択可能な発注グループ一覧
   */
  selectableOrderGroups: Array<DatabaseOrderGroup>

  /**
   * 登録ボタンが押された時に呼び出されるハンドラー
   */
  onSubmitHandler: (
    code: string,
    orderGroupID: number,
    minNumberOfProductsPerDelivery: number | null,
    price: number | null,
    endOfSalesAt: Date | null,
    shortageToleranceRank: PostSKURequestShortageToleranceRankEnum,
    demandAggregation: DemandAggregation
  ) => void

  /**
   * キャンセルボタンが押された時に呼び出されるハンドラー
   */
  onCancelHandler: () => void
}

const shortageToleranceRankDropdownOptions: Array<DropdownOption<PostSKURequestShortageToleranceRankEnum>> = [
  {
    label: 'S',
    value: PostSKURequestShortageToleranceRankEnum.S,
  },
  {
    label: 'A',
    value: PostSKURequestShortageToleranceRankEnum.A,
  },
  {
    label: 'B',
    value: PostSKURequestShortageToleranceRankEnum.B,
  },
  {
    label: 'C',
    value: PostSKURequestShortageToleranceRankEnum.C,
  },
];

const isValidCode = (code: string) => /^4[59][0-9]+$/.test(code);

export const SKUForm: React.FC<SKUFormProps> = ({
  selectableOrderGroups,
  onSubmitHandler,
  onCancelHandler,
}) => {

  const orderGroupDropdownOptions: Array<DropdownOption<number>> = selectableOrderGroups.map((orderGroup) => ({
    label: orderGroup.name,
    value: orderGroup.id,
  }));
  orderGroupDropdownOptions.unshift({
    label: '選択してください',
    value: -1,
  });

  const [code, setCode] = useState('');
  const [orderGroupId, setOrderGroupId] = useState(-1);
  const [minNumberOfProductsPerDelivery, setMinNumberOfProductsPerDelivery] = useState<number | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const [endOfSalesAt, setEndOfSalesAt] = useState<Date | null>(null);
  const [shortageToleranceRank, setShortageToleranceRank] = useState(PostSKURequestShortageToleranceRankEnum.B);
  const [forceValidate, setForceValidate] = useState(false);
  const {
    demandAggregation,
    start,
    end,
    startDateMax,
    startDateMin,
    endDateMin,
    changeStartDate,
    changeEndDate,
    errorMessage,
  } = useSKU(null, null);


  return (
    <>
      <ButtonsContainer>
        <Button
          styleType='primary'
          label='登録'
          width={76}
          onClickHandler={() => {
            setForceValidate(true);
            if (
              (code === '' || isValidCode(code) === false) ||
              orderGroupId === -1 ||
              (minNumberOfProductsPerDelivery != null && (minNumberOfProductsPerDelivery < 1 || minNumberOfProductsPerDelivery > 999999)) ||
              (price != null && (price < 1 || price > 999999))
            ) {
              return;
            }
            onSubmitHandler(
              code,
              orderGroupId,
              minNumberOfProductsPerDelivery,
              price,
              endOfSalesAt,
              shortageToleranceRank,
              demandAggregation
            );
          }}
          data-testid='sku-form-submit-button'
        />
        <CancelButtonContainer>
          <Button
            styleType='tertiary'
            label='キャンセル'
            width={120}
            onClickHandler={() => {
              onCancelHandler();
            }}
            data-testid='sku-form-cancel-button'
          />
        </CancelButtonContainer>
      </ButtonsContainer>
      <InputFormContainer>
        <InputFormLabel>
          JANコード
          <InputFromLabelRequiredIcon>*</InputFromLabelRequiredIcon>
        </InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id='sku-form-code-input'
            type='text'
            width={265}
            height={40}
            defaultValue={code}
            required={true}
            forceValidate={forceValidate}
            customErrorMessage={code !== '' && isValidCode(code) === false? '45か49から始まる半角数字で入力してください。' : ''}
            onChangeHandler={(value) => {
              setCode(value);
            }}
          />
        </InputFormContainerInner>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>
          発注グループ
          <InputFromLabelRequiredIcon>*</InputFromLabelRequiredIcon>
        </InputFormLabel>
        <InputFormContainerInner>
          <Dropdown
            options={orderGroupDropdownOptions}
            defaultValue={orderGroupId}
            width={265}
            customErrorMessage={forceValidate && orderGroupId === -1? '選択して下さい。' : ''}
            onChangeHandler={(value) => {
              if (orderGroupDropdownOptions.length > 0 && orderGroupDropdownOptions[0].value === -1) {
                // 未選択状態は許容しないため、何か選択されたら未選択の選択肢は取り除く
                orderGroupDropdownOptions.shift();
              }
              setOrderGroupId(Number(value));
            }}
          />
        </InputFormContainerInner>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>売価</InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id='sku-form-price-input'
            type='number'
            width={91}
            height={40}
            defaultValue={price != null? price.toString() : ''}
            min={1}
            max={999999}
            forceValidate={forceValidate}
            customErrorMessage={price != null && (price < 1 || price > 999999)? '1〜999,999以内で入力して下さい。' : ''}
            onChangeHandler={(value) => {
              const maybePrice = Number.parseInt(value);
              setPrice(Number.isNaN(maybePrice)? null : maybePrice);
            }}
          />
        </InputFormContainerInner>
        <InputFormSubLabel left={300}>（1〜999,999）</InputFormSubLabel>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>店発単</InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id='sku-form-min-number-of-products-per-delivery-input'
            type='number'
            width={91}
            height={40}
            defaultValue={minNumberOfProductsPerDelivery != null? minNumberOfProductsPerDelivery.toString() : ''}
            min={1}
            max={999999}
            forceValidate={forceValidate}
            customErrorMessage={minNumberOfProductsPerDelivery != null && (minNumberOfProductsPerDelivery < 1 || minNumberOfProductsPerDelivery > 999999)? '1〜999,999以内で入力して下さい。' : ''}
            onChangeHandler={(value) => {
              const maybeMinNumberOfProductsPerDelivery = Number.parseInt(value);
              setMinNumberOfProductsPerDelivery(Number.isNaN(maybeMinNumberOfProductsPerDelivery)? null : maybeMinNumberOfProductsPerDelivery);
            }}
          />
        </InputFormContainerInner>
        <InputFormSubLabel left={300}>（1〜999,999）</InputFormSubLabel>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>終売日</InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id='sku-form-end-of-sales-at-input'
            type='date'
            width={160}
            height={40}
            defaultValue={endOfSalesAt?.toISOString().split('T')[0]}
            min={new Date().toISOString().split('T')[0]}
            onChangeHandler={(value) => {
              setEndOfSalesAt(value === ''? null : new Date(value));
            }}
          />
        </InputFormContainerInner>
        <InputFormSubLabel left={370}>（YYYY/MM/DD）</InputFormSubLabel>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>
          欠品許容ランク
          <InputFromLabelRequiredIcon>*</InputFromLabelRequiredIcon>
        </InputFormLabel>
        <InputFormContainerInner>
          <Dropdown
            options={shortageToleranceRankDropdownOptions}
            defaultValue={PostSKURequestShortageToleranceRankEnum.B}
            width={70}
            onChangeHandler={(value) => {
              setShortageToleranceRank(value);
            }}
          />
        </InputFormContainerInner>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>
          需要集約期間
        </InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            data-testid='sku-form-demand-aggregation-start-date'
            type='date'
            height={40}
            width={100}
            padding={5}
            fontSize={13}
            fontFamily='"Noto Sans JP", sans-serif'
            step={7}
            defaultValue={start?.toISOString().split('T')[0] ?? undefined}
            min={startDateMin()}
            max={startDateMax}
            highlightOnChange={false}
            onChangeHandler={(startDate) => {
              changeStartDate(startDate);
            }}
          />
          { errorMessage.start !== null ?
            <Tooltip
              id='demand-aggregation-start-error-tooltip'
              text='日付を選択して下さい'
              top={52}
              left={216}
              width={145}
            />
            : null
          }
          <span className="demand-aggregation-between">
          〜
          </span>
          <TextBox
            data-testid='sku-form-demand-aggregation-end-date'
            type='date'
            height={40}
            width={100}
            padding={5}
            fontSize={13}
            fontFamily='"Noto Sans JP", sans-serif'
            step={7}
            defaultValue={end?.toISOString().split('T')[0] ?? undefined}
            min={endDateMin}
            highlightOnChange={false}
            onChangeHandler={(endDate) => {
              changeEndDate(endDate);
            }}
          />
          { errorMessage.end !== null ?
            <Tooltip
              id='demand-aggregation-end-error-tooltip'
              text='日付を選択して下さい'
              top={52}
              left={336}
              width={145}
            />
            : null
          }
        </InputFormContainerInner>
      </InputFormContainer>
    </>
  );
};
