/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MdWeekDate,
    MdWeekDateFromJSON,
    MdWeekDateFromJSONTyped,
    MdWeekDateToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutDestockingOrderGroupIncomingMdWeekBackwardRequest
 */
export interface PutDestockingOrderGroupIncomingMdWeekBackwardRequest {
    /**
     * 
     * @type {MdWeekDate}
     * @memberof PutDestockingOrderGroupIncomingMdWeekBackwardRequest
     */
    targetMdWeekDate: MdWeekDate;
    /**
     * 
     * @type {string}
     * @memberof PutDestockingOrderGroupIncomingMdWeekBackwardRequest
     */
    plannedAt: string;
}

export function PutDestockingOrderGroupIncomingMdWeekBackwardRequestFromJSON(json: any): PutDestockingOrderGroupIncomingMdWeekBackwardRequest {
    return PutDestockingOrderGroupIncomingMdWeekBackwardRequestFromJSONTyped(json, false);
}

export function PutDestockingOrderGroupIncomingMdWeekBackwardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutDestockingOrderGroupIncomingMdWeekBackwardRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetMdWeekDate': MdWeekDateFromJSON(json['targetMdWeekDate']),
        'plannedAt': json['plannedAt'],
    };
}

export function PutDestockingOrderGroupIncomingMdWeekBackwardRequestToJSON(value?: PutDestockingOrderGroupIncomingMdWeekBackwardRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetMdWeekDate': MdWeekDateToJSON(value.targetMdWeekDate),
        'plannedAt': value.plannedAt,
    };
}


