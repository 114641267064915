import React, {
  useState,
} from 'react';
import {
  useHistory,
} from 'react-router-dom';
import firebase from 'firebase/app';

import {
  DefaultApi,
  Configuration,
  ErrorTitle,
  ModelError,
  PostSKUOperationRequest,
  PostSKURequest,
  User,
  UserRole,
} from '../../api-client';

import {
  SKUOperationType,
} from '../../constants';

import {
  useOwnOrderGroups,
} from '../../hooks';

import {
  ErrorMessagePanel,
  LoadingSpinner,
  NotificationField,
  PageHeader,
} from '../../components/atoms';

import {
  SKUForm,
} from '../../components/molecules';

import {
  ErrorMessagePanelContainer,
  LoadingSpinnerContainer,
  NotificationErrorContainer,
  SKUFormContainer,
} from './style';

export interface CreateSKUProps {
  user: User
}

export const CreateSKU: React.FC<CreateSKUProps> = ({
  user,
}) => {

  const history = useHistory();
  const ownOrderGroups = useOwnOrderGroups();

  const [createSKUErrorMessage, setCreateSKUErrorMessage] = useState<string | undefined | null>(undefined);

  return user.role === UserRole.Operator? (
    <>
      <PageHeader
        pageTitle='SKU登録'
        backLink={true}
      />
      {ownOrderGroups != null? (
        <SKUFormContainer>
          {createSKUErrorMessage != null? (
            <NotificationErrorContainer>
              <NotificationField
                type='error'
                bodyMessage={createSKUErrorMessage}
              />
            </NotificationErrorContainer>
          ) : null}
          <SKUForm
            selectableOrderGroups={ownOrderGroups}
            onSubmitHandler={(
              code,
              orderGroupID,
              minNumberOfProductsPerDelivery,
              price,
              endOfSalesAt,
              shortageToleranceRank,
              demandAggregation,
            ) => {
              setCreateSKUErrorMessage(null);
              firebase.auth().currentUser?.getIdToken(true)
                .then((token) => {
                  const conf = new Configuration({
                    basePath: process.env.REACT_APP_API_PATH,
                    headers: {
                      'Authorization': `Bearer ${token}`
                    }
                  });
                  const api = new DefaultApi(conf);
                  const bodyParam: PostSKURequest = {
                    code,
                    orderGroupID,
                    minNumberOfProductsPerDelivery,
                    price,
                    endOfSalesAt,
                    shortageToleranceRank,
                    demandAggregation: {
                      startDate: demandAggregation.startDate,
                      endDate: demandAggregation.endDate,
                    }
                  };
                  const param: PostSKUOperationRequest = {
                    bodyParam,
                  };
                  return api.postSKU(param);
                })
                .then((response) => {
                  history.push({
                    pathname: '/skus',
                    state: {
                      skuOperationType: SKUOperationType.Create,
                    }
                  });
                })
                .catch((error: Response) => {
                  if (error.status === 400) {
                    error.json()
                      .then((e: ModelError) => {
                        if (e.title === ErrorTitle._400BadRequestJanCodeNotFound) {
                          setCreateSKUErrorMessage('存在しないJANコードです。');
                        } else if (e.title === ErrorTitle._400BadRequestSkuAlreadyExists) {
                          setCreateSKUErrorMessage('このJANコードは、既に登録されています。');
                        } else if (e.title === ErrorTitle._400BadRequestSkuRegisteredByOthers) {
                          setCreateSKUErrorMessage('このJANコードは、他の担当者（在庫対応）で既に登録されています。');
                        } else if (e.title === ErrorTitle._400BadRequestDcIsInvalid) {
                          setCreateSKUErrorMessage('このJANコードは、対象外のDCに紐付いています。');
                        } else {
                          setCreateSKUErrorMessage('不明なエラーです。システム管理者にお問い合わせください。');
                        }
                      });
                  } else {
                    setCreateSKUErrorMessage('不明なエラーです。システム管理者にお問い合わせください。');
                  }
                });
            }}
            onCancelHandler={() => history.goBack()}
          />
        </SKUFormContainer>
      ) :(
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      )}
    </>
  ) : (
    <ErrorMessagePanelContainer>
      <ErrorMessagePanel statusCode={403} />
    </ErrorMessagePanelContainer>
  );
};
