import styled from 'styled-components';

import { ReactComponent as BaseCaretLeftIcon } from '../../../assets/images/caret-left.svg';

const BasePageHeader = styled.div`
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 1px #00000029;
  display: flex;
  height: var(--di-page-header-height);
  white-space: nowrap;
  width: 100%;
`;

const CaretLeftIcon = styled(BaseCaretLeftIcon)`
  height: 13px;
  margin-right: 4px;
`;

const LinkContainer = styled.div`
  margin-left: 25px;
  margin-right: 12px;
`;

const LinkContainerInner = styled.div`
  align-items: center;
  display: flex;
`;

const Title = styled.h1`
  margin-left: 19px;
`;

export {
  BasePageHeader,
  CaretLeftIcon,
  LinkContainer,
  LinkContainerInner,
  Title,
};
