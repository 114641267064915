import styled from 'styled-components';

const CheckBoxContainer = styled.div`
  margin-bottom: 9px;
  margin-right: 25px;
`;

const Container = styled.div`
  align-items: flex-end;
  background-color: #f1f1f1;
  display: flex;
  padding-bottom: 18px;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 18px;
`;

const TextBoxContainer = styled.div`
  margin-right: 25px;
`;

export {
  CheckBoxContainer,
  Container,
  TextBoxContainer,
};
