import React, {
  useState,
} from 'react';

import {
  Button,
  TextBox,
} from '../../atoms';

import {
  ButtonsContainer,
  CancelButtonContainer,
  InputFormContainer,
  InputFormContainerInner,
  InputFormLabel,
  InputFromLabelRequiredIcon,
  InputFormSubLabel,
} from './style';

export interface OrderGroupFormProps {
  /**
    * 登録ボタンが押された時に呼び出されるハンドラー
    */
  onSubmitHandler: (
    name: string,
    leadtime: number,
    numberOfOrderCycles: number,
    numberOfOrderCycleWeeks: number,
  ) => void

  /**
    * キャンセルボタンが押された時に呼び出されるハンドラー
    */
  onCancelHandler: () => void
}

export const OrderGroupForm: React.FC<OrderGroupFormProps> = ({
  onSubmitHandler,
  onCancelHandler,
}) => {

  const [name, setName] = useState<string | null>(null);
  const [leadtime, setLeadtime] = useState<number | null>(null);
  const [numberOfOrderCycles, setNumberOfOrderCycles] = useState<number | null>(null);
  const [numberOfOrderCycleWeeks, setNumberOfOrderCycleWeeks] = useState<number | null>(null);
  const [forceValidate, setForceValidate] = useState(false);

  const isValidName = (name: string) => /^[0-9a-zA-Z-]{1,40}$/.test(name);

  // リードタイムの最大有効値は (52 - (サイクル回数 * サイクル週数)) * 7
  const calcMaxLeadtime = (
    numberOfOrderCycles: number,
    numberOfOrderCycleWeeks: number,
  ) => (52 - (numberOfOrderCycles * numberOfOrderCycleWeeks)) * 7;

  // サイクル回数の最大有効値は (52 - (リードタイム / 7)) / サイクル週数
  // リードタイムが7で割り切れない場合は切り上げる
  // サイクル週数で割って出た余りは切り捨てる（合計52を超えないようにする）
  const calcMaxNumberOfOrderCycles = (
    leadtime: number,
    numberOfOrderCycleWeeks: number,
  ) => Math.floor((52 - Math.ceil(leadtime / 7)) / numberOfOrderCycleWeeks);

  // サイクル週数の最大有効値は (52 - ((リードタイム / 7)) / サイクル回数
  // リードタイムが7で割り切れない場合は切り上げる
  // サイクル回数で割って出た余りは切り捨てる（合計52を超えないようにする）
  const calcMaxNumberOfOrderCycleWeeks = (
    leadtime: number,
    numberOfOrderCycles: number,
  ) => Math.floor((52 - Math.ceil(leadtime / 7)) / numberOfOrderCycles);

  return (
    <>
      <ButtonsContainer>
        <Button
          styleType='primary'
          label='登録'
          width={76}
          onClickHandler={() => {
            setForceValidate(true);

            if (name == null) {
              return;
            }
            if (leadtime == null) {
              return;
            }
            if (numberOfOrderCycles == null) {
              return;
            }
            if (numberOfOrderCycleWeeks == null) {
              return;
            }

            if (isValidName(name) === false) {
              return;
            }
            if (leadtime < 1 || leadtime > 364 || leadtime > calcMaxLeadtime(numberOfOrderCycles, numberOfOrderCycleWeeks)) {
              return;
            }
            if (numberOfOrderCycles < 1 || numberOfOrderCycles > 52 || numberOfOrderCycles > calcMaxNumberOfOrderCycles(leadtime, numberOfOrderCycleWeeks)) {
              return;
            }
            if (numberOfOrderCycleWeeks < 1 || numberOfOrderCycleWeeks > 52 || numberOfOrderCycleWeeks > calcMaxNumberOfOrderCycleWeeks(leadtime, numberOfOrderCycles)) {
              return;
            }

            onSubmitHandler(
              name,
              leadtime,
              numberOfOrderCycles,
              numberOfOrderCycleWeeks,
            );
          }}
          data-testid='order-group-form-submit-button'
        />
        <CancelButtonContainer>
          <Button
            styleType='tertiary'
            label='キャンセル'
            width={120}
            onClickHandler={() => {
              onCancelHandler();
            }}
            data-testid='order-group-form-cancel-button'
          />
        </CancelButtonContainer>
      </ButtonsContainer>
      <InputFormContainer>
        <InputFormLabel>
          <span>発注グループ</span>
          <InputFromLabelRequiredIcon>*</InputFromLabelRequiredIcon>
        </InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id='order-group-form-name-input'
            type='text'
            width={265}
            height={40}
            defaultValue={name != null? name : ''}
            required={true}
            forceValidate={forceValidate}
            customErrorMessage={name != null && isValidName(name) === false? '半角英数字かハイフン(-)の40文字以内で入力して下さい。' : ''}
            onChangeHandler={(value) => {
              setName(value);
            }}
          />
        </InputFormContainerInner>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>
          <span>リードタイム（日）</span>
          <InputFromLabelRequiredIcon>*</InputFromLabelRequiredIcon>
        </InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id='order-group-form-leadtime-input'
            type='number'
            width={91}
            height={40}
            defaultValue={leadtime != null? leadtime.toString() : ''}
            required={true}
            forceValidate={forceValidate}
            customErrorMessage={
              (leadtime != null && leadtime < 1) ||
              (leadtime != null && leadtime > 364) ||
              (leadtime != null && numberOfOrderCycles != null && numberOfOrderCycleWeeks != null && leadtime > calcMaxLeadtime(numberOfOrderCycles, numberOfOrderCycleWeeks))
                ? '合計52週の半角数字で入力して下さい。' : ''
            }
            onChangeHandler={(value) => {
              const maybeLeadtime = Number.parseInt(value);
              setLeadtime(Number.isNaN(maybeLeadtime)? null : maybeLeadtime);
            }}
          />
        </InputFormContainerInner>
        <InputFormSubLabel left={320}>（1〜364）</InputFormSubLabel>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>
          <span>サイクル回数（週）</span>
          <InputFromLabelRequiredIcon>*</InputFromLabelRequiredIcon>
        </InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id='order-group-form-number-of-order-cycles-input'
            type='number'
            width={91}
            height={40}
            defaultValue={numberOfOrderCycles != null? numberOfOrderCycles.toString() : ''}
            required={true}
            forceValidate={forceValidate}
            customErrorMessage={
              (numberOfOrderCycles != null && numberOfOrderCycles < 1) ||
              (numberOfOrderCycles != null && numberOfOrderCycles > 52) ||
              (leadtime != null && numberOfOrderCycles != null && numberOfOrderCycleWeeks != null && numberOfOrderCycles > calcMaxNumberOfOrderCycles(leadtime, numberOfOrderCycleWeeks))
                ? '合計52週の半角数字で入力して下さい。' : ''
            }
            onChangeHandler={(value) => {
              const maybeNumberOfOrderCycles = Number.parseInt(value);
              setNumberOfOrderCycles(Number.isNaN(maybeNumberOfOrderCycles)? null : maybeNumberOfOrderCycles);
            }}
          />
        </InputFormContainerInner>
        <InputFormSubLabel left={320}>（1〜52）</InputFormSubLabel>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>
          <span>サイクル週数（週）</span>
          <InputFromLabelRequiredIcon>*</InputFromLabelRequiredIcon>
        </InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id='order-group-form-number-of-order-cycle-weeks-input'
            type='number'
            width={91}
            height={40}
            defaultValue={numberOfOrderCycleWeeks != null? numberOfOrderCycleWeeks.toString() : ''}
            required={true}
            forceValidate={forceValidate}
            customErrorMessage={
              (numberOfOrderCycleWeeks != null && numberOfOrderCycleWeeks < 1) ||
              (numberOfOrderCycleWeeks != null && numberOfOrderCycleWeeks > 52) ||
              (leadtime != null && numberOfOrderCycles != null && numberOfOrderCycleWeeks != null && numberOfOrderCycleWeeks > calcMaxNumberOfOrderCycleWeeks(leadtime, numberOfOrderCycles))
                ? '合計52週の半角数字で入力して下さい。' : ''
            }
            onChangeHandler={(value) => {
              const maybeNumberOfOrderCycleWeeks = Number.parseInt(value);
              setNumberOfOrderCycleWeeks(Number.isNaN(maybeNumberOfOrderCycleWeeks)? null : maybeNumberOfOrderCycleWeeks);
            }}
          />
        </InputFormContainerInner>
        <InputFormSubLabel left={320}>（1〜52）</InputFormSubLabel>
      </InputFormContainer>
    </>
  );
};
