import React, {
  useState,
} from 'react';
import {
  useHistory,
} from 'react-router-dom';
import firebase from 'firebase/app';

import {
  Configuration,
  DefaultApi,
  ErrorTitle,
  ModelError,
  PostDatabaseOrderGroupOperationRequest,
  PostDatabaseOrderGroupRequest,
  User,
  UserRole,
} from '../../api-client';

import {
  OrderGroupOperationType,
} from '../../constants';

import {
  ErrorMessagePanel,
  NotificationField,
  PageHeader,
} from '../../components/atoms';

import {
  OrderGroupForm,
} from '../../components/molecules';

import {
  ErrorMessagePanelContainer,
  NotificationErrorContainer,
  OrderGroupFormContainer,
} from './style';

export interface CreateOrderGroupProps {
  user: User
}

export const CreateOrderGroup: React.FC<CreateOrderGroupProps> = ({
  user,
}) => {
  const history = useHistory();

  const [createOrderGroupErrorMessage, setCreateOrderGroupErrorMessage] = useState<string | undefined | null>(undefined);


  return user.role === UserRole.Operator? (
    <>
      <PageHeader
        pageTitle='発注グループ登録'
        backLink={true}
      />
      {createOrderGroupErrorMessage != null? (
        <NotificationErrorContainer>
          <NotificationField
            type='error'
            bodyMessage={createOrderGroupErrorMessage}
          />
        </NotificationErrorContainer>
      ) : null}
      <OrderGroupFormContainer>
        <OrderGroupForm
          onSubmitHandler={(
            name,
            leadtime,
            numberOfOrderCycles,
            numberOfOrderCycleWeeks,
          ) => {
            setCreateOrderGroupErrorMessage(null);
            firebase.auth().currentUser?.getIdToken(true)
              .then((token) => {
                const conf = new Configuration({
                  basePath: process.env.REACT_APP_API_PATH,
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                });
                const api = new DefaultApi(conf);
                const bodyParam: PostDatabaseOrderGroupRequest = {
                  name,
                  leadtime,
                  numberOfOrderCycles,
                  numberOfOrderCycleWeeks,
                };
                const param: PostDatabaseOrderGroupOperationRequest = {
                  bodyParam,
                };
                return api.postDatabaseOrderGroup(param);
              })
              .then(() => {
                history.push({
                  pathname: '/manage-order-group',
                  state: {
                    orderGroupOperationType: OrderGroupOperationType.Create,
                  },
                });
              })
              .catch((error: Response) => {
                if (error.status === 400) {
                  error.json()
                    .then((e: ModelError) => {
                      if (e.title === ErrorTitle._400BadRequestOrderGroupNameIsDuplicated) {
                        setCreateOrderGroupErrorMessage('この発注グループ名は既に登録されています。');
                      } else {
                        setCreateOrderGroupErrorMessage('不明なエラーです。システム管理者にお問い合わせください。');
                      }
                    });
                } else {
                  setCreateOrderGroupErrorMessage('不明なエラーです。システム管理者にお問い合わせください。');
                }
              });
          }}
          onCancelHandler={() => history.goBack()}
        />
      </OrderGroupFormContainer>
    </>
  ) : (
    <ErrorMessagePanelContainer>
      <ErrorMessagePanel statusCode={403} />
    </ErrorMessagePanelContainer>
  );
};
