import styled from 'styled-components';

const ErrorMessagePanelContainer = styled.div`
  height: calc(100% - 60px);
  padding-bottom: 30px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 30px;
`;

export {
  ErrorMessagePanelContainer
};
