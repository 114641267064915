import React from 'react';

import {
  OrderMdWeekDate,
  OrderSummary,
} from '../../../api-client';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BaseOrderSummaryTable,
  DisableArrowLeftIcon,
  DisableArrowRightIcon,
  OrderSummaryTableBodyRow,
  OrderSummaryTableCol,
  OrderSummaryTableContainer,
  OrderSummaryTableData,
  OrderSummaryTableDataNumberLabel,
  OrderSummaryTableDataTotal,
  OrderSummaryTableHeader,
  OrderSummaryTableHeaderBorderBottom,
  OrderSummaryTableSubHeader,
  ProductCodeLabel,
  ProductNameLabel,
  SKUName,
} from './style';

export interface OrderSummaryTableProps {
  /**
   * 発注サマリーの情報
   */
  orderSummary: OrderSummary

  /**
   * 発注週を更新できるかどうか
   */
  isIncomingWeekUpdatable: boolean


  /**
   * 発注を前週に移動する関数
   */
  onUpdateIncomingMdWeekForward: (mdWeek: number, mdYear: number, date: Date) => void

  /**
   * 発注を後週に移動する関数
   */
  onUpdateIncomingMdWeekBackward: (mdWeek: number, mdYear: number, date: Date) => void
}

export const OrderSummaryTable: React.FC<OrderSummaryTableProps> = ({
  orderSummary,
  isIncomingWeekUpdatable,
  onUpdateIncomingMdWeekForward,
  onUpdateIncomingMdWeekBackward
}) => {

  const productNameMaxLength = Math.max(...orderSummary.products.map((product) => product.code.length + (product.name != null? product.name.length : 0) + (product.specName != null? product.specName.length : 0)));

  const mdWeekYearNumStr = (mdWeekDate: OrderMdWeekDate) => `${mdWeekDate.mdYear}-${mdWeekDate.mdWeekNum}`;

  return (
    <OrderSummaryTableContainer>
      <BaseOrderSummaryTable width={(productNameMaxLength * 14 + 28) + orderSummary.orderMdWeekDates.length * 190}>
        <colgroup>
          <OrderSummaryTableCol width={productNameMaxLength * 14 + 28} />
          {orderSummary.orderMdWeekDates.map((elem, index) => (
            <React.Fragment key={index}>
              <OrderSummaryTableCol width={95} />
              <OrderSummaryTableCol width={95} />
            </React.Fragment>
          ))}
        </colgroup>
        <thead>
          <tr>
            <OrderSummaryTableHeader></OrderSummaryTableHeader>
            {orderSummary.orderMdWeekDates.map((orderMdWeekDate, index) => (
              <OrderSummaryTableHeader key={index} colSpan={2} data-testid="order-summary-md-week-num">
                {orderMdWeekDate.canBeMovedBackward === true?
                  (isIncomingWeekUpdatable===true?
                    <ArrowLeftIcon data-testid="arrow-left-icon" onClick={() => onUpdateIncomingMdWeekBackward(orderMdWeekDate.mdWeekNum, orderMdWeekDate.mdYear, orderMdWeekDate.date)} />:
                    <DisableArrowLeftIcon data-testid="disable-arrow-left-icon"/>)
                  :''}
                {orderMdWeekDate.canBeMovedForward === true?
                  (isIncomingWeekUpdatable===true?
                    <ArrowRightIcon data-testid="arrow-right-icon" onClick={() => onUpdateIncomingMdWeekForward(orderMdWeekDate.mdWeekNum, orderMdWeekDate.mdYear, orderMdWeekDate.date)}/>:
                    <DisableArrowRightIcon data-testid="disable-arrow-right-icon"/>)
                  :''}
                {orderMdWeekDate.mdWeekNum}週({`${orderMdWeekDate.date.getMonth()+1}/${orderMdWeekDate.date.getDate()}`})<OrderSummaryTableHeaderBorderBottom />
              </OrderSummaryTableHeader>
            ))}
          </tr>
          <tr>
            <OrderSummaryTableSubHeader></OrderSummaryTableSubHeader>
            {orderSummary.orderMdWeekDates.map((elem, index) => (
              <React.Fragment key={index}>
                <OrderSummaryTableSubHeader>発注確定数</OrderSummaryTableSubHeader>
                <OrderSummaryTableSubHeader>M3</OrderSummaryTableSubHeader>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {orderSummary.products.map((product, index) => (
            <OrderSummaryTableBodyRow key={index}>
              <OrderSummaryTableData>
                <SKUName
                  onClick={() => {
                    const elem = document.getElementById(`product-panel-${product.code}`);
                    // jsdom may not have scrollIntoView method.
                    if (elem != null && elem.scrollIntoView != null) {
                      elem.scrollIntoView();
                    }
                  }}
                  data-testid='order-summary-table-sku-name'
                  data-cy='order-summary-table-sku-name'
                >
                  <ProductCodeLabel>{product.code}</ProductCodeLabel>
                  {product.name == null && product.specName == null? (
                    <ProductNameLabel>-</ProductNameLabel>
                  ) : (
                    <React.Fragment>
                      <ProductNameLabel>
                        {product.name != null? product.name : '-'}
                      </ProductNameLabel>
                      {product.specName != null? `(${product.specName})` : '-'}
                    </React.Fragment>
                  )}
                </SKUName>
              </OrderSummaryTableData>
              {orderSummary.orderMdWeekDates.map((orderMdWeekDate, idx) => (
                <React.Fragment key={idx}>
                  <OrderSummaryTableData adjustment={product.orderPerMdWeek[mdWeekYearNumStr(orderMdWeekDate)]?.isNeedAdjustment === true}>
                    <OrderSummaryTableDataNumberLabel order={product.orderPerMdWeek[mdWeekYearNumStr(orderMdWeekDate)]?.isNeedOrder.toString()}>
                      {product.orderPerMdWeek[mdWeekYearNumStr(orderMdWeekDate)]?.numberOfOrders? product.orderPerMdWeek[mdWeekYearNumStr(orderMdWeekDate)].numberOfOrders.toLocaleString() : '-' }
                    </OrderSummaryTableDataNumberLabel>
                  </OrderSummaryTableData>
                  <OrderSummaryTableData>
                    <OrderSummaryTableDataNumberLabel order={product.orderPerMdWeek[mdWeekYearNumStr(orderMdWeekDate)]?.isNeedOrder.toString()} data-testid='m3'>
                      {product.orderPerMdWeek[mdWeekYearNumStr(orderMdWeekDate)]?.m3? `${Math.ceil(product.orderPerMdWeek[mdWeekYearNumStr(orderMdWeekDate)].m3 * 100) / 100}㎥` : '-'}
                    </OrderSummaryTableDataNumberLabel>
                  </OrderSummaryTableData>
                </React.Fragment>
              ))}
            </OrderSummaryTableBodyRow>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <OrderSummaryTableDataTotal></OrderSummaryTableDataTotal>
            {orderSummary.orderMdWeekDates.map((orderMdWeekDate, index) => (
              <React.Fragment key={index}>
                <OrderSummaryTableDataTotal colSpan={2} data-testid='total'>{`${orderMdWeekDate.totalNumberOfOrders.toLocaleString()} (${Math.ceil(orderMdWeekDate.totalM3 * 100) / 100}㎥)`}</OrderSummaryTableDataTotal>
              </React.Fragment>
            ))}
          </tr>
        </tfoot>
      </BaseOrderSummaryTable>
    </OrderSummaryTableContainer>
  );
};
