/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MdWeekDate,
    MdWeekDateFromJSON,
    MdWeekDateFromJSONTyped,
    MdWeekDateToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutDestockingOrderGroupIncomingMdWeekForwardRequest
 */
export interface PutDestockingOrderGroupIncomingMdWeekForwardRequest {
    /**
     * 
     * @type {MdWeekDate}
     * @memberof PutDestockingOrderGroupIncomingMdWeekForwardRequest
     */
    targetMdWeekDate: MdWeekDate;
    /**
     * 
     * @type {string}
     * @memberof PutDestockingOrderGroupIncomingMdWeekForwardRequest
     */
    plannedAt: string;
}

export function PutDestockingOrderGroupIncomingMdWeekForwardRequestFromJSON(json: any): PutDestockingOrderGroupIncomingMdWeekForwardRequest {
    return PutDestockingOrderGroupIncomingMdWeekForwardRequestFromJSONTyped(json, false);
}

export function PutDestockingOrderGroupIncomingMdWeekForwardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutDestockingOrderGroupIncomingMdWeekForwardRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetMdWeekDate': MdWeekDateFromJSON(json['targetMdWeekDate']),
        'plannedAt': json['plannedAt'],
    };
}

export function PutDestockingOrderGroupIncomingMdWeekForwardRequestToJSON(value?: PutDestockingOrderGroupIncomingMdWeekForwardRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetMdWeekDate': MdWeekDateToJSON(value.targetMdWeekDate),
        'plannedAt': value.plannedAt,
    };
}


