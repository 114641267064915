import React from 'react';
import {
  TextBox,
} from '../../../atoms/TextBox/TextBox';

import { useSKU }from '../../../../hooks/useSKU';

import { Tooltip } from '../../../atoms';

import './DemandAggregation.css';

interface DemandAggregationProps {
  disabled?: boolean,
  startDate: Date | null,
  endDate: Date | null,
  onBlurHandler: (startDate: Date | null, endDate: Date | null) => void;
}

export const DemandAggregation: React.FC<DemandAggregationProps> = ({
  disabled=false,
  startDate,
  endDate,
  onBlurHandler,
}) => {
  const {
    start,
    end,
    startDateMax,
    endDateMin,
    isDemandAggregationValid,
    changeStartDate,
    changeEndDate,
    startDateMin,
    errorMessage,
  } = useSKU(startDate, endDate);

  return (
    <div className="demand-aggregation-container">
      <TextBox
        data-testid='skus-list-table-demand-aggregation-start-date'
        type='date'
        height={40}
        width={133}
        padding={5}
        fontSize={16}
        fontFamily='"Noto Sans JP", sans-serif'
        step={7}
        defaultValue={start?.toISOString().split('T')[0] ?? undefined}
        min={startDateMin()}
        max={startDateMax}
        highlightOnChange={false}
        disabled={disabled}
        onBlurHandler={() => {
          if (isDemandAggregationValid) {
            onBlurHandler(start, end);
          }
        }}
        onChangeHandler={(startDate) => {
          changeStartDate(startDate);
        }}
      />
      { errorMessage.start !== null ?
        <Tooltip
          id='demand-aggregation-start-error-tooltip'
          text='日付を選択して下さい'
          top={42}
          left={0}
          width={145}
        />
        : null
      }
      <span className="demand-aggregation-between">
      〜
      </span>
      <TextBox
        data-testid='skus-list-table-demand-aggregation-end-date'
        type='date'
        height={40}
        width={133}
        padding={5}
        fontSize={16}
        fontFamily='"Noto Sans JP", sans-serif'
        step={7}
        defaultValue={end?.toISOString().split('T')[0] ?? undefined}
        min={endDateMin}
        highlightOnChange={false}
        disabled={disabled}
        onBlurHandler={() => {
          if (isDemandAggregationValid) {
            onBlurHandler(start, end);
          }
        }}
        onChangeHandler={(endDate) => {
          changeEndDate(endDate);
        }}
      />
      { errorMessage.end !== null ?
        <Tooltip
          id='demand-aggregation-end-error-tooltip'
          text='日付を選択して下さい'
          top={42}
          left={154}
          width={145}
        />
        : null
      }
    </div>
  );
};
