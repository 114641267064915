import styled from 'styled-components';

import { ReactComponent as BaseCancelIcon } from '../../../assets/images/cancel.svg';

const Background = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CancelIcon = styled(BaseCancelIcon)`
  cursor: pointer;
  height: 18px;
  width: 18px;
`;

const CancelIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  margin-bottom: 7px;
  margin-top: 25px;
`;

const Container = styled.div`
  background-color: #fff;
  height: 177px;
  padding-left: 57px;
  text-align: left;
  width: 432px;
`;

const Description = styled.div`
  margin-bottom: 80px;
`;

const SubmitButtonContainer = styled.div`
  margin-left: 20px;
  margin-right: 30px;
`;

const Window = styled.div`
  background-color: #fff;
  border-radius: 2px;
  position: relative;
`;

export {
  Background,
  ButtonsContainer,
  CancelIcon,
  CancelIconContainer,
  Container,
  Description,
  SubmitButtonContainer,
  Window,
};
