import React, {
  useState,
} from 'react';

import {
  Button,
  CheckBox,
  TextBox,
} from '../../atoms';

import {
  CheckBoxContainer,
  Container,
  TextBoxContainer,
} from './style';

export type SearchSKUsParams = {
  orderGroupName: string | null
  code: string | null
  name: string | null
  specName: string | null
  hasEndOfSalesAt: boolean
};

export interface SearchSKUsFormProps {
  /**
   * 検索パラメーター
   */
  searchParams: SearchSKUsParams

  /**
   * 検索ボタンが押された時に呼び出されるハンドラー
   */
  onSubmitHandler: (params: SearchSKUsParams) => void
}

export const SearchSKUsForm: React.FC<SearchSKUsFormProps> = ({
  searchParams,
  onSubmitHandler,
}) => {

  const [orderGroupName, setOrderGroupName] = useState(searchParams.orderGroupName);
  const [code, setCode] = useState(searchParams.code);
  const [name, setName] = useState(searchParams.name);
  const [specName, setSpecName] = useState(searchParams.specName);
  const [hasEndOfSalesAt, setHasEndOfSalesAt] = useState(searchParams.hasEndOfSalesAt);

  return (
    <Container>
      <TextBoxContainer>
        <TextBox
          id='search-skus-form-order-group-name-input'
          label='発注グループ'
          type='text'
          height={40}
          width={200}
          defaultValue={searchParams.orderGroupName != null? searchParams.orderGroupName : ''}
          onChangeHandler={(value) => {
            if (value === '') {
              setOrderGroupName(null);
            } else {
              setOrderGroupName(value);
            }
          }}
        />
      </TextBoxContainer>
      <TextBoxContainer>
        <TextBox
          id='search-skus-form-code-input'
          label='JANコード'
          type='text'
          height={40}
          width={200}
          defaultValue={searchParams.code != null? searchParams.code : ''}
          onChangeHandler={(value) => {
            if (value === '') {
              setCode(null);
            } else {
              setCode(value);
            }
          }}
        />
      </TextBoxContainer>
      <TextBoxContainer>
        <TextBox
          id='search-skus-form-name-input'
          label='SKU'
          type='text'
          height={40}
          width={200}
          defaultValue={searchParams.name != null? searchParams.name : ''}
          onChangeHandler={(value) => {
            if (value === '') {
              setName(null);
            } else {
              setName(value);
            }
          }}
        />
      </TextBoxContainer>
      <TextBoxContainer>
        <TextBox
          id='search-skus-form-spec-name-input'
          label='規格'
          type='text'
          height={40}
          width={200}
          defaultValue={searchParams.specName != null? searchParams.specName : ''}
          onChangeHandler={(value) => {
            if (value === '') {
              setSpecName(null);
            } else {
              setSpecName(value);
            }
          }}
        />
      </TextBoxContainer>
      <CheckBoxContainer>
        <CheckBox
          id='search-skus-form-has-end-of-order-at-checkbox'
          label='終売日あり'
          defaultValue={searchParams.hasEndOfSalesAt}
          onChangeHandler={(value) => {
            setHasEndOfSalesAt(value);
          }}
        />
      </CheckBoxContainer>
      <Button
        styleType='tertiary'
        label='検索'
        width={76}
        onClickHandler={() => {
          onSubmitHandler({
            orderGroupName,
            code,
            name,
            specName,
            hasEndOfSalesAt,
          });
        }}
        data-testid='search-skus-form-search-button'
      />
    </Container>
  );
};
