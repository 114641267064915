import React from 'react';
import {
  Link,
} from 'react-router-dom';

import {
  Container,
  LabelsContainer,
  LabelNumberOfProducts,
  Panel,
  PanelTitle,
  PanelTitlesContainer,
} from './style';

export interface AlertPanelProps {
  /**
    * 発注アラートがある発注グループの数
    */
  numberOfOrderNeededOrderGroups: number | null

  /**
   * 調整アラートがあるSKUの数
   */
  numberOfAdjustmentNeededProducts: number | null
}

export const AlertPanel: React.FC<AlertPanelProps> = ({
  numberOfOrderNeededOrderGroups,
  numberOfAdjustmentNeededProducts,
}) => (
  <Container>
    <Panel>
      <PanelTitlesContainer>
        <PanelTitle>発注アラート</PanelTitle>
        <Link to='/order-groups'>詳細</Link>
      </PanelTitlesContainer>
      <LabelsContainer>
        <LabelNumberOfProducts>{numberOfOrderNeededOrderGroups != null? numberOfOrderNeededOrderGroups : '-'}</LabelNumberOfProducts>
        <span>発注グループ</span>
      </LabelsContainer>
    </Panel>
    <Panel>
      <PanelTitlesContainer>
        <PanelTitle>調整アラート</PanelTitle>
        <Link to='/order-groups'>詳細</Link>
      </PanelTitlesContainer>
      <LabelsContainer>
        <LabelNumberOfProducts>{numberOfAdjustmentNeededProducts != null? numberOfAdjustmentNeededProducts : '-'}</LabelNumberOfProducts>
        <span>SKU</span>
      </LabelsContainer>
    </Panel>
  </Container>
);
