/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAlertSummaryResponse
 */
export interface GetAlertSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof GetAlertSummaryResponse
     */
    numberOfOrderNeededOrderGroups: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetAlertSummaryResponse
     */
    numberOfAdjustmentNeededProducts: number | null;
}

export function GetAlertSummaryResponseFromJSON(json: any): GetAlertSummaryResponse {
    return GetAlertSummaryResponseFromJSONTyped(json, false);
}

export function GetAlertSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAlertSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfOrderNeededOrderGroups': json['numberOfOrderNeededOrderGroups'],
        'numberOfAdjustmentNeededProducts': json['numberOfAdjustmentNeededProducts'],
    };
}

export function GetAlertSummaryResponseToJSON(value?: GetAlertSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfOrderNeededOrderGroups': value.numberOfOrderNeededOrderGroups,
        'numberOfAdjustmentNeededProducts': value.numberOfAdjustmentNeededProducts,
    };
}


