import React, {
  useMemo,
  useState,
} from 'react';

import { Tooltip } from '../../atoms';

import './SentenceBox.css';

export interface SentenceBoxProps {
  dataTestId?: string
  /**
   * フォームの初期値
   */
  defaultValue?: string;

  /**
   * 無効かどうか
   */
  disabled?: boolean;

  /**
   * 必須かどうか
   */
  required?: boolean;

  /**
   * 値が変更されたら背景色を変えるかどうか
   */
  highlightOnChange?: boolean

  /**
   * 許容される最小値
   * number か date のみ適用される
   */
  min?: number | string;

  /**
   * 許容される最大値
   * number のみ適用される
   */
  max?: number;

  /**
   * フォーカスが外れた時に呼び出されるハンドラー
   */
  onBlurHandler?: (value: string, errorMessage: string) => void
}

export const SentenceBox: React.FC<SentenceBoxProps> = ({
  dataTestId = '',
  defaultValue = '',
  required = false,
  disabled = false,
  min,
  max,
  onBlurHandler = () => {},
}) => {
  const [value, setValue] = useState(defaultValue);

  const errorMessage = useMemo(() => {
    if (required && value === '') {
      return '入力して下さい。';
    } else if (min && value.length < min) {
      return `${min}文字以上で入力してください。`;
    } else if (max && value.length > max) {
      return `${max}文字以内で入力してください。`;
    } else {
      return '';
    }
  }, [min, max, required, value]);

  const isBackgroundYellow = useMemo(() => value !== defaultValue, [value, defaultValue]);
  const hasErr = useMemo(() => errorMessage !== '', [errorMessage]);

  const getClassName = useMemo(() => {
    let className = 'base-textarea';
    if (isBackgroundYellow) {
      className += ' background-yellow';
    }
    if (hasErr) {
      className += ' has-err';
    }
    if (disabled) {
      className += ' disabled';
    }
    return className;
  }, [isBackgroundYellow, hasErr, disabled]);

  return (
    <div>
      <textarea
        data-testid={dataTestId}
        className={getClassName}
        value={value}
        onChange={(event) => {
          if (disabled === true) {
            // jsdom may not have pointer event feature.
            // Ref: https://github.com/jsdom/jsdom/issues/2527
            return;
          }
          setValue(() => event.target.value);
        }}
        onBlur={() => {
          onBlurHandler(value, errorMessage);
        }}
      />
      {hasErr ?
        <div className='sentence-box-error-tooltip-container'>
          <Tooltip
            id='sentence-box-error-tooltip'
            text={errorMessage}
            top={0}
            width={220}
          />
        </div> : null
      }
    </div>
  );
};
