/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutDatabaseOrderGroupsRequestOrderGroups,
    PutDatabaseOrderGroupsRequestOrderGroupsFromJSON,
    PutDatabaseOrderGroupsRequestOrderGroupsFromJSONTyped,
    PutDatabaseOrderGroupsRequestOrderGroupsToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutDatabaseOrderGroupsRequest
 */
export interface PutDatabaseOrderGroupsRequest {
    /**
     * 
     * @type {Array<PutDatabaseOrderGroupsRequestOrderGroups>}
     * @memberof PutDatabaseOrderGroupsRequest
     */
    orderGroups: Array<PutDatabaseOrderGroupsRequestOrderGroups>;
}

export function PutDatabaseOrderGroupsRequestFromJSON(json: any): PutDatabaseOrderGroupsRequest {
    return PutDatabaseOrderGroupsRequestFromJSONTyped(json, false);
}

export function PutDatabaseOrderGroupsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutDatabaseOrderGroupsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderGroups': ((json['orderGroups'] as Array<any>).map(PutDatabaseOrderGroupsRequestOrderGroupsFromJSON)),
    };
}

export function PutDatabaseOrderGroupsRequestToJSON(value?: PutDatabaseOrderGroupsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderGroups': ((value.orderGroups as Array<any>).map(PutDatabaseOrderGroupsRequestOrderGroupsToJSON)),
    };
}


