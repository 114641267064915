/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatabaseOrderGroup,
    DatabaseOrderGroupFromJSON,
    DatabaseOrderGroupFromJSONTyped,
    DatabaseOrderGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOwnOrderGroupsResponse
 */
export interface GetOwnOrderGroupsResponse {
    /**
     * 
     * @type {Array<DatabaseOrderGroup>}
     * @memberof GetOwnOrderGroupsResponse
     */
    orderGroups: Array<DatabaseOrderGroup>;
}

export function GetOwnOrderGroupsResponseFromJSON(json: any): GetOwnOrderGroupsResponse {
    return GetOwnOrderGroupsResponseFromJSONTyped(json, false);
}

export function GetOwnOrderGroupsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOwnOrderGroupsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderGroups': ((json['orderGroups'] as Array<any>).map(DatabaseOrderGroupFromJSON)),
    };
}

export function GetOwnOrderGroupsResponseToJSON(value?: GetOwnOrderGroupsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderGroups': ((value.orderGroups as Array<any>).map(DatabaseOrderGroupToJSON)),
    };
}


