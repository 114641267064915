import styled from 'styled-components';

const AccuracyLabelContainer = styled.div`
  margin-right: 16px;
`;

const LabelContainer = styled.div`
  margin-right: 16px;
`;

const ProductCodeLabel = styled.span`
  font-weight: bold;
`;

const ProductInfoContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`;

const ProductInfoLabel = styled.span`
  color: #767676;
  font-weight: bold;
`;

const ProductInfoValue = styled.span`
  color: #767676;
  margin-right: 16px;
`;

const ProductNameLabel = styled.span`
  font-weight: bold;
  margin-left: 23px;
`;

const ProductPanelContainer = styled.div`
  background-color: #fff;
`;

const ProductSpecLabel = styled.span`
  font-weight: bold;
  margin-left: 14px;
`;

const ProductTitleContainer = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

const ProductTitleLabelContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export {
  AccuracyLabelContainer,
  LabelContainer,
  ProductCodeLabel,
  ProductInfoContainer,
  ProductInfoLabel,
  ProductInfoValue,
  ProductNameLabel,
  ProductPanelContainer,
  ProductSpecLabel,
  ProductTitleContainer,
  ProductTitleLabelContainer,
};
