/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DemandAggregation,
    DemandAggregationFromJSON,
    DemandAggregationFromJSONTyped,
    DemandAggregationToJSON,
} from './';

/**
 * 
 * @export
 * @interface SKU
 */
export interface SKU {
    /**
     * JANコード
     * @type {string}
     * @memberof SKU
     */
    code: string;
    /**
     * 商品名
     * @type {string}
     * @memberof SKU
     */
    name: string | null;
    /**
     * 規格名
     * @type {string}
     * @memberof SKU
     */
    specName: string | null;
    /**
     * サプライヤー名
     * @type {string}
     * @memberof SKU
     */
    supplierName: string | null;
    /**
     * 入数
     * @type {number}
     * @memberof SKU
     */
    quantityPerCase: number;
    /**
     * M3
     * @type {number}
     * @memberof SKU
     */
    m3: number;
    /**
     * 発注グループのID
     * @type {number}
     * @memberof SKU
     */
    orderGroupID: number;
    /**
     * 店発単
     * @type {number}
     * @memberof SKU
     */
    minNumberOfProductsPerDelivery: number | null;
    /**
     * 売価
     * @type {number}
     * @memberof SKU
     */
    price: number | null;
    /**
     * 欠品許容ランク
     * @type {string}
     * @memberof SKU
     */
    shortageToleranceRank: SKUShortageToleranceRankEnum;
    /**
     * 終売日
     * @type {Date}
     * @memberof SKU
     */
    endOfSalesAt: Date | null;
    /**
     * 店舗数
     * @type {number}
     * @memberof SKU
     */
    numberOfStores: number;
    /**
     * 
     * @type {DemandAggregation}
     * @memberof SKU
     */
    demandAggregation: DemandAggregation;
}

/**
* @export
* @enum {string}
*/
export enum SKUShortageToleranceRankEnum {
    S = 'S',
    A = 'A',
    B = 'B',
    C = 'C'
}

export function SKUFromJSON(json: any): SKU {
    return SKUFromJSONTyped(json, false);
}

export function SKUFromJSONTyped(json: any, ignoreDiscriminator: boolean): SKU {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'specName': json['specName'],
        'supplierName': json['supplierName'],
        'quantityPerCase': json['quantityPerCase'],
        'm3': json['m3'],
        'orderGroupID': json['orderGroupID'],
        'minNumberOfProductsPerDelivery': json['minNumberOfProductsPerDelivery'],
        'price': json['price'],
        'shortageToleranceRank': json['shortageToleranceRank'],
        'endOfSalesAt': (json['endOfSalesAt'] === null ? null : new Date(json['endOfSalesAt'])),
        'numberOfStores': json['numberOfStores'],
        'demandAggregation': DemandAggregationFromJSON(json['demandAggregation']),
    };
}

export function SKUToJSON(value?: SKU | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'specName': value.specName,
        'supplierName': value.supplierName,
        'quantityPerCase': value.quantityPerCase,
        'm3': value.m3,
        'orderGroupID': value.orderGroupID,
        'minNumberOfProductsPerDelivery': value.minNumberOfProductsPerDelivery,
        'price': value.price,
        'shortageToleranceRank': value.shortageToleranceRank,
        'endOfSalesAt': (value.endOfSalesAt === null ? null : value.endOfSalesAt.toISOString().substr(0,10)),
        'numberOfStores': value.numberOfStores,
        'demandAggregation': DemandAggregationToJSON(value.demandAggregation),
    };
}


