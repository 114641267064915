import { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import firebase from 'firebase/app';

import {
  Configuration,
  DatabaseOrderGroup,
  DefaultApi,
  DeleteDatabaseOrderGroupsOperationRequest,
  DeleteDatabaseOrderGroupsRequest,
  GetDatabaseOrderGroupsRequest,
  PutDatabaseOrderGroupsOperationRequest,
  PutDatabaseOrderGroupsRequest,
  User,
} from '../api-client';

export const useDatabaseOrderGroups = () => {
  const [orderGroups, setOrderGroups] = useState<Array<DatabaseOrderGroup> | undefined>([]);
  const [selectableOperators, setSelectableOperators] = useState<Array<User> | undefined>([]);

  useEffect(() => {
    firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        return api.getOperators();
      })
      .then((response) => {
        setSelectableOperators(response);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, []);

  const getOrderGroups = (
    orderGroupName?: string,
    operatorId?: number,
  ) => {
    // 検索中はローディングを表示させるため, 空にする.
    setOrderGroups(undefined);
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const param: GetDatabaseOrderGroupsRequest = {
          orderGroupName,
          operatorId,
        };
        return api.getDatabaseOrderGroups(param);
      })
      .then((response) => {
        setOrderGroups(response.orderGroups);
        setSelectableOperators(response.operators);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  const putOrderGroups = (
    orderGroups: Array<DatabaseOrderGroup>,
    orderGroupName?: string,
    operatorId?: number,
  ) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const bodyParam: PutDatabaseOrderGroupsRequest = {
          orderGroups
        };
        const param: PutDatabaseOrderGroupsOperationRequest = {
          bodyParam
        };
        return api.putDatabaseOrderGroups(param)
          .then(() => {
            setOrderGroups(undefined);
            const param: GetDatabaseOrderGroupsRequest = {
              orderGroupName,
              operatorId,
            };
            return api.getDatabaseOrderGroups(param);
          })
          .then((response) => {
            setOrderGroups(response.orderGroups);
            setSelectableOperators(response.operators);
          });
      });
  };

  const deleteOrderGroups = (
    orderGroups: Array<DatabaseOrderGroup>,
    orderGroupName?: string,
    operatorId?: number,
  ) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const bodyParam: DeleteDatabaseOrderGroupsRequest = {
          ids: orderGroups.map((orderGroup) => orderGroup.id)
        };
        const param: DeleteDatabaseOrderGroupsOperationRequest = {
          bodyParam
        };
        return api.deleteDatabaseOrderGroups(param)
          .then(() => {
            setOrderGroups(undefined);
            const param: GetDatabaseOrderGroupsRequest = {
              orderGroupName,
              operatorId,
            };
            return api.getDatabaseOrderGroups(param);
          })
          .then((response) => {
            setOrderGroups(response.orderGroups);
            setSelectableOperators(response.operators);
          });
      });
  };

  return {
    orderGroups,
    selectableOperators,
    getOrderGroups,
    putOrderGroups,
    deleteOrderGroups,
  };
};
