import React from 'react';
import {
  useHistory
} from 'react-router-dom';

import {
  OrderGroup,
} from '../../../api-client';

import {
  BaseOrderGroupsTable,
  OrderGroupsTableBodyRow,
  OrderGroupsTableData,
  OrderGroupsTableHeader,
  UnorderedProductsLabel,
} from './style';

export interface OrderGroupsTableProps {
  /**
   * 発注グループのリスト
   */
  orderGroups: OrderGroup[]

  /**
   * 担当者名の列を表示するかどうか
   */
  isOperatorNameVisible: boolean
}

export const OrderGroupsTable: React.FC<OrderGroupsTableProps> = ({
  orderGroups,
  isOperatorNameVisible,
}) => {
  const history = useHistory();

  return (
    <BaseOrderGroupsTable>
      <thead>
        <tr>
          <OrderGroupsTableHeader width={224} textalign='left'>発注グループ</OrderGroupsTableHeader>
          <OrderGroupsTableHeader width={319} textalign='left'>サプライヤー</OrderGroupsTableHeader>
          {isOperatorNameVisible === true? (
            <OrderGroupsTableHeader width={110} textalign='left'>担当者</OrderGroupsTableHeader>
          ) : null}
          <OrderGroupsTableHeader width={126}>発注アラート</OrderGroupsTableHeader>
          <OrderGroupsTableHeader width={159}>調整アラートSKU</OrderGroupsTableHeader>
          <OrderGroupsTableHeader width={311}></OrderGroupsTableHeader>
          <OrderGroupsTableHeader></OrderGroupsTableHeader>
        </tr>
      </thead>
      <tbody>
        {orderGroups.map((orderGroup, index) => {
          return (
            <OrderGroupsTableBodyRow key={index} onClick={() => history.push(`/order-groups/${orderGroup.id}/destocking`)}>
              <OrderGroupsTableData width={224}  textalign='left'>{orderGroup.name}</OrderGroupsTableData>
              <OrderGroupsTableData width={319}  textalign='left'>{orderGroup.supplierName != null? orderGroup.supplierName : '-'}</OrderGroupsTableData>
              {isOperatorNameVisible === true? (
                <OrderGroupsTableData width={110} textalign='left'>{orderGroup.operatorName}</OrderGroupsTableData>
              ) : null}
              <OrderGroupsTableData width={126}>{orderGroup.hasProductsThatNeedOrder? 'あり' : '-'}</OrderGroupsTableData>
              <OrderGroupsTableData width={159}>{orderGroup.numberOfProductsThatNeedAdjustment > 0? orderGroup.numberOfProductsThatNeedAdjustment : '-'}</OrderGroupsTableData>
              <OrderGroupsTableData width={311} textalign='left'><UnorderedProductsLabel>{orderGroup.hasUnorderedProducts? '未発注SKUがあります' : ''}</UnorderedProductsLabel></OrderGroupsTableData>
              <OrderGroupsTableData></OrderGroupsTableData>
            </OrderGroupsTableBodyRow>
          );
        })}
      </tbody>
    </BaseOrderGroupsTable>
  );
};
