import React from 'react';

import {
  useAlertSummary,
  useCurrentMdWeek,
} from '../../hooks';

import {
  AlertPanel,
  ErrorMessagePanel,
  LoadingSpinner,
  PageHeader
} from '../../components/atoms';

import {
  AlertPanelContainer,
  AlertPanelTitle,
  CurrentMdWeek,
  ErrorMessagePanelContainer,
  LoadingSpinnerContainer,
} from './style';

export interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = () => {

  const {
    currentMdWeek,
  } = useCurrentMdWeek();

  const {
    numberOfOrderNeededOrderGroups,
    numberOfAdjustmentNeededProducts,
    statusCode,
  } = useAlertSummary();

  return statusCode != null && statusCode !== 200? (
    <ErrorMessagePanelContainer>
      <ErrorMessagePanel statusCode={statusCode} />
    </ErrorMessagePanelContainer>
  ) : (
    <>
      <PageHeader
        pageTitle='ダッシュボード'
        backLink={false}
      />
      <CurrentMdWeek>当週：{currentMdWeek != null? currentMdWeek.mdWeekNum : ''}週（MD週）</CurrentMdWeek>
      <AlertPanelContainer>
        <AlertPanelTitle>アラート</AlertPanelTitle>
        {numberOfOrderNeededOrderGroups !== undefined && numberOfAdjustmentNeededProducts !== undefined? (
          <AlertPanel
            numberOfOrderNeededOrderGroups={numberOfOrderNeededOrderGroups}
            numberOfAdjustmentNeededProducts={numberOfAdjustmentNeededProducts}
          />
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
      </AlertPanelContainer>
    </>
  );
};
