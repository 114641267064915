import styled from 'styled-components';

const BackToTopLinkContainer = styled.div`
  color: var(--di-text-link-color);
  cursor: pointer;
  margin-top: 6px;
  text-align: right;
`;

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: var(--di-page-header-height);
  margin-top: calc(-1 * var(--di-page-header-height));
  padding-right: 22px;
`;

const ContentsContainer = styled.div`
  background-color: #fff;
  margin-left: 22px;
  margin-right: 22px;
  padding-bottom: 31px;
  padding-top: 19px;
`;

const ContentsContainerOuter = styled.div`
  padding-bottom: 80px;
`;

const EmptyOrderSummaryMessage = styled.div`
  margin-bottom: 58px;
  margin-left: 32px;
`;

const EmptySKUsMessage = styled.div`
  margin-bottom: 20px;
  margin-left: 32px;
`;

const ErrorMessagePanelContainer = styled.div`
  height: calc(100% - 60px);
  padding-bottom: 30px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 30px;
`;

const LeadtimeFormLabel = styled.div`
  margin-left: 16px;
  margin-right: 9px;
`;

const LeadtimeFormsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-left: 22px;
  margin-right: 22px;
  margin-top: 26px;
`;

const LoadingSpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const OrderButtonContainer = styled.div`
  margin-left: 16px;
`;

const OrderSummaryTableContainer = styled.div`
  margin-bottom: 31px;
  margin-left: 26px;
  margin-right: 29px;
`;

const ProductPanelContainer = styled.div`
  margin-top: calc(0px - var(--global-header-height));
  padding-bottom: 20px;
  padding-left: 20px;
  padding-top: 45px;
`;

const ProductPanelContainerOuter = styled.div`
  border-top: solid 1px var(--di-table-border-color);
  margin-left: 26.5px;
  margin-right: 32.5px;
  padding-top: 16px;
`;

const RecalculatingMessage = styled.span`
  color: #fff;
  font-size: 16px;
  margin-left: 45px;
`;

const RecalculatingMessageContainer = styled.div`
  align-items: center;
  background-color: #333333d3;
  bottom: 0;
  display: flex;
  height: 73px;
  position: fixed;
  width: calc(100% - var(--global-sidebar-width));
  z-index: 3;
`;

const SectionName = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.56px;
  margin-bottom: 21px;
  margin-left: 32px;
`;

const TextBoxContainer = styled.div`
  position: relative;
`;

const ToastContainer = styled.div`
  position: absolute;
  right: 22px;
  top: 107px;
  z-index: 399;
`;

export {
  BackToTopLinkContainer,
  ButtonsContainer,
  ContentsContainer,
  ContentsContainerOuter,
  EmptyOrderSummaryMessage,
  EmptySKUsMessage,
  ErrorMessagePanelContainer,
  LeadtimeFormLabel,
  LeadtimeFormsContainer,
  LoadingSpinnerContainer,
  OrderButtonContainer,
  OrderSummaryTableContainer,
  ProductPanelContainer,
  ProductPanelContainerOuter,
  RecalculatingMessage,
  RecalculatingMessageContainer,
  SectionName,
  TextBoxContainer,
  ToastContainer,
};
