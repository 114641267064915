/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ErrorTitle {
    _400BadRequestEmailAlreadyExists = '400 Bad Request, Email already exists.',
    _400BadRequestEmailFormatIsInvalid = '400 Bad Request, Email format is invalid.',
    _400BadRequestUnexpectedAuthError = '400 Bad Request, Unexpected auth error.',
    _400BadRequestInvalidJsonBody = '400 Bad Request, Invalid JSON body.',
    _400BadRequestWrongUserIdFormat = '400 Bad Request, Wrong userId format.',
    _400BadRequestUserHasOrderGroup = '400 Bad Request, User has OrderGroup.',
    _403ForbiddenInsufficientPermission = '403 Forbidden, Insufficient Permission.',
    _404NotFoundResourceNotFound = '404 Not Found, Resource Not Found.',
    _400BadRequestJanCodeNotFound = '400 Bad Request, JAN code not found',
    _400BadRequestSkuAlreadyExists = '400 Bad Request, SKU Already exists.',
    _400BadRequestSkuRegisteredByOthers = '400 Bad Request, SKU registered by others.',
    _400BadRequestDcIsInvalid = '400 Bad Request, DC is invalid.',
    _400BadRequestOrderGroupNameIsDuplicated = '400 Bad Request, OrderGroup name is duplicated.',
    _400BadRequestOrderGroupNameInParametersIsDuplicated = '400 Bad Request, OrderGroup name in parameters is duplicated.',
    _400BadRequestOrderGroupNameHasInvalidChars = '400 Bad Request, OrderGroup name has invalid chars.',
    _400BadRequestOrderGroupNCycleParameterIsInvalid = '400 Bad Request, OrderGroup N_CYCLE_PARAMETER is invalid.',
    _400BadRequestOnlyOperatorCanCreateOrderGroup = '400 Bad Request, Only Operator can create order group.',
    _400BadRequestManagerCanChangeOperatorIdOnly = '400 Bad Request, Manager can change operatorId only.',
    _400BadRequestCanNotDeleteOrderGroupsHavingSkus = '400 Bad Request, Can not delete OrderGroups having SKUs.',
    _400BadRequestCanNotDeleteOrderGroupsBelongingToOthers = '400 Bad Request, Can not delete OrderGroups belonging to others.',
    _400BadRequestCanNotDeleteOrderGroupsNotFound = '400 Bad Request, Can not delete OrderGroups Not Found.',
    _400BadRequestDemandAggregationIsInvalid = '400 Bad Request, DemandAggregation is invalid.'
}

export function ErrorTitleFromJSON(json: any): ErrorTitle {
    return ErrorTitleFromJSONTyped(json, false);
}

export function ErrorTitleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorTitle {
    return json as ErrorTitle;
}

export function ErrorTitleToJSON(value?: ErrorTitle | null): any {
    return value as any;
}

