import React from 'react';
import {
  useHistory,
} from 'react-router-dom';

import {
  User,
  UserRole,
} from '../../../api-client';

import {
  BaseUsersTable,
  UserName,
  UsersTableBodyRow,
  UsersTableData,
  UsersTableHeader
} from './style';

const roleLabel = (role: UserRole) => {
  switch (role) {
    case UserRole.Manager:
      return '部門管理者';
    case UserRole.Operator:
      return '一般ユーザー';
    default: // ロールは必ず付与されるので、このケースは実際には存在しない想定
      return '';
  }
};

export interface UsersTableProps {
  /**
   * ログイン中のユーザー
   */
  currentUser: User,

  /**
   * ユーザーのリスト
   */
  users: User[]
}

export const UsersTable: React.FC<UsersTableProps> = ({
  currentUser,
  users
}) => {

  const history = useHistory();

  return (
    <BaseUsersTable>
      <thead>
        <tr>
          <UsersTableHeader width={210}>ユーザー名</UsersTableHeader>
          <UsersTableHeader width={319}>メールアドレス</UsersTableHeader>
          <UsersTableHeader width={183}>ユーザー種別</UsersTableHeader>
          <UsersTableHeader></UsersTableHeader>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return (
            <UsersTableBodyRow
              key={user.id}
              clickable={currentUser.role === UserRole.Manager || currentUser.id === user.id}
              onClick={() => {
                if (currentUser.role === UserRole.Manager || currentUser.id === user.id) {
                  history.push(`/users/${user.id}`);
                }
              }}
            >
              <UsersTableData>
                <UserName>{user.name}{currentUser.id === user.id? '（あなた）' : ''}</UserName>
              </UsersTableData>
              <UsersTableData>{user.email}</UsersTableData>
              <UsersTableData>{roleLabel(user.role)}</UsersTableData>
              <UsersTableData></UsersTableData>
            </UsersTableBodyRow>
          );
        })}
      </tbody>
    </BaseUsersTable>
  );
};
