import React from 'react';

import {
  Button,
} from '../../atoms';

import {
  Background,
  ButtonsContainer,
  CancelIcon,
  CancelIconContainer,
  Container,
  Description,
  SubmitButtonContainer,
  Window,
} from './style';

export interface StopAdjustmentAlertModalProps {
  /**
   * 無効化するかどうか
   */
  disabled: boolean
  /**
   * 閉じるボタンや背景がクリックされた時に呼び出されるハンドラー
   */
  onCloseHandler: () => void

  /**
   * 実行ボタンがクリックされた時に呼び出されるハンドラー
   */
  onSubmitHandler: () => void
}

export const StopAdjustmentAlertModal: React.FC<StopAdjustmentAlertModalProps> = ({
  disabled,
  onCloseHandler,
  onSubmitHandler,
}) => (
  <Background
    onClick={onCloseHandler}
  >
    <Window>
      <CancelIconContainer
        onClick={onCloseHandler}
        data-testid='close'
      >
        <CancelIcon />
      </CancelIconContainer>
      <Container onClick={(e) => e.stopPropagation()}>
        <Description>調整アラートを止めますか?</Description>
        <ButtonsContainer>
          <Button
            styleType='tertiary'
            label='キャンセル'
            width={120}
            onClickHandler={onCloseHandler}
            data-testid='cancel-stop-adjustment-alert-button'
          />
          <SubmitButtonContainer>
            <Button
              styleType='primary'
              label='はい'
              width={76}
              disabled={disabled}
              onClickHandler={onSubmitHandler}
              data-testid='submit-stop-adjustment-alert-button'
            />
          </SubmitButtonContainer>
        </ButtonsContainer>
      </Container>
    </Window>
  </Background>
);
