/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteSKUsRequest
 */
export interface DeleteSKUsRequest {
    /**
     * 削除対象のSKUのJANコード
     * @type {Array<string>}
     * @memberof DeleteSKUsRequest
     */
    codes: Array<string>;
}

export function DeleteSKUsRequestFromJSON(json: any): DeleteSKUsRequest {
    return DeleteSKUsRequestFromJSONTyped(json, false);
}

export function DeleteSKUsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteSKUsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codes': json['codes'],
    };
}

export function DeleteSKUsRequestToJSON(value?: DeleteSKUsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codes': value.codes,
    };
}


