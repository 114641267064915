import styled from 'styled-components';

const CheckBoxContainer = styled.div`
  margin-right: 23px;
`;

const CheckBoxesContainer = styled.div`
  margin-bottom: 13px;
  display: flex;
`;

const Container = styled.div`
  align-items: flex-end;
  display: flex;
`;

const TextBoxContainer = styled.div`
  margin-right: 16px;
`;

export {
  CheckBoxContainer,
  CheckBoxesContainer,
  Container,
  TextBoxContainer,
};
