/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MdWeekDate,
    MdWeekDateFromJSON,
    MdWeekDateFromJSONTyped,
    MdWeekDateToJSON,
    ShipmentReason,
    ShipmentReasonFromJSON,
    ShipmentReasonFromJSONTyped,
    ShipmentReasonToJSON,
} from './';

/**
 * 在庫計画
 * @export
 * @interface InventoryPlan
 */
export interface InventoryPlan {
    /**
     * 
     * @type {MdWeekDate}
     * @memberof InventoryPlan
     */
    mdWeekDate: MdWeekDate;
    /**
     * 販売予測数（ユーザー）
     * @type {number}
     * @memberof InventoryPlan
     */
    expectedSalesAmountByUser?: number | null;
    /**
     * 販売予測数（AI）
     * @type {number}
     * @memberof InventoryPlan
     */
    expectedSalesAmountByAI?: number | null;
    /**
     * 販売実績数
     * @type {number}
     * @memberof InventoryPlan
     */
    actualSalesAmount: number | null;
    /**
     * 昨年販売数
     * @type {number}
     * @memberof InventoryPlan
     */
    lastMdYearActualSalesAmount: number | null;
    /**
     * 推奨発注数
     * @type {number}
     * @memberof InventoryPlan
     */
    recommendedOrderQuantity: number | null;
    /**
     * 
     * @type {ShipmentReason}
     * @memberof InventoryPlan
     */
    shipmentReason: ShipmentReason;
    /**
     * 出荷加減数
     * @type {number}
     * @memberof InventoryPlan
     */
    shipmentQuantity: number | null;
    /**
     * 発注起案数
     * @type {number}
     * @memberof InventoryPlan
     */
    proposalOrderQuantity: number | null;
    /**
     * 発注確定数
     * @type {number}
     * @memberof InventoryPlan
     */
    confirmedOrderQuantity: number | null;
    /**
     * 安全在庫数
     * @type {number}
     * @memberof InventoryPlan
     */
    numberOfSafeDCInventory: number | null;
    /**
     * 安全在庫週数
     * @type {number}
     * @memberof InventoryPlan
     */
    numberOfSafeInventoryWeeks: number | null;
    /**
     * 最大在庫数
     * @type {number}
     * @memberof InventoryPlan
     */
    numberOfMaxDCInventory: number | null;
    /**
     * 最大在庫週数
     * @type {number}
     * @memberof InventoryPlan
     */
    numberOfMaxInventoryWeeks: number | null;
    /**
     * DC在庫予測数
     * @type {number}
     * @memberof InventoryPlan
     */
    expectedDCInventoryQuantity: number | null;
    /**
     * DC在庫週数
     * @type {number}
     * @memberof InventoryPlan
     */
    numberOfDCInventoryWeeks: number | null;
    /**
     * DC在庫が足りなくなる可能性があるかどうか
     * @type {boolean}
     * @memberof InventoryPlan
     */
    hasShortageRiskOfDCInventory: boolean;
    /**
     * 修正発注数
     * @type {number}
     * @memberof InventoryPlan
     */
    adjustedOrderQuantity: number | null;
    /**
     * 不足数
     * @type {number}
     * @memberof InventoryPlan
     */
    deficientQuantity: number | null;
    /**
     * 超過数
     * @type {number}
     * @memberof InventoryPlan
     */
    excessQuantity: number | null;
    /**
     * M3
     * @type {number}
     * @memberof InventoryPlan
     */
    m3: number | null;
    /**
     * 店頭在庫基準数
     * @type {number}
     * @memberof InventoryPlan
     */
    storeInventoryQuantityStandard: number | null;
    /**
     * 店頭在庫数
     * @type {number}
     * @memberof InventoryPlan
     */
    storeInventoryQuantity: number | null;
    /**
     * 発注が未確定かどうか
     * @type {boolean}
     * @memberof InventoryPlan
     */
    isOrderConfirmed: boolean;
    /**
     * 調整アラートを停止するか
     * @type {boolean}
     * @memberof InventoryPlan
     */
    isAdjustmentAlertStopped: boolean;
    /**
     * 出荷予測数
     * @type {number}
     * @memberof InventoryPlan
     */
    predictedShipmentQuantity: number | null;
    /**
     * 需要集約対象かどうか
     * @type {boolean}
     * @memberof InventoryPlan
     */
    isDemandAggregationTarget: boolean;
}

export function InventoryPlanFromJSON(json: any): InventoryPlan {
    return InventoryPlanFromJSONTyped(json, false);
}

export function InventoryPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mdWeekDate': MdWeekDateFromJSON(json['mdWeekDate']),
        'expectedSalesAmountByUser': !exists(json, 'expectedSalesAmountByUser') ? undefined : json['expectedSalesAmountByUser'],
        'expectedSalesAmountByAI': !exists(json, 'expectedSalesAmountByAI') ? undefined : json['expectedSalesAmountByAI'],
        'actualSalesAmount': json['actualSalesAmount'],
        'lastMdYearActualSalesAmount': json['lastMdYearActualSalesAmount'],
        'recommendedOrderQuantity': json['recommendedOrderQuantity'],
        'shipmentReason': ShipmentReasonFromJSON(json['shipmentReason']),
        'shipmentQuantity': json['shipmentQuantity'],
        'proposalOrderQuantity': json['proposalOrderQuantity'],
        'confirmedOrderQuantity': json['confirmedOrderQuantity'],
        'numberOfSafeDCInventory': json['numberOfSafeDCInventory'],
        'numberOfSafeInventoryWeeks': json['numberOfSafeInventoryWeeks'],
        'numberOfMaxDCInventory': json['numberOfMaxDCInventory'],
        'numberOfMaxInventoryWeeks': json['numberOfMaxInventoryWeeks'],
        'expectedDCInventoryQuantity': json['expectedDCInventoryQuantity'],
        'numberOfDCInventoryWeeks': json['numberOfDCInventoryWeeks'],
        'hasShortageRiskOfDCInventory': json['hasShortageRiskOfDCInventory'],
        'adjustedOrderQuantity': json['adjustedOrderQuantity'],
        'deficientQuantity': json['deficientQuantity'],
        'excessQuantity': json['excessQuantity'],
        'm3': json['m3'],
        'storeInventoryQuantityStandard': json['storeInventoryQuantityStandard'],
        'storeInventoryQuantity': json['storeInventoryQuantity'],
        'isOrderConfirmed': json['isOrderConfirmed'],
        'isAdjustmentAlertStopped': json['isAdjustmentAlertStopped'],
        'predictedShipmentQuantity': json['predictedShipmentQuantity'],
        'isDemandAggregationTarget': json['isDemandAggregationTarget'],
    };
}

export function InventoryPlanToJSON(value?: InventoryPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mdWeekDate': MdWeekDateToJSON(value.mdWeekDate),
        'expectedSalesAmountByUser': value.expectedSalesAmountByUser,
        'expectedSalesAmountByAI': value.expectedSalesAmountByAI,
        'actualSalesAmount': value.actualSalesAmount,
        'lastMdYearActualSalesAmount': value.lastMdYearActualSalesAmount,
        'recommendedOrderQuantity': value.recommendedOrderQuantity,
        'shipmentReason': ShipmentReasonToJSON(value.shipmentReason),
        'shipmentQuantity': value.shipmentQuantity,
        'proposalOrderQuantity': value.proposalOrderQuantity,
        'confirmedOrderQuantity': value.confirmedOrderQuantity,
        'numberOfSafeDCInventory': value.numberOfSafeDCInventory,
        'numberOfSafeInventoryWeeks': value.numberOfSafeInventoryWeeks,
        'numberOfMaxDCInventory': value.numberOfMaxDCInventory,
        'numberOfMaxInventoryWeeks': value.numberOfMaxInventoryWeeks,
        'expectedDCInventoryQuantity': value.expectedDCInventoryQuantity,
        'numberOfDCInventoryWeeks': value.numberOfDCInventoryWeeks,
        'hasShortageRiskOfDCInventory': value.hasShortageRiskOfDCInventory,
        'adjustedOrderQuantity': value.adjustedOrderQuantity,
        'deficientQuantity': value.deficientQuantity,
        'excessQuantity': value.excessQuantity,
        'm3': value.m3,
        'storeInventoryQuantityStandard': value.storeInventoryQuantityStandard,
        'storeInventoryQuantity': value.storeInventoryQuantity,
        'isOrderConfirmed': value.isOrderConfirmed,
        'isAdjustmentAlertStopped': value.isAdjustmentAlertStopped,
        'predictedShipmentQuantity': value.predictedShipmentQuantity,
        'isDemandAggregationTarget': value.isDemandAggregationTarget,
    };
}


