import React, {
  useState,
} from 'react';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';

import {
  OrderGroup,
  User,
  UserRole,
} from '../../api-client';

import {
  LoadingSpinner,
  OrderGroupsTable,
  PageHeader
} from '../../components/atoms';

import {
  SearchParams,
  SearchOrderGroupsForm,
} from '../../components/molecules';

import {
  useDestockingOrderGroups
} from '../../hooks';

import {
  LoadingSpinnerContainer,
  NoHitLabelContainer,
  NumberOfOrderGroups,
  OrderGroupsTableContainer,
  SearchFormContainer,
} from './style';

const useQuery = () => new URLSearchParams(useLocation().search);

const buildQueryString = (searchParams: SearchParams) =>
  `?order=${searchParams.hasProductsThatNeedOrder === true? 'true' : 'false'}&adjustment=${searchParams.hasProductsThatNeedAdjustment}&unordered=${searchParams.hasUnorderedProducts}&group-name=${searchParams.groupName}&operator-name=${searchParams.operatorName}`;

export interface OrderGroupsProps {
  user: User
}

export const OrderGroups: React.FC<OrderGroupsProps> = ({
  user,
}) => {

  const query = useQuery();
  const history = useHistory();
  const orderGroups = useDestockingOrderGroups();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    hasProductsThatNeedOrder: query.get('order') === 'true',
    hasProductsThatNeedAdjustment: query.get('adjustment') === 'true',
    hasUnorderedProducts: query.get('unordered') === 'true',
    groupName: query.get('group-name') || '',
    operatorName: query.get('operator-name') || '',
  });

  const filterOrderGroupsHandler = (orderGroup: OrderGroup) => (searchParams.hasProductsThatNeedOrder === false || orderGroup.hasProductsThatNeedOrder) &&
    (searchParams.hasProductsThatNeedAdjustment === false || orderGroup.numberOfProductsThatNeedAdjustment > 0) &&
    (searchParams.hasUnorderedProducts === false || orderGroup.hasUnorderedProducts) &&
    (searchParams.groupName === '' || orderGroup.name == null || orderGroup.name.includes(searchParams.groupName)) &&
    (searchParams.operatorName === '' || orderGroup.operatorName.includes(searchParams.operatorName));

  return (
    <div>
      <PageHeader
        pageTitle='発注グループ一覧'
        backLink={false}
      />
      <SearchFormContainer>
        <SearchOrderGroupsForm
          searchParams={searchParams}
          searchableByOperatorName={user.role === UserRole.Manager}
          onHasProductsThatNeedOrderChangeHandler={(checked: boolean) => {
            if (checked !== searchParams.hasProductsThatNeedOrder) {
              searchParams.hasProductsThatNeedOrder = checked;
            }
          }}
          onHasProductsThatNeedAdjustmentChangeHandler={(checked: boolean) => {
            if (checked !== searchParams.hasProductsThatNeedAdjustment) {
              searchParams.hasProductsThatNeedAdjustment = checked;
            }
          }}
          onHasUnorderedProductsChangeHandler={(checked: boolean) => {
            if (checked !== searchParams.hasUnorderedProducts) {
              searchParams.hasUnorderedProducts = checked;
            }
          }}
          onGroupNameChangeHandler={(text: string) => {
            if (text !== searchParams.groupName) {
              searchParams.groupName = text.trim();
            }
          }}
          onOperatorNameChangeHandler={(text: string) => {
            if (text !== searchParams.operatorName) {
              searchParams.operatorName = text.trim();
            }
          }}
          onSearchButtonClickHandler={() => {
            setSearchParams(searchParams);
            history.push({
              pathname: '/order-groups',
              search: buildQueryString(searchParams),
            });
          }}
        />
      </SearchFormContainer>
      {orderGroups != null?
        orderGroups.filter(filterOrderGroupsHandler).length > 0? (
          <OrderGroupsTableContainer>
            <NumberOfOrderGroups>{orderGroups.filter(filterOrderGroupsHandler).length}件</NumberOfOrderGroups>
            <OrderGroupsTable
              orderGroups={orderGroups.filter(filterOrderGroupsHandler)}
              isOperatorNameVisible={user.role === UserRole.Manager}
            />
          </OrderGroupsTableContainer>
        ) : (
          <NoHitLabelContainer>検索結果がありません。</NoHitLabelContainer>
        )
        : <LoadingSpinnerContainer><LoadingSpinner /></LoadingSpinnerContainer>}
    </div>
  );
};
