import styled from 'styled-components';

const ErrorMessagePanelContainer = styled.div`
  height: calc(100% - 60px);
  padding-bottom: 30px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 30px;
`;

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const NotificationErrorContainer = styled.div`
  margin-bottom: 15px;
`;

const SKUFormContainer = styled.div`
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 35px;
`;

export {
  ErrorMessagePanelContainer,
  LoadingSpinnerContainer,
  NotificationErrorContainer,
  SKUFormContainer,
};
