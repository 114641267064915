import React from 'react';
import {
  TextBox,
} from '../../../atoms/TextBox/TextBox';

import { Tooltip } from '../../../atoms';

import {
  ProductWithDetail,
} from '../../../../api-client';

import { useSKU }from '../../../../hooks/useSKU';

import './DemandAggregation.css';

interface DemandAggregationProps {
  disabled?: boolean,
  startDate: Date | null,
  endDate: Date | null,
  orderGroupId: number,
  product: ProductWithDetail,
  cbAfterPut: () => Promise<void>
}

export const DemandAggregation: React.FC<DemandAggregationProps> = ({
  disabled=false,
  startDate,
  endDate,
  orderGroupId,
  product,
  cbAfterPut
}) => {
  const {
    start,
    end,
    startDateMax,
    endDateMin,
    errorMessage,
    changeStartDate,
    changeEndDate,
    putSKU,
    startDateMin,
  } = useSKU(startDate, endDate);

  return (
    <div className="demand-aggregation-wrapper">
      <span className="demand-aggregation-label">需要集約期間：</span>
      <TextBox
        data-testid='demand-aggregation-start-date'
        type='date'
        height={30}
        width={95}
        padding={5}
        fontSize={12}
        fontFamily='"Noto Sans JP", sans-serif'
        step={7}
        defaultValue={start?.toISOString().split('T')[0] ?? undefined}
        min={startDateMin()}
        max={startDateMax}
        highlightOnChange={false}
        disabled={disabled}
        onBlurHandler={async () => {
          await putSKU(product, orderGroupId);
          await cbAfterPut();
        }}
        onChangeHandler={(startDate) => {
          changeStartDate(startDate);
        }}
      />
      { errorMessage.start !== null ?
        <Tooltip
          id='demand-aggregation-start-error-tooltip'
          text='日付を選択して下さい'
          top={33}
          left={122}
          width={145}
        />
        : null
      }
      <span className="demand-aggregation-between">
      〜
      </span>
      <TextBox
        data-testid='demand-aggregation-end-date'
        type='date'
        height={30}
        width={95}
        padding={5}
        fontSize={12}
        fontFamily='"Noto Sans JP", sans-serif'
        step={7}
        defaultValue={end?.toISOString().split('T')[0] ?? undefined}
        min={endDateMin}
        highlightOnChange={false}
        disabled={disabled}
        onBlurHandler={async () => {
          await putSKU(product, orderGroupId);
          await cbAfterPut();
        }}
        onChangeHandler={(endDate) => {
          changeEndDate(endDate);
        }}
      />
      { errorMessage.end !== null ?
        <Tooltip
          id='demand-aggregation-end-error-tooltip'
          text='日付を選択して下さい'
          top={33}
          left={238}
          width={145}
        />
        : null
      }
    </div>
  );
};
