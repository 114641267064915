import styled from 'styled-components';

import { ReactComponent as BaseCancelIcon } from '../../../assets/images/cancel.svg';

const CancelIcon = styled(BaseCancelIcon)`
  cursor: pointer;
  height: 18px;
  width: 18px;
`;

const CancelIconContainer = styled.div`
  margin-left: auto;
  margin-right: 24px;
`;

const Container = styled.div<{ visible: string }>`
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 4px #80808080;
  display: flex;
  height: 70px;
  opacity: ${props => props.visible === 'true'? 1 : 0};
  padding-left: 28px;
  transition: all 0.3s ease-out;
  visibility: ${props => props.visible === 'true'? 'visible' : 'hidden'};
  width: 375px;
`;

export {
  CancelIcon,
  CancelIconContainer,
  Container,
};
