import React, {
  useState,
} from 'react';

import {
  Button,
  TextBox
} from '../../atoms';

import {
  EmailInputContainer
} from './style';

export interface PasswordResetMailFormProps {
  /**
   * メール送信ボタンがクリックされた時に呼び出されるハンドラー　
   */
  onSendMailClick: (email: string) => void;
}

export const PasswordResetMailForm: React.FC<PasswordResetMailFormProps> = ({
  onSendMailClick
}) => {

  // Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
  const emailValidationRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$", "");

  const [email, setEmail] = useState('');

  const [forceValidate, setForceValidate] = useState(false);

  return (
    <div>
      <EmailInputContainer>
        <TextBox
          id='PasswordResetMailFormTextBoxEmail'
          label='メールアドレス'
          width={427}
          height={40}
          type='email'
          required={true}
          defaultValue={email}
          forceValidate={forceValidate}
          onChangeHandler={(value: string) => setEmail(value)}
        />
      </EmailInputContainer>
      <Button
        styleType='primary'
        label='パスワード設定用メールの送信'
        width={427}
        onClickHandler={() => {
          setForceValidate(true);
          if (email === '' || emailValidationRegex.test(email) === false) {
            return;
          }
          onSendMailClick(email);
        }}
        data-testid='password-reset-mail-form-submit-button'
      />
    </div>
  );
};
