import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
`;

const MainContents = styled.div`
  height: calc(100% - var(--global-header-height));
  padding-left: var(--global-sidebar-width);
  padding-top: var(--global-header-height);
`;

export {
  Container,
  MainContents
};
