/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MdWeekDate,
    MdWeekDateFromJSON,
    MdWeekDateFromJSONTyped,
    MdWeekDateToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMdWeekResponse
 */
export interface GetMdWeekResponse {
    /**
     * 
     * @type {MdWeekDate}
     * @memberof GetMdWeekResponse
     */
    mdWeekDate: MdWeekDate;
}

export function GetMdWeekResponseFromJSON(json: any): GetMdWeekResponse {
    return GetMdWeekResponseFromJSONTyped(json, false);
}

export function GetMdWeekResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMdWeekResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mdWeekDate': MdWeekDateFromJSON(json['mdWeekDate']),
    };
}

export function GetMdWeekResponseToJSON(value?: GetMdWeekResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mdWeekDate': MdWeekDateToJSON(value.mdWeekDate),
    };
}


