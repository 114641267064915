/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MdWeekDate,
    MdWeekDateFromJSON,
    MdWeekDateFromJSONTyped,
    MdWeekDateToJSON,
    ProductWithDetail,
    ProductWithDetailFromJSON,
    ProductWithDetailFromJSONTyped,
    ProductWithDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderDetail
 */
export interface OrderDetail {
    /**
     * 
     * @type {Array<ProductWithDetail>}
     * @memberof OrderDetail
     */
    products: Array<ProductWithDetail>;
    /**
     * 
     * @type {Array<MdWeekDate>}
     * @memberof OrderDetail
     */
    pastMdWeekDates: Array<MdWeekDate>;
    /**
     * 
     * @type {Array<MdWeekDate>}
     * @memberof OrderDetail
     */
    presentAndFutureMdWeekDates: Array<MdWeekDate>;
}

export function OrderDetailFromJSON(json: any): OrderDetail {
    return OrderDetailFromJSONTyped(json, false);
}

export function OrderDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'products': ((json['products'] as Array<any>).map(ProductWithDetailFromJSON)),
        'pastMdWeekDates': ((json['pastMdWeekDates'] as Array<any>).map(MdWeekDateFromJSON)),
        'presentAndFutureMdWeekDates': ((json['presentAndFutureMdWeekDates'] as Array<any>).map(MdWeekDateFromJSON)),
    };
}

export function OrderDetailToJSON(value?: OrderDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'products': ((value.products as Array<any>).map(ProductWithDetailToJSON)),
        'pastMdWeekDates': ((value.pastMdWeekDates as Array<any>).map(MdWeekDateToJSON)),
        'presentAndFutureMdWeekDates': ((value.presentAndFutureMdWeekDates as Array<any>).map(MdWeekDateToJSON)),
    };
}


