import React from 'react';

import './StandardOfInventoryTooltip.css';

const baseTooltipStyle = (bottom: number | undefined, left: number | undefined, right: number | undefined, top: number | undefined, visible: boolean) => {
  return {
    bottom: bottom ? `${bottom}px` : 'auto',
    display: visible ? 'block' : 'none',
    left: left ? `${left}px` : 'auto',
    right: right ? `${right}px` : 'auto',
    top: top ? `${top}px` : 'auto',
  };
};

export interface StandardOfInventoryTooltipProps {
  /**
   * テスト用の id に使用する
   */
  id?: string

  /**
   * 週数
   */
  numberOfWeek: number

  /**
   * 安全在庫数
   */
  numberOfSafeDCInventory: number | null

  /**
   * 安全在庫週数
   */
  numberOfSafeInventoryWeeks: number | null

  /**
   * 最大在庫数
   */
  numberOfMaxDCInventory: number | null

  /**
   * 最大在庫週数
   */
  numberOfMaxInventoryWeeks: number | null

  /**
   * 不足数
   */
  deficientQuantity: number | null

  /**
   * 超過数
   */
  excessQuantity: number | null

  /**
   * 表示位置の下からの距離
   */
  bottom?: number

  /**
   * 表示位置の左からの距離
   */
  left?: number

  /**
   * 表示位置の右からの距離
   */
  right?: number

  /**
   * 表示位置の上からの距離
   */
  top?: number

  /**
   * 表示有無
   */
  visible: boolean
}

export const StandardOfInventoryTooltip: React.FC<StandardOfInventoryTooltipProps> = ({
  id='standard-of-inventory-tooltip',
  numberOfWeek,
  numberOfSafeDCInventory,
  numberOfSafeInventoryWeeks,
  numberOfMaxDCInventory,
  numberOfMaxInventoryWeeks,
  deficientQuantity,
  excessQuantity,
  bottom,
  left,
  right,
  top,
  visible
}) => {

  return (
    <div
      data-testid={id}
      className="base-tooltip"
      style={baseTooltipStyle(bottom, left, right, top, visible)}
    >
      <div className="week">{numberOfWeek}週</div>
      {deficientQuantity != null? (
        <div className="row">
          <span className="label">不足数</span>
          <div className="dots" />
          <span className="value">{deficientQuantity.toLocaleString()}</span>
        </div>
      ) : null}
      {excessQuantity != null? (
        <div className="row">
          <span className="label">超過数</span>
          <div className="dots" />
          <span className="value">{excessQuantity.toLocaleString()}</span>
        </div>
      ) : null}
      {numberOfSafeDCInventory != null? (
        <div className="row">
          <span className="label">安全在庫数</span>
          <div className="dots" />
          <span className="value">{numberOfSafeDCInventory.toLocaleString()}</span>
        </div>
      ) : null}
      {numberOfSafeInventoryWeeks != null? (
        <div className="row">
          <span className="label">安全在庫週数</span>
          <div className="dots" />
          <span className="value">{numberOfSafeInventoryWeeks.toLocaleString()}</span>
        </div>
      ) : null}
      {numberOfMaxDCInventory != null? (
        <div className="row">
          <span className="label">最大在庫数</span>
          <div className="dots" />
          <span className="value">{numberOfMaxDCInventory.toLocaleString()}</span>
        </div>
      ) : null}
      {numberOfMaxInventoryWeeks != null? (
        <div className="row">
          <span className="label">最大在庫週数</span>
          <div className="dots" />
          <span className="value">{numberOfMaxInventoryWeeks.toLocaleString()}</span>
        </div>
      ) : null}
    </div>
  );
};
