import styled from 'styled-components';

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: flex;
  height: 125px;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 560px;
`;

const LabelsContainer = styled.div`
  align-items: flex-end;
  display: flex;
  height: 70px;
  justify-content: flex-end;
`;

const LabelNumberOfProducts = styled.span`
  font-size: 48px;
  height: 58px;
  letter-spacing: 1.68px;
  margin-right: 16px;
`;

const Panel = styled.div`
  padding-top: 15px;

  &:first-child {
    border-right: 1px solid #ccc;
    padding-right: 24px;
  }
`;

const PanelTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-left: 30px;
  margin-right: 95px;
`;

const PanelTitlesContainer = styled.div`
  display: flex;
`;

export {
  Container,
  LabelsContainer,
  LabelNumberOfProducts,
  Panel,
  PanelTitle,
  PanelTitlesContainer,
};
