import styled from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const CancelButtonContainer = styled.div`
  margin-left: 16px;
`;

const InputFormContainer = styled.div`
  background-color: #fff;
  border: 1px solid var(--di-table-border-color);
  display: flex;
  position: relative;
  width: 100%;

  & + div {
    border-top: none;
  }
`;

const InputFormContainerInner = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const InputFormLabel = styled.span`
  font-weight: bold;
  margin-left: 68px;
  margin-right: 26px;
  margin-top: 20px;
  text-align: right;
  width: 120px;
`;

const InputFromLabelRequiredIcon = styled.span`
  color: var(--di-text-error-color);
  font-weight: normal;
  margin-left: 6px;
`;

const InputFormSubLabel = styled.span<{left : number}>`
  color: #999;
  left: ${props => `${props.left}px`};
  margin-left: 20px;
  position: absolute;
  top: 20px;
`;

export {
  ButtonsContainer,
  CancelButtonContainer,
  InputFormContainer,
  InputFormContainerInner,
  InputFormLabel,
  InputFromLabelRequiredIcon,
  InputFormSubLabel,
};
