import {
  useState,
} from 'react';
import * as Sentry from "@sentry/react";
import firebase from 'firebase/app';

import {
  Configuration,
  DatabaseOrderGroup,
  DefaultApi,
  DeleteSKUsOperationRequest,
  DeleteSKUsRequest,
  GetSKUsRequest,
  PutSKUsOperationRequest,
  PutSKUsRequest,
  PutSKUsRequestSkusShortageToleranceRankEnum,
  SKU,
  SKUShortageToleranceRankEnum,
} from '../api-client';

const convShortageToleranceRankType = (rank: SKUShortageToleranceRankEnum): PutSKUsRequestSkusShortageToleranceRankEnum => {
  switch (rank) {
    case SKUShortageToleranceRankEnum.S:
      return PutSKUsRequestSkusShortageToleranceRankEnum.S;
    case SKUShortageToleranceRankEnum.A:
      return PutSKUsRequestSkusShortageToleranceRankEnum.A;
    case SKUShortageToleranceRankEnum.B:
      return PutSKUsRequestSkusShortageToleranceRankEnum.B;
    case SKUShortageToleranceRankEnum.C:
      return PutSKUsRequestSkusShortageToleranceRankEnum.C;
  }
};

function getConf(token: string) {
  const conf = new Configuration({
    basePath: process.env.REACT_APP_API_PATH,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return conf;
}

function getApi(token: string) {
  const conf = getConf(token);
  const api = new DefaultApi(conf);
  return api;
}

export const updateSKUs = async (skus: Array<SKU>, token: string) => {
  if (!token) return;
  const conf = getConf(token);
  const api = new DefaultApi(conf);
  const bodyParam: PutSKUsRequest = {
    skus: skus.map((sku) => ({
      code: sku.code,
      orderGroupID: sku.orderGroupID,
      minNumberOfProductsPerDelivery: sku.minNumberOfProductsPerDelivery,
      price: sku.price,
      shortageToleranceRank: convShortageToleranceRankType(sku.shortageToleranceRank),
      endOfSalesAt: sku.endOfSalesAt,
      demandAggregation: {
        startDate: sku.demandAggregation.startDate,
        endDate: sku.demandAggregation.endDate
      },
    }))
  };
  const params: PutSKUsOperationRequest = {
    bodyParam,
  };
  return api.putSKUs(params);
};

export const useSKUs = () => {
  const [skus, setSKUs] = useState<Array<SKU> | undefined>([]);
  const [orderGroups, setOrderGroups] = useState<Array<DatabaseOrderGroup> | undefined>([]);

  const getSKUs = (
    orderGroupName: string | null,
    code: string | null,
    name: string | null,
    specName: string | null,
    hasEndOfSalesAt: boolean,
  ) => {
    setSKUs(undefined);
    setOrderGroups(undefined);
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = getConf(token);
        const api = new DefaultApi(conf);
        const params: GetSKUsRequest = {
          hasEndOfSalesAt,
        };
        if (orderGroupName != null) {
          params.orderGroupName = orderGroupName;
        }
        if (code != null) {
          params.code = code;
        }
        if (name != null) {
          params.name = name;
        }
        if (specName != null) {
          params.specName = specName;
        }
        return api.getSKUs(params);
      })
      .then((response) => {
        setSKUs(response.skus);
        setOrderGroups(response.orderGroups);
      }).catch((err) => {
        Sentry.captureException(err);
      });
  };

  const putSKUs = async (
    skus: Array<SKU>,
    orderGroupName: string | null,
    code: string | null,
    name: string | null,
    specName: string | null,
    hasEndOfSalesAt: boolean,
  ) => {
    try {
      const token =  await firebase.auth().currentUser?.getIdToken(true);
      if (!token) return;
      await updateSKUs(skus, token);

      setSKUs(undefined);
      setOrderGroups(undefined);

      const params: GetSKUsRequest = {
        hasEndOfSalesAt,
      };
      if (orderGroupName != null) {
        params.orderGroupName = orderGroupName;
      }
      if (code != null) {
        params.code = code;
      }
      if (name != null) {
        params.name = name;
      }
      if (specName != null) {
        params.specName = specName;
      }
      const api = getApi(token);
      const response = await api.getSKUs(params);
      setSKUs(response.skus);
      setOrderGroups(response.orderGroups);
    } catch(err) {
      Sentry.captureException(err);
    }
  };

  const deleteSKUs = (
    skus: Array<SKU>,
    orderGroupName: string | null,
    code: string | null,
    name: string | null,
    specName: string | null,
    hasEndOfSalesAt: boolean,
  ) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = getConf(token);
        const api = new DefaultApi(conf);
        const bodyParam: DeleteSKUsRequest = {
          codes: skus.map((sku) => sku.code)
        };
        const params: DeleteSKUsOperationRequest = {
          bodyParam,
        };
        return api.deleteSKUs(params)
          .then(() => {
            setSKUs(undefined);
            setOrderGroups(undefined);

            const params: GetSKUsRequest = {
              hasEndOfSalesAt,
            };
            if (orderGroupName != null) {
              params.orderGroupName = orderGroupName;
            }
            if (code != null) {
              params.code = code;
            }
            if (name != null) {
              params.name = name;
            }
            if (specName != null) {
              params.specName = specName;
            }
            return api.getSKUs(params);
          })
          .then((response) => {
            setSKUs(response.skus);
            setOrderGroups(response.orderGroups);
          })
          .catch((err) => {
            Sentry.captureException(err);
          });
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  return {
    skus,
    orderGroups,
    getSKUs,
    putSKUs,
    deleteSKUs,
  };
};
