import React, {
  useEffect,
  useState,
} from 'react';

import {
  CaretIcon,
  Container,
  ErrorMessage,
  OptionContainer,
  OptionLabel,
  OptionsContainer,
  SelectedOptionContainer,
  SelectedOptionLabel,
} from './style';

export type DropdownOption<T> = {
  label: string
  value: T
};

export interface DropdownProps<T> {

  /**
   * 選択肢
   */
  options: DropdownOption<T>[]

  /**
   * 初期選択の選択肢
   */
  defaultValue: T

  /**
   * ドロップダウンの幅
   */
  width: number

  /**
   * ドロップダウンの高さ
   */
  height?: number

  /**
   * 無効化状態かどうか
   */
  disabled?: boolean

  /**
   * 値が変更された時に背景色を変えるかどうか
   */
  highlightOnChange?: boolean

  /**
   * エラーメッセージ
   */
  customErrorMessage?: string

  /**
   * 値が変更された時に呼び出されるハンドラー
   */
  onChangeHandler: (value: T) => void
}

export const Dropdown = <T extends {}>({
  options,
  defaultValue,
  width,
  height = 40,
  disabled = false,
  highlightOnChange = false,
  customErrorMessage = '',
  onChangeHandler,
}: DropdownProps<T>) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const closeUserMenu = () => setIsExpanded(false);

  useEffect(() => {
    document.addEventListener('click', closeUserMenu);
    return function cleanup() {
      document.removeEventListener('click', closeUserMenu);
    };
  }, []);

  useEffect(() => {
    if (defaultValue !== selectedValue) {
      onChangeHandler(selectedValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, defaultValue]);

  return (
    <Container>
      <SelectedOptionContainer
        width={width}
        height={height}
        disabled={disabled}
        hasError={customErrorMessage !== ''}
        highlight={highlightOnChange && selectedValue !== defaultValue}
        onClick={(event) => {
          if (disabled === true) {
            // jsdom may not have pointer event feature.
            // Ref: https://github.com/jsdom/jsdom/issues/2527
            return;
          }
          event.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
        data-testid='dropdown-selected-option'
      >
        <SelectedOptionLabel
          width={width - 42}
          data-testid='dropdown-selected-option-label'
        >{options.find((option) => option.value === selectedValue)?.label || ''}</SelectedOptionLabel>
        <CaretIcon expanded={isExpanded.toString()} />
      </SelectedOptionContainer>
      {customErrorMessage !== ''? (
        <ErrorMessage>{customErrorMessage}</ErrorMessage>
      ) : null}
      {isExpanded === true? (
        <OptionsContainer
          width={width}
          height={height}
        >
          {options.filter((option) => option.value !== selectedValue).map((option, idx) => (
            <OptionContainer
              key={idx}
              width={width}
              height={height}
              onClick={() => {
                setIsExpanded(false);
                setSelectedValue(option.value);
              }}
              data-testid='dropdown-option'
            >
              <OptionLabel>{option.label}</OptionLabel>
            </OptionContainer>
          ))}
        </OptionsContainer>
      ) : null}
    </Container>
  );
};
