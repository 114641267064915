import styled from 'styled-components';

const PasswordInputContainer = styled.div`
  margin-bottom: 18px;
`;

const PasswordConfirmationInputContainer = styled.div`
  margin-bottom: 32px;
`;

export {
  PasswordInputContainer,
  PasswordConfirmationInputContainer,
};
