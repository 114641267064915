import styled from 'styled-components';

const LoadingSpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-left: 48px;
    margin-right: 48px;
    margin-top: 45px;
`;

const NoHitLabelContainer = styled.div`
  margin-left: 48px;
  margin-top: 50px;
`;

const NumberOfOrderGroups = styled.p`
  margin-bottom: 7px;
`;

const OrderGroupsTableContainer = styled.div`
  margin-bottom: 30px;
  margin-left: 48px;
  margin-right: 48px;
`;

const SearchFormContainer = styled.div`
  background-color: #f1f1f1;
  height: 115px;
  margin-bottom: 24px;
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 45px;
  padding-left: 22px;
  padding-top: 19px;
`;


export {
  LoadingSpinnerContainer,
  NoHitLabelContainer,
  NumberOfOrderGroups,
  OrderGroupsTableContainer,
  SearchFormContainer,
};
