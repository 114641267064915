import styled from 'styled-components';

import { ReactComponent as BaseTriangleIcon } from '../../../assets/images/triangle.svg';

const OptionContainer = styled.div`
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 9px;
  height: 20px;
  justify-content: flex-end;
  padding-right: 7px;
  text-align: right;
  width: 70px;

  &:hover {
    background-color: #fafafa;
  }
`;

const OptionsContainer = styled.div<{visible: boolean}>`
  box-shadow: 0 3px 6px #00000029;
  display: ${props => props.visible? 'block' : 'none'};
  position: absolute;
  width: 75px;
  z-index: 2;
`;

const SelectedOptionContainer = styled.div<{disabled: boolean, isbackgroundyellow: boolean}>`
  align-items: center;
  background-color: ${props => props.disabled === true? '#fbfafa' : props.isbackgroundyellow === true? '#fffbd9' : '#fff'};
  border-color: ${props => props.disabled === true? '#ccc' : '#8e8e8e'};
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  font-size: 9px;
  height: 20px;
  justify-content: flex-end;
  padding-right: 4px;
  pointer-events: ${props => props.disabled === true? 'none' : 'auto'};
  text-align: right;
  width: 70px;

  &:hover {
    border: 1px solid var(--di-primary-color);
  }
`;

const TriangleIcon = styled(BaseTriangleIcon)<{isexpanded: string}>`
  margin-left: 2px;
  transform: ${props => props.isexpanded === 'true'? 'none' : 'scaleY(-1)'};

  & path {
    fill: #707070;
  }
`;
export {
  OptionContainer,
  OptionsContainer,
  SelectedOptionContainer,
  TriangleIcon,
};
