import { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import firebase from 'firebase/app';

import {
  DefaultApi,
  Configuration,
  User
} from '../api-client';

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        return api.getUser();
      })
      .then(setCurrentUser)
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, []);

  return {
    currentUser,
    setCurrentUser,
  };
};
