import React, {
  useEffect,
  useState,
} from 'react';

import {
  BaseCheckBox,
  Container,
  Label,
} from './style';

export interface CheckBoxProps {
  /**
   * DOMの id とテスト用の id に使用する.
   * id がない場合はラベルがつかない.
   */
  id?: string

  /**
   * ラベル
   */
  label?: string

  /**
   * 初期値
   */
  defaultValue?: boolean

  /**
   * 無効化するかどうか
   */
  disabled?: boolean

  /**
   * 値が変更された時に呼び出されるハンドラー
   */
  onChangeHandler: (checked: boolean) => void

  /**
   * チェックボックスのサイズ
   */
  size?: number

  /**
   * チェックボックスの色を変更するかどうか
   */
  setUncheckedMarkColor?: boolean
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  id = '',
  label = '',
  defaultValue = false,
  disabled = false,
  onChangeHandler,
  size = 16,
  setUncheckedMarkColor = false,
}) => {

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Container>
      <BaseCheckBox
        id={id}
        type='checkbox'
        checked={value}
        value={value.toString()}
        disabled={disabled}
        onChange={(event) => {
          if (disabled === true) {
            // jsdom may not have pointer event feature.
            // Ref: https://github.com/jsdom/jsdom/issues/2527
            return;
          }
          setValue(event.target.checked);
          onChangeHandler(event.target.checked);
        }}
        data-testid={id}
        size={size}
        setUncheckedMarkColor={setUncheckedMarkColor}
      />
      {id !== '' && label !== '' ? (
        <Label htmlFor={id}>{label}</Label>
      ) : null}
    </Container>
  );
};
