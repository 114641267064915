import React from 'react';

import {
  Button,
  CheckBox,
  TextBox,
} from '../../atoms';

import {
  CheckBoxContainer,
  CheckBoxesContainer,
  Container,
  TextBoxContainer,
} from './style';

// TODO: バックエンドで検索するようになったらAPI定義の型を使うようにするようにする.
export type SearchParams = {
  hasProductsThatNeedOrder: boolean
  hasProductsThatNeedAdjustment: boolean
  hasUnorderedProducts: boolean
  groupName: string
  operatorName: string
};

export interface SearchOrderGroupsFormProps {
  /**
   * 検索パラメーター
   */
  searchParams: SearchParams

  /**
   * 担当者名の検索フォームを表示するかどうか
   */
  searchableByOperatorName: boolean

  /**
   * 発注アラートのチェックボックスの値が変更された時に呼び出されるハンドラー
   */
  onHasProductsThatNeedOrderChangeHandler: (checked: boolean) => void

  /**
   * 調整アラートのチェックボックスの値が変更された時に呼び出されるハンドラー
   */
  onHasProductsThatNeedAdjustmentChangeHandler: (checked: boolean) => void

  /**
   * 未発注のチェックボックスの値が変更された時に呼び出されるハンドラー
   */
  onHasUnorderedProductsChangeHandler: (checked: boolean) => void

  /**
   * 発注グループ名の検索文字列が変更された時に呼び出されるハンドラー
   */
  onGroupNameChangeHandler: (searchText: string) => void

  /**
   * 担当者名の検索文字列が変更された時に呼び出されるハンドラー
   */
  onOperatorNameChangeHandler: (searchText: string) => void

  /**
   * 検索ボタンが押された時に呼び出されるハンドラー
   */
  onSearchButtonClickHandler: () => void
}

export const SearchOrderGroupsForm: React.FC<SearchOrderGroupsFormProps> = ({
  searchParams,
  searchableByOperatorName,
  onHasProductsThatNeedOrderChangeHandler,
  onHasProductsThatNeedAdjustmentChangeHandler,
  onHasUnorderedProductsChangeHandler,
  onGroupNameChangeHandler,
  onOperatorNameChangeHandler,
  onSearchButtonClickHandler,
}) => {
  return (
    <div>
      <CheckBoxesContainer>
        <CheckBoxContainer>
          <CheckBox
            id='order-alert-checkbox'
            label='発注アラート'
            defaultValue={searchParams.hasProductsThatNeedOrder}
            onChangeHandler={onHasProductsThatNeedOrderChangeHandler}
          />
        </CheckBoxContainer>
        <CheckBoxContainer>
          <CheckBox
            id='adjustment-alert-checkbox'
            label='調整アラート'
            defaultValue={searchParams.hasProductsThatNeedAdjustment}
            onChangeHandler={onHasProductsThatNeedAdjustmentChangeHandler}
          />
        </CheckBoxContainer>
        <CheckBoxContainer>
          <CheckBox
            id='unordered-checkbox'
            label='未発注'
            defaultValue={searchParams.hasUnorderedProducts}
            onChangeHandler={onHasUnorderedProductsChangeHandler}
          />
        </CheckBoxContainer>
      </CheckBoxesContainer>
      <Container>
        <TextBoxContainer>
          <TextBox
            id='order-group-name-form'
            label='発注グループ'
            type='text'
            defaultValue={searchParams.groupName}
            height={40}
            width={200}
            onChangeHandler={onGroupNameChangeHandler}
          />
        </TextBoxContainer>
        {searchableByOperatorName === true? (
          <TextBoxContainer>
            <TextBox
              id='operator-name-form'
              label='担当者'
              type='text'
              defaultValue={searchParams.operatorName}
              height={40}
              width={200}
              onChangeHandler={onOperatorNameChangeHandler}
            />
          </TextBoxContainer>
        ) : null}
        <Button
          styleType='primary'
          label='検索'
          width={76}
          onClickHandler={onSearchButtonClickHandler}
        />
      </Container>
    </div>
  );
};
