import { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import firebase from 'firebase/app';

import {
  FirebaseAuthErrorCode,
} from '../constants';

export const useVerifyPasswordResetCode = (oobCode: string, mode: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPasswordResetFinished, setIsPasswordResetFinished] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (mode !== 'resetPassword') {
      setErrorMessage("パスワード再設定URLのリンクが無効です。\n再度パスワード設定用メールを送信して下さい。");
      setIsLoading(false);
      return;
    }
    firebase.auth().verifyPasswordResetCode(oobCode)
      .then(() => {
        setErrorMessage('');
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.code === FirebaseAuthErrorCode.INVALID_OOB_CODE) {
          // TODO: DOR v1 からコピーしただけで v2 では未定義なので, 文言の仕様を定義してもらう
          setErrorMessage("パスワード再設定URLのリンクが無効です。\n再度パスワード設定用メールを送信して下さい。");
        } else if (err.code === FirebaseAuthErrorCode.EXPIRED_OOB_CODE) {
          // TODO: DOR v1 からコピーしただけで v2 では未定義なので, 文言の仕様を定義してもらう
          setErrorMessage("パスワード設定URLの有効期限が切れました。\n再度パスワード設定用メールの送信をお願いします。");
        } else if (err.code === FirebaseAuthErrorCode.USER_DISABLED) {
          // TODO: DOR v1 からコピーしただけで v2 では未定義なので, 文言の仕様を定義してもらう
          setErrorMessage("ユーザーが無効です。");
        } else if (err.code === FirebaseAuthErrorCode.USER_DELETED) {
          // TODO: DOR v1 からコピーしただけで v2 では未定義なので, 文言の仕様を定義してもらう
          setErrorMessage("ユーザーが見つかりませんでした。");
        } else {
          Sentry.captureException(err);
          setErrorMessage("パスワード再設定URLのリンクが無効です。\n再度パスワード設定用メールを送信して下さい。");
        }
      });
  }, [oobCode, mode]);

  return {
    isLoading,
    isPasswordResetFinished,
    setIsPasswordResetFinished,
    errorMessage,
    setErrorMessage
  };
};
