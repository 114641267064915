import React from 'react';

import {
  PlusIconContainer,
  PrimaryButton,
  SecondaryButton,
  SecondaryPlusIcon,
  TertiaryButton
} from './style';

type StyleType = 'primary' | 'secondary' | 'tertiary';

export interface ButtonProps {
  /**
   * 見た目の種類
   */
  styleType: StyleType

  /**
   * ラベル
   */
  label: string;

  /**
   * ボタンの幅
   */
  width: number;

  /**
   * 無効にするかどうか
   */
  disabled?: boolean;

  /**
   * 十字アイコンを出すかどうか
   */
  plusIcon?: boolean;

  /**
   * ボタンがクリックされた時に呼び出されるハンドラー
   */
  onClickHandler: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  styleType,
  label,
  width,
  disabled = false,
  plusIcon = false,
  onClickHandler,
  ...props
}) => {

  if (styleType === 'secondary') {
    return (
      <SecondaryButton
        {...props}
        width={width}
        disabled={disabled}
        onClick={onClickHandler}
      >
        {plusIcon? (
          <PlusIconContainer>
            <SecondaryPlusIcon
              data-testid='button-secondary-plus-icon'
            />
          </PlusIconContainer>
        ) : null}
        {label}
      </SecondaryButton>
    );
  } else if (styleType === 'tertiary') {
    return (
      <TertiaryButton
        {...props}
        width={width}
        disabled={disabled}
        onClick={onClickHandler}
      >
        {label}
      </TertiaryButton>
    );
  }

  // デフォルトは primary
  return (
    <PrimaryButton
      {...props}
      width={width}
      disabled={disabled}
      onClick={onClickHandler}
    >
      {label}
    </PrimaryButton>
  );
};
