import styled from 'styled-components';

const BaseOrderGroupsListTable = styled.table`
  border: solid 1px var(--di-table-border-color);
  table-layout: fixed;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CountLabel = styled.p`
  margin-bottom: 8px;
`;

const DeleteButtonContainer = styled.div`
  margin-left: 20px;
`;

const OrderGroupsListTableBodyRow = styled.tr`
  border-top: solid 1px var(--di-table-border-color);
  background-color: #fff;

  &:hover {
    background-color: var(--di-table-body-bg-hover-color);
  }
`;

const OrderGroupsListTableData = styled.td`
  height: 70px;
  padding-left: 15px;
`;

const OrderGroupsListTableHeader = styled.th<{width?: number}>`
  height: 47px;
  padding-left: 15px;
  text-align: left;
  width: ${props => props.width != null? `${props.width}px` : 'auto'};
`;

const TextBoxContainer = styled.div`
  position: relative;
`;

export {
  BaseOrderGroupsListTable,
  ButtonsContainer,
  CountLabel,
  DeleteButtonContainer,
  OrderGroupsListTableBodyRow,
  OrderGroupsListTableData,
  OrderGroupsListTableHeader,
  TextBoxContainer,
};
