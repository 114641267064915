/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderDetail,
    OrderDetailFromJSON,
    OrderDetailFromJSONTyped,
    OrderDetailToJSON,
    OrderGroup,
    OrderGroupFromJSON,
    OrderGroupFromJSONTyped,
    OrderGroupToJSON,
    OrderSummary,
    OrderSummaryFromJSON,
    OrderSummaryFromJSONTyped,
    OrderSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetDestockingOrderGroupResponse
 */
export interface GetDestockingOrderGroupResponse {
    /**
     * 
     * @type {OrderGroup}
     * @memberof GetDestockingOrderGroupResponse
     */
    orderGroup: OrderGroup;
    /**
     * 
     * @type {OrderSummary}
     * @memberof GetDestockingOrderGroupResponse
     */
    orderSummary: OrderSummary;
    /**
     * 
     * @type {OrderDetail}
     * @memberof GetDestockingOrderGroupResponse
     */
    orderDetail: OrderDetail;
}

export function GetDestockingOrderGroupResponseFromJSON(json: any): GetDestockingOrderGroupResponse {
    return GetDestockingOrderGroupResponseFromJSONTyped(json, false);
}

export function GetDestockingOrderGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDestockingOrderGroupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderGroup': OrderGroupFromJSON(json['orderGroup']),
        'orderSummary': OrderSummaryFromJSON(json['orderSummary']),
        'orderDetail': OrderDetailFromJSON(json['orderDetail']),
    };
}

export function GetDestockingOrderGroupResponseToJSON(value?: GetDestockingOrderGroupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderGroup': OrderGroupToJSON(value.orderGroup),
        'orderSummary': OrderSummaryToJSON(value.orderSummary),
        'orderDetail': OrderDetailToJSON(value.orderDetail),
    };
}


