/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
    ProductWithOrderAllOf,
    ProductWithOrderAllOfFromJSON,
    ProductWithOrderAllOfFromJSONTyped,
    ProductWithOrderAllOfToJSON,
} from './';

/**
 * 発注数情報込SKU
 * @export
 * @interface ProductWithOrder
 */
export interface ProductWithOrder {
    /**
     * JANコード
     * @type {string}
     * @memberof ProductWithOrder
     */
    code: string;
    /**
     * 商品名
     * @type {string}
     * @memberof ProductWithOrder
     */
    name: string | null;
    /**
     * 規格名
     * @type {string}
     * @memberof ProductWithOrder
     */
    specName: string | null;
    /**
     * 
     * @type {{ [key: string]: Order; }}
     * @memberof ProductWithOrder
     */
    orderPerMdWeek: { [key: string]: Order; };
}

export function ProductWithOrderFromJSON(json: any): ProductWithOrder {
    return ProductWithOrderFromJSONTyped(json, false);
}

export function ProductWithOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductWithOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'specName': json['specName'],
        'orderPerMdWeek': (mapValues(json['orderPerMdWeek'], OrderFromJSON)),
    };
}

export function ProductWithOrderToJSON(value?: ProductWithOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'specName': value.specName,
        'orderPerMdWeek': (mapValues(value.orderPerMdWeek, OrderToJSON)),
    };
}


