import React from 'react';

import {
  BodyMessageError,
  BodyMessageSuccess,
  NotificationFieldContainerError,
  NotificationFieldContainerSuccess,
  TitleError,
} from './style';

type NotificationType = 'success' | 'error';

export interface NotificationFieldProps {
  /**
   * 通知の種別. 種別によって, 主に色が変わる
   */
  type: NotificationType

  /**
   * 通知のタイトル
   */
  title?: string

  /**
   * 通知の本文
   */
  bodyMessage: string
}

export const NotificationField: React.FC<NotificationFieldProps> = ({
  type,
  title = '',
  bodyMessage
}) => {
  if (type === 'success') {
    return (
      <NotificationFieldContainerSuccess>
        <BodyMessageSuccess>{bodyMessage}</BodyMessageSuccess>
      </NotificationFieldContainerSuccess>
    );
  } else if (type === 'error') {
    return (
      <NotificationFieldContainerError>
        <TitleError>{title}</TitleError>
        <BodyMessageError>{bodyMessage}</BodyMessageError>
      </NotificationFieldContainerError>
    );
  } else {
    return <div />;
  }
};
