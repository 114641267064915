import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Link,
} from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import {
  DatabaseOrderGroup,
  SKU,
  SKUShortageToleranceRankEnum,
} from '../../../api-client';

import {
  Button,
  CheckBox,
  Dropdown,
  DropdownOption,
  TextBox,
  Tooltip,
} from '../../atoms';

import {
  BaseSKUsListTable,
  ButtonsContainer,
  CheckBoxContainer,
  Count,
  DeleteButtonContainer,
  M3,
  QuantityPerCase,
  SKUName,
  SKUsListTableBodyRow,
  SKUsListTableHeader,
  SKUSpec,
  SupplierName,
  TextBoxContainer,
} from './style';

import { DemandAggregation } from './DemandAggregation/DemandAggregation';

import './SKUListTable.css';

type LastEditedNumberOfInventoryWeeks = 'number-of-safe-inventory-weeks' | 'number-of-max-inventory-weeks';

const invalidPrice = (price: number | null) => {
  if (price) {
    return price < 1 || price > 999999;
  } else {
    return false;
  }
};

export interface SKUsListTableProps {
  /**
   * 読み取り専用かどうか
   */
  readonly: boolean

  /**
   * SKUの一覧
   */
  skus: Array<SKU>

  /**
   * 選べる発注グループの一覧
   */
  selectableOrderGroups: Array<DatabaseOrderGroup>

  /**
   * 更新ボタンがクリックされた時に呼び出されるハンドラー
   */
  onClickUpdateButtonHandler: (skus: Array<SKU>) => void

  /**
   * 削除ボタンがクリックされた時に呼び出されるハンドラー
   */
  onClickDeleteButtonHandler: (skus: Array<SKU>) => void
}

export const SKUsListTable: React.FC<SKUsListTableProps> = ({
  readonly,
  skus,
  selectableOrderGroups,
  onClickUpdateButtonHandler,
  onClickDeleteButtonHandler,
}) => {

  // 再レンダリングを行いたい場合に使う.
  // 全ての state を更新していると再レンダリングの負荷がかかりすぎるので、必要な時にのみ実行すること.
  const forceUpdate = useState(false)[1];

  const orderGroupsDropdownOptions: Array<DropdownOption<number>> = selectableOrderGroups.map((orderGroup) => ({
    label: orderGroup.name,
    value: orderGroup.id,
  }));

  const shortageToleranceRankDropdownOptions: Array<DropdownOption<SKUShortageToleranceRankEnum>> = [
    {
      label: 'S',
      value: SKUShortageToleranceRankEnum.S,
    },
    {
      label: 'A',
      value: SKUShortageToleranceRankEnum.A,
    },
    {
      label: 'B',
      value: SKUShortageToleranceRankEnum.B,
    },
    {
      label: 'C',
      value: SKUShortageToleranceRankEnum.C,
    },
  ];

  const skusRef = useRef(cloneDeep(skus));
  const lastEditedNumberOfInventoryWeeks = useRef<Array<LastEditedNumberOfInventoryWeeks>>(skusRef.current.map(() => 'number-of-safe-inventory-weeks'));
  const checkedSKUs = useRef<{ [key: string]: boolean }>({});

  useEffect(() => {
    skusRef.current = cloneDeep(skus);
    lastEditedNumberOfInventoryWeeks.current = skusRef.current.map(() => 'number-of-safe-inventory-weeks');
    checkedSKUs.current = {};
  }, [skus]);

  return (
    <div>
      <ButtonsContainer>
        <Button
          styleType='primary'
          label='更新'
          width={76}
          disabled={readonly || JSON.stringify(skus) === JSON.stringify(skusRef.current) || skusRef.current.some((sku) => (
            (sku.minNumberOfProductsPerDelivery != null && (sku.minNumberOfProductsPerDelivery < 1 ||sku.minNumberOfProductsPerDelivery > 999999)) ||
            (sku.price != null && (sku.price < 1 || sku.price > 999999))
          ))}
          onClickHandler={() => {
            onClickUpdateButtonHandler(skusRef.current);
          }}
          data-testid='skus-list-table-update-button'
        />
        <DeleteButtonContainer>
          <Button
            styleType='tertiary'
            label='選択して削除'
            width={134}
            disabled={readonly || skusRef.current.some((sku) => checkedSKUs.current[sku.code] === true) === false}
            onClickHandler={() => {
              onClickDeleteButtonHandler(skusRef.current.filter((sku) => checkedSKUs.current[sku.code] === true));
            }}
            data-testid='skus-list-table-delete-button'
          />
        </DeleteButtonContainer>
      </ButtonsContainer>
      <Count>{skus.length}件</Count>
      <div className='sku-list-table-container'>
        <BaseSKUsListTable>
          <thead>
            <tr>
              <SKUsListTableHeader width={46}>
                <CheckBoxContainer>
                  <CheckBox
                    id='skus-list-table-checkbox-all-skus'
                    defaultValue={skusRef.current.some((sku) => checkedSKUs.current[sku.code] == null || checkedSKUs.current[sku.code] === false) === false}
                    disabled={readonly}
                    onChangeHandler={(value) => {
                      skusRef.current.forEach((sku) => checkedSKUs.current[sku.code] = value);
                      forceUpdate(v => !v);
                    }}
                  />
                </CheckBoxContainer>
              </SKUsListTableHeader>
              <SKUsListTableHeader width={130}>JANコード</SKUsListTableHeader>
              <SKUsListTableHeader width={330}>SKU</SKUsListTableHeader>
              <SKUsListTableHeader width={194}>発注グループ</SKUsListTableHeader>
              <SKUsListTableHeader width={92}>店発単</SKUsListTableHeader>
              <SKUsListTableHeader width={92}>売価</SKUsListTableHeader>
              <SKUsListTableHeader width={62}>店舗数</SKUsListTableHeader>
              <SKUsListTableHeader width={85}>欠品許容<br />ランク</SKUsListTableHeader>
              <SKUsListTableHeader width={145}>終売日</SKUsListTableHeader>
              <SKUsListTableHeader width={300}>需要集約期間</SKUsListTableHeader>
            </tr>
          </thead>
          <tbody>
            {skus.map((sku, idx) => (
              <SKUsListTableBodyRow key={sku.code}>
                <td>
                  <CheckBoxContainer>
                    <CheckBox
                      id={`skus-list-table-checkbox-${sku.code}`}
                      defaultValue={checkedSKUs.current[sku.code] === true}
                      disabled={readonly}
                      onChangeHandler={(value) => {
                        checkedSKUs.current[sku.code] = value;
                        forceUpdate(v => !v);
                      }}
                    />
                  </CheckBoxContainer>
                </td>
                <td>{sku.code}</td>
                <td>
                  <SupplierName>{sku.supplierName != null? sku.supplierName : '-'}</SupplierName>
                  <SKUName>
                    <Link
                      // ループ内の skusRef は更新される可能性があるので skus を参照する.
                      to={`/order-groups/${skus[idx].orderGroupID}/destocking`}
                    >{sku.name != null? sku.name : '-'}</Link>
                  </SKUName>
                  <SKUSpec>
                    <Link
                      // ループ内の skusRef は更新される可能性があるので skus を参照する.
                      to={`/order-groups/${skus[idx].orderGroupID}/destocking`}
                    >{sku.specName != null? `（${sku.specName}）` : '-'}</Link>
                  </SKUSpec>
                  <QuantityPerCase>入数：{sku.quantityPerCase}</QuantityPerCase>
                  <M3>M3：{sku.m3}</M3>
                </td>
                <td>
                  <Dropdown
                    options={orderGroupsDropdownOptions}
                    defaultValue={sku.orderGroupID}
                    width={170}
                    disabled={readonly}
                    highlightOnChange={true}
                    onChangeHandler={(value) => {
                      skusRef.current[idx].orderGroupID = Number(value);
                      forceUpdate(v => !v);
                    }}
                  />
                </td>
                <td>
                  <TextBoxContainer>
                    <TextBox
                      id='skus-list-table-min-number-of-products-per-delivery-input'
                      type='number'
                      height={40}
                      width={70}
                      defaultValue={sku.minNumberOfProductsPerDelivery != null? sku.minNumberOfProductsPerDelivery.toString() : ''}
                      disabled={readonly}
                      min={1}
                      max={999999}
                      forceValidate={true}
                      suppressErrorMessage={true}
                      highlightOnChange={true}
                      onChangeHandler={(value) => {
                        const maybeMinNumberOfProductsPerDelivery = Number.parseInt(value);
                        const minNumberOfProductsPerDelivery = Number.isNaN(maybeMinNumberOfProductsPerDelivery) === true? null : maybeMinNumberOfProductsPerDelivery;
                        if (skusRef.current[idx].minNumberOfProductsPerDelivery !== minNumberOfProductsPerDelivery) {
                          skusRef.current[idx].minNumberOfProductsPerDelivery = minNumberOfProductsPerDelivery;
                          forceUpdate(v => !v);
                        }
                      }}
                    />
                    {skusRef.current[idx].minNumberOfProductsPerDelivery != null && invalidPrice(skusRef.current[idx].minNumberOfProductsPerDelivery)? (
                      <Tooltip
                        id='skus-list-table-min-number-of-products-per-delivery-input-error'
                        text='1〜999,999以内で入力して下さい。'
                        top={43}
                        width={240}
                      />
                    ) : null}
                  </TextBoxContainer>
                </td>
                <td>
                  <TextBoxContainer>
                    <TextBox
                      id='skus-list-table-price-input'
                      type='number'
                      height={40}
                      width={70}
                      defaultValue={sku.price != null? sku.price.toString() : ''}
                      disabled={readonly}
                      min={1}
                      max={999999}
                      forceValidate={true}
                      suppressErrorMessage={true}
                      highlightOnChange={true}
                      onChangeHandler={(value) => {
                        const maybePrice = Number.parseInt(value);
                        const price = Number.isNaN(maybePrice) === true? null : maybePrice;
                        if (skusRef.current[idx].price !== price) {
                          skusRef.current[idx].price = price;
                          forceUpdate(v => !v);
                        }
                      }}
                    />
                    {sku.price != null && invalidPrice(skusRef.current[idx].price)? (
                      <Tooltip
                        id='skus-list-table-price-input-error'
                        text='1〜999,999以内で入力して下さい。'
                        top={43}
                        width={240}
                      />
                    ) : null}
                  </TextBoxContainer>
                </td>
                <td>
                  { sku.numberOfStores.toLocaleString() }
                </td>
                <td>
                  <Dropdown
                    options={shortageToleranceRankDropdownOptions}
                    defaultValue={sku.shortageToleranceRank}
                    width={58}
                    disabled={readonly}
                    highlightOnChange={true}
                    onChangeHandler={(value) => {
                      skusRef.current[idx].shortageToleranceRank = value;
                      forceUpdate(v => !v);
                    }}
                  />
                </td>
                <td>
                  <TextBox
                    id='skus-list-table-end-of-sales-at'
                    type='date'
                    height={40}
                    width={133}
                    padding={8}
                    defaultValue={sku.endOfSalesAt?.toISOString().split('T')[0]}
                    disabled={readonly}
                    min={new Date().toISOString().split('T')[0]}
                    highlightOnChange={true}
                    onChangeHandler={(value) => {
                      const endOfSalesAt = new Date(value);
                      if (skusRef.current[idx].endOfSalesAt != null && value === '') {
                        skusRef.current[idx].endOfSalesAt = null;
                      } else if (sku.endOfSalesAt?.toString() !== endOfSalesAt.toString()){
                        skusRef.current[idx].endOfSalesAt = endOfSalesAt;
                      }
                      forceUpdate(v => !v);
                    }}
                  />
                </td>
                <td className="demand-aggregation-row">
                  <DemandAggregation
                    startDate={skusRef.current[idx].demandAggregation.startDate}
                    endDate={skusRef.current[idx].demandAggregation.endDate}
                    disabled={readonly}
                    onBlurHandler={(startDate, endDate) => {
                      skusRef.current[idx].demandAggregation.startDate = startDate;
                      skusRef.current[idx].demandAggregation.endDate = endDate;
                      forceUpdate(v => !v);
                    }}
                  />
                </td>
              </SKUsListTableBodyRow>
            ))}
          </tbody>
        </BaseSKUsListTable>
      </div>
    </div>
  );
};
