import {
  Configuration,
  DefaultApi,
} from '../api-client';

function getConf(token: string) {
  const conf = new Configuration({
    basePath: process.env.REACT_APP_API_PATH,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return conf;
}

function getApi(token: string) {
  const conf = getConf(token);
  const api = new DefaultApi(conf);
  return api;
}

export {
  getApi,
};
