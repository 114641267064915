import React from 'react';
import {
  Link
} from 'react-router-dom';

import {
  Container,
  ErrorCodeLabel,
  ErrorCodeLabelContainer,
  ErrorTitle,
  ErrorTitleContainer
} from './style';

export interface ErrorMessagePanelProps {
  /**
   * HTTPステータスコード
   */
  statusCode: number
}

export const ErrorMessagePanel: React.FC<ErrorMessagePanelProps> = ({
  statusCode
}) => {

  return (
    <Container>
      <ErrorTitleContainer>
        <ErrorTitle>{
          statusCode === 403? 'アクセス権がありません' :
            statusCode === 404? 'ページが見つかりません' : ''
        }</ErrorTitle>
      </ErrorTitleContainer>
      <ErrorCodeLabelContainer>
        <ErrorCodeLabel>エラーコード：{statusCode}</ErrorCodeLabel>
      </ErrorCodeLabelContainer>
      <div><Link to='/'>ホームへ戻る</Link></div>
    </Container>
  );
};
