import styled from 'styled-components';

const DescriptionContainer = styled.div`
  height: 40px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-right: auto;
  margin-top: 45px;
  width: 430px;
`;

const LinkContainer = styled.div`
  margin-top: 20px;
  text-align: right;
`;

const LogoContainer = styled.div`
  margin: auto;
  width: 339px;
`;

const NotificationFieldContainer = styled.div`
  margin-top: 60px;
`;


const PasswordResetFormContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  min-height: 300px;
  width: 440px;
`;

export {
  DescriptionContainer,
  LinkContainer,
  LogoContainer,
  NotificationFieldContainer,
  PasswordResetFormContainer,
};
