import React, { useState } from 'react';
import {
  Link
} from 'react-router-dom';
import firebase from 'firebase/app';

import {
  FirebaseAuthErrorCode,
} from '../../constants';

import {
  Logo
} from '../../components/atoms';
import {
  PasswordResetMailForm
} from '../../components/molecules';
import {
  BeforeLogin
} from '../../templates';

import {
  DescriptionContainer,
  ErrorMessageContainer,
  LinkContainer,
  LogoContainer,
  MailSentMessageContainer,
  PasswordResetMailFormContainer
} from './style';

export interface PasswordResetMailProps {}

export const PasswordResetMail: React.FC<PasswordResetMailProps> = () => {

  const [mailSentMessageVisible, setMailSentMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <BeforeLogin>
      <LogoContainer>
        <Logo logoColor='black' />
      </LogoContainer>
      <DescriptionContainer>
      登録しているEメールアドレスを入力してください。パスワードを<br />
      リセットするためのリンクを記載したEメールをお送りします。
      </DescriptionContainer>
      <PasswordResetMailFormContainer>
        <PasswordResetMailForm
          onSendMailClick={(email) => {
            firebase.auth().languageCode = 'ja';
            firebase.auth().sendPasswordResetEmail(email, {
              handleCodeInApp: false,
              url: `${window.location.origin.toString()}/login`
            })
              .then(() => {
                setMailSentMessageVisible(true);
                setErrorMessage('');
              })
              .catch((error) => {
                if (error.code === FirebaseAuthErrorCode.USER_DELETED) {
                  // ユーザーが存在するかどうかの情報は表示しない。
                  // ブラウザのコンソールには出るので事実上知ることはできるが、仕様としてそこはカバーしない。
                  setMailSentMessageVisible(true);
                  setErrorMessage('');
                } else if (error.code === FirebaseAuthErrorCode.INVALID_EMAIL) {
                  setErrorMessage('不正な形式のメールアドレスです。');
                  setMailSentMessageVisible(false);
                } else {
                  // TODO: DOR v1 からコピーしただけで v2 では未定義なので, 文言の仕様を定義してもらう
                  setErrorMessage('パスワード設定用メールの送信に失敗しました。');
                  setMailSentMessageVisible(false);
                }
              });
          }}
        />
        <LinkContainer>
          <Link to='/login'>ログイン</Link>
        </LinkContainer>
      </PasswordResetMailFormContainer>
      {mailSentMessageVisible? (
        <MailSentMessageContainer>
          入力されたEメールアドレス宛にパスワードをリセットするため<br />
          のメールをお送りしました。メールボックスをご確認下さい。<br /><br />
          しばらくしてもメールが届かない場合は、入力されたメールアド<br />
          レスが間違っている可能性があります。メールアドレスを確認の
          上、再度送信をお願いします。
        </MailSentMessageContainer>
      ) : null}
      {errorMessage !== ''? (
        <ErrorMessageContainer>
          {errorMessage}
        </ErrorMessageContainer>
      ) : null}
    </BeforeLogin>
  );
};
