import React from 'react';
import {
  Switch,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import firebase from 'firebase/app';

import {
  useCurrentUser
} from '../../hooks';

import {
  Sidebar
} from '../../components/atoms';

import {
  GlobalHeader
} from '../../components/molecules';

import {
  CreateOrderGroup,
  CreateSKU,
  CreateUser,
  Dashboard,
  Destocking,
  EditUser,
  ManageOrderGroup,
  NotFound,
  OrderGroups,
  SKUs,
  Users
} from '../../pages';

import {
  Container,
  MainContents
} from './style';

export interface AfterLoginProps {}

export const AfterLogin: React.FC<AfterLoginProps> = ({...props}) => {

  const history = useHistory();
  const location = useLocation();

  const {
    currentUser,
    setCurrentUser,
  } = useCurrentUser();

  return currentUser != null? (
    <Container id='page-top'>
      <GlobalHeader
        user={currentUser}
        logout={() => {
          firebase
            .auth()
            .signOut()
            .then(() => history.push('/'))
            .catch((err) => Sentry.captureException(err));
        }}
      />
      <Sidebar
        currentPath={location.pathname}
      />
      <MainContents>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/order-groups/:orderGroupId/destocking">
            <Destocking user={currentUser} />
          </Route>
          <Route exact path="/order-groups/new">
            <CreateOrderGroup user={currentUser} />
          </Route>
          <Route exact path="/order-groups">
            <OrderGroups user={currentUser} />
          </Route>
          <Route exact path="/manage-order-group">
            <ManageOrderGroup currentUser={currentUser} />
          </Route>
          <Route exact path="/skus/new">
            <CreateSKU user={currentUser} />
          </Route>
          <Route exact path="/skus">
            <SKUs user={currentUser} />
          </Route>
          <Route exact path="/users/new">
            <CreateUser user={currentUser} />
          </Route>
          <Route exact path="/users/:userId">
            <EditUser
              currentUser={currentUser}
              onEditUserHandler={(u) => {
                if (u.id === currentUser.id) {
                  // 自ユーザーの更新時は state を更新.
                  setCurrentUser(u);
                }
              }}
            />
          </Route>
          <Route exact path="/users">
            <Users user={currentUser} />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </MainContents>
    </Container>
  ) : (
    // TODO: Provide loading UI
    <div />
  );
};
