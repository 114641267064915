import React from 'react';
import {
  Link,
  useHistory
} from 'react-router-dom';

import {
  BasePageHeader,
  CaretLeftIcon,
  LinkContainer,
  LinkContainerInner,
  Title,
} from './style';

export interface PageHeaderProps {
  /**
   * 画面のタイトル
   */
  pageTitle: string;

  /**
   * 戻るリンクを表示するかどうか
   */
  backLink: boolean;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  pageTitle,
  backLink
}) => {
  const history = useHistory();

  return (
    <BasePageHeader>
      {backLink === true? (
        <LinkContainer>
          <Link
            to='#'
            onClick={() => history.goBack()}
          >
            <LinkContainerInner>
              <CaretLeftIcon />
              戻る
            </LinkContainerInner>
          </Link>
        </LinkContainer>
      ) : null}
      <Title>{pageTitle}</Title>
    </BasePageHeader>
  );
};
