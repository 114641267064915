/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutDestockingOrderGroupRequestInventoryPlanPerMdWeek,
    PutDestockingOrderGroupRequestInventoryPlanPerMdWeekFromJSON,
    PutDestockingOrderGroupRequestInventoryPlanPerMdWeekFromJSONTyped,
    PutDestockingOrderGroupRequestInventoryPlanPerMdWeekToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutDestockingOrderGroupRequestProducts
 */
export interface PutDestockingOrderGroupRequestProducts {
    /**
     * 
     * @type {string}
     * @memberof PutDestockingOrderGroupRequestProducts
     */
    code: string;
    /**
     * 欠品許容ランク
     * @type {string}
     * @memberof PutDestockingOrderGroupRequestProducts
     */
    shortageToleranceRank: PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum;
    /**
     * 
     * @type {{ [key: string]: PutDestockingOrderGroupRequestInventoryPlanPerMdWeek; }}
     * @memberof PutDestockingOrderGroupRequestProducts
     */
    inventoryPlanPerMdWeek: { [key: string]: PutDestockingOrderGroupRequestInventoryPlanPerMdWeek; };
}

/**
* @export
* @enum {string}
*/
export enum PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum {
    S = 'S',
    A = 'A',
    B = 'B',
    C = 'C'
}

export function PutDestockingOrderGroupRequestProductsFromJSON(json: any): PutDestockingOrderGroupRequestProducts {
    return PutDestockingOrderGroupRequestProductsFromJSONTyped(json, false);
}

export function PutDestockingOrderGroupRequestProductsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutDestockingOrderGroupRequestProducts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'shortageToleranceRank': json['shortageToleranceRank'],
        'inventoryPlanPerMdWeek': (mapValues(json['inventoryPlanPerMdWeek'], PutDestockingOrderGroupRequestInventoryPlanPerMdWeekFromJSON)),
    };
}

export function PutDestockingOrderGroupRequestProductsToJSON(value?: PutDestockingOrderGroupRequestProducts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'shortageToleranceRank': value.shortageToleranceRank,
        'inventoryPlanPerMdWeek': (mapValues(value.inventoryPlanPerMdWeek, PutDestockingOrderGroupRequestInventoryPlanPerMdWeekToJSON)),
    };
}


