import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ScrollState = { left: number };
const initialState: ScrollState = { left: 0 };

export const scrollSlice = createSlice({
  name: 'scroll',
  initialState,
  reducers: {
    scroll (state, action: PayloadAction<number>) {
      state.left = action.payload;
    }
  }
});

export const selectScroll= (state: ScrollState) => state.left;
