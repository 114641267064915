import { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import firebase from 'firebase/app';

import {
  DefaultApi,
  Configuration,
  MdWeekDate,
} from '../api-client';

export const useCurrentMdWeek = () => {
  const [currentMdWeek, setCurrentMdWeek] = useState<MdWeekDate | undefined>(undefined);

  useEffect(() => {
    firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        return api.getMdWeek();
      })
      .then((response) => setCurrentMdWeek(response.mdWeekDate))
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, []);

  return {
    currentMdWeek,
    setCurrentMdWeek,
  };
};
