import React, { useState } from 'react';
import firebase from 'firebase/app';

const FirebaseAppContext = React.createContext<firebase.app.App | undefined>(undefined);

// https://firebase.google.com/docs/web/setup#config-object
export interface FirebaseOptions {
  apiKey?: string;
  authDomain?: string;
  databaseURL?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
  measurementId?: string;
}

export interface AuthProps {
  firebaseOptions: FirebaseOptions
}

export const Auth: React.FC<AuthProps> = ({
  firebaseOptions,
  ...props
}) => {

  let firebaseApp: firebase.app.App;
  if (firebase.apps.length === 0) {
    firebaseApp = firebase.initializeApp(firebaseOptions);
  } else {
    firebaseApp = firebase.app();
  }

  const [loading, setLoading] = useState(true);
  firebase.auth().onAuthStateChanged(() => {
    setLoading(false);
  });

  return loading? (
    // TODO: Provide loading UI
    <div />
  ) : (
    <FirebaseAppContext.Provider value={firebaseApp} {...props} />
  );
};
