import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useHistory,
} from 'react-router-dom';

import {
  User,
  UserRole,
  SKU,
} from '../../api-client';

import {
  SKUOperationType,
} from '../../constants';

import {
  useSKUs,
} from '../../hooks';

import {
  Button,
  LoadingSpinner,
  PageHeader,
  Toast,
} from '../../components/atoms';

import {
  DeleteConfirmationModal,
  SearchSKUsParams,
  SearchSKUsForm,
  SKUsListTable,
} from '../../components/molecules';

import {
  ButtonContainer,
  Container,
  LoadingSpinnerContainer,
  SearchSKUsFormContainer,
  SKUsListTableContainer,
  ToastContainer,
} from './style';

type HistoryLocationState = {
  skuOperationType: SKUOperationType | undefined
};

export interface SKUsProps {
  user: User
}

export const SKUs: React.FC<SKUsProps> = ({
  user,
}) => {

  const history = useHistory<HistoryLocationState>();

  const getToastMessage = (operationType: SKUOperationType | undefined | null) => {
    switch (operationType) {
      case SKUOperationType.Create:
        return '登録しました。';
      default:
        return '';
    }
  };

  const [deleteTargetSKUs, setDeleteTargetSKUs] = useState<Array<SKU>>([]);
  const [deleteSKUsConfirmationVisible, setDeleteSKUsConfirmationVisible] = useState(false);

  const skuOperationType = history.location.state != null? history.location.state.skuOperationType : null;
  const [toastMessage, setToastMessage] = useState(getToastMessage(skuOperationType));
  const [toastVisible, setToastVisible] = useState(skuOperationType != null);
  useEffect(() => {
    history.push({
      pathname: '/skus',
      state: {
        skuOperationType: undefined,
      },
    });
  }, [history]);

  const searchParamsRef = useRef<SearchSKUsParams>({
    orderGroupName: null,
    code: null,
    name: null,
    specName: null,
    hasEndOfSalesAt: false,
  });

  const {
    skus,
    orderGroups,
    getSKUs,
    putSKUs,
    deleteSKUs,
  } = useSKUs();

  return (
    <Container>
      <PageHeader
        pageTitle='SKU管理'
        backLink={false}
      />
      {user.role === UserRole.Operator? (
        <ButtonContainer>
          <Button
            styleType='secondary'
            label='SKU登録'
            width={116}
            plusIcon={true}
            onClickHandler={() => history.push('/skus/new')}
            data-testid='create-sku-button'
          />
        </ButtonContainer>
      ) : null}
      {toastVisible === true? (
        <ToastContainer>
          <Toast
            message={toastMessage}
            onCloseHandler={() => {
              setTimeout(() => setToastVisible(false), 300);
            }}
          />
        </ToastContainer>
      ) : null}
      {deleteSKUsConfirmationVisible === true? (
        <DeleteConfirmationModal
          message={`${deleteTargetSKUs.length}件のSKUを削除しますか？`}
          disabled={false}
          onSubmitHandler={() => {
            deleteSKUs(
              deleteTargetSKUs,
              searchParamsRef.current.orderGroupName,
              searchParamsRef.current.code,
              searchParamsRef.current.name,
              searchParamsRef.current.specName,
              searchParamsRef.current.hasEndOfSalesAt,
            )
              ?.then(() => {
                setDeleteSKUsConfirmationVisible(false);
                setDeleteTargetSKUs([]);
                setToastMessage('削除しました。');
                setToastVisible(true);
              });
          }}
          onCloseHandler={() => {
            setDeleteSKUsConfirmationVisible(false);
            setDeleteTargetSKUs([]);
          }}
        />
      ) : null}
      <SearchSKUsFormContainer>
        <SearchSKUsForm
          searchParams={{
            orderGroupName: searchParamsRef.current.orderGroupName,
            code: searchParamsRef.current.code,
            name: searchParamsRef.current.name,
            specName: searchParamsRef.current.specName,
            hasEndOfSalesAt: searchParamsRef.current.hasEndOfSalesAt,
          }}
          onSubmitHandler={(params) => {
            searchParamsRef.current = params;
            getSKUs(
              searchParamsRef.current.orderGroupName,
              searchParamsRef.current.code,
              searchParamsRef.current.name,
              searchParamsRef.current.specName,
              searchParamsRef.current.hasEndOfSalesAt,
            );
          }}
        />
      </SearchSKUsFormContainer>
      {skus != null && orderGroups != null? (
        <SKUsListTableContainer>
          {skus.length === 0? (
            <span>検索結果がありません。</span>
          ) : (
            <React.Fragment>
              <SKUsListTable
                readonly={user.role === UserRole.Manager}
                skus={skus}
                selectableOrderGroups={orderGroups}
                onClickUpdateButtonHandler={(skus) => {
                  putSKUs(
                    skus,
                    searchParamsRef.current.orderGroupName,
                    searchParamsRef.current.code,
                    searchParamsRef.current.name,
                    searchParamsRef.current.specName,
                    searchParamsRef.current.hasEndOfSalesAt,
                  )
                    ?.then(() => {
                      setToastMessage('更新しました。');
                      setToastVisible(true);
                    });
                }}
                onClickDeleteButtonHandler={(skus) => {
                  setDeleteTargetSKUs(skus);
                  setDeleteSKUsConfirmationVisible(true);
                }}
              />
            </React.Fragment>
          )}
        </SKUsListTableContainer>
      ) : (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      )}
    </Container>
  );
};
