/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderGroup
 */
export interface OrderGroup {
    /**
     * 発注グループのID
     * @type {number}
     * @memberof OrderGroup
     */
    id: number;
    /**
     * 商品名
     * @type {string}
     * @memberof OrderGroup
     */
    name: string | null;
    /**
     * サプライヤー名
     * @type {string}
     * @memberof OrderGroup
     */
    supplierName: string | null;
    /**
     * 調整が必要なSKUの数
     * @type {number}
     * @memberof OrderGroup
     */
    numberOfProductsThatNeedAdjustment: number;
    /**
     * 発注が必要なSKUの数
     * @type {boolean}
     * @memberof OrderGroup
     */
    hasProductsThatNeedOrder: boolean;
    /**
     * 未発注のSKUがあるかどうか
     * @type {boolean}
     * @memberof OrderGroup
     */
    hasUnorderedProducts: boolean;
    /**
     * リードタイム
     * @type {number}
     * @memberof OrderGroup
     */
    leadtime: number;
    /**
     * サイクル回数(週)
     * @type {number}
     * @memberof OrderGroup
     */
    numberOfOrderCycles: number;
    /**
     * サイクル週数(週)
     * @type {number}
     * @memberof OrderGroup
     */
    numberOfOrderCycleWeeks: number;
    /**
     * 再計算が実行された日時
     * @type {string}
     * @memberof OrderGroup
     */
    plannedAt: string;
    /**
     * 担当者名
     * @type {string}
     * @memberof OrderGroup
     */
    operatorName: string;
    /**
     * サブカテゴリーの予測を使用するかどうか
     * @type {boolean}
     * @memberof OrderGroup
     */
    useSubcategoryPrediction: boolean;
}

export function OrderGroupFromJSON(json: any): OrderGroup {
    return OrderGroupFromJSONTyped(json, false);
}

export function OrderGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'supplierName': json['supplierName'],
        'numberOfProductsThatNeedAdjustment': json['numberOfProductsThatNeedAdjustment'],
        'hasProductsThatNeedOrder': json['hasProductsThatNeedOrder'],
        'hasUnorderedProducts': json['hasUnorderedProducts'],
        'leadtime': json['leadtime'],
        'numberOfOrderCycles': json['numberOfOrderCycles'],
        'numberOfOrderCycleWeeks': json['numberOfOrderCycleWeeks'],
        'plannedAt': json['plannedAt'],
        'operatorName': json['operatorName'],
        'useSubcategoryPrediction': json['useSubcategoryPrediction'],
    };
}

export function OrderGroupToJSON(value?: OrderGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'supplierName': value.supplierName,
        'numberOfProductsThatNeedAdjustment': value.numberOfProductsThatNeedAdjustment,
        'hasProductsThatNeedOrder': value.hasProductsThatNeedOrder,
        'hasUnorderedProducts': value.hasUnorderedProducts,
        'leadtime': value.leadtime,
        'numberOfOrderCycles': value.numberOfOrderCycles,
        'numberOfOrderCycleWeeks': value.numberOfOrderCycleWeeks,
        'plannedAt': value.plannedAt,
        'operatorName': value.operatorName,
        'useSubcategoryPrediction': value.useSubcategoryPrediction,
    };
}


