import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  DatabaseOrderGroup,
  User,
} from '../../../api-client';
import cloneDeep from 'lodash/cloneDeep';

import {
  Button,
  CheckBox,
  Dropdown,
  DropdownOption,
  TextBox,
  Tooltip,
} from '../../atoms';

import {
  BaseOrderGroupsListTable,
  ButtonsContainer,
  CountLabel,
  DeleteButtonContainer,
  OrderGroupsListTableBodyRow,
  OrderGroupsListTableData,
  OrderGroupsListTableHeader,
  TextBoxContainer,
} from './style';

type LastEditedNCycleParameter = 'leadtime' | 'numberOfOrderCycles' | 'numberOfOrderCycleWeeks';

export interface OrderGroupsListTableProps {
  /**
   * 発注グループ一覧
   */
  orderGroups: Array<DatabaseOrderGroup>

  /**
   * 選択可能な担当者一覧
   */
  selectableOperators: Array<User>

  /**
   * 読み取り専用かどうか
   */
  readonly: boolean

  /**
   * 担当者のみ切り替え可能な表示にするかどうか
   */
  onlyOperatorsChangeable: boolean

  /**
   * 更新ボタンがクリックされた時に呼び出されるハンドラー
   */
  onClickUpdateButtonHandler: (orderGroups: Array<DatabaseOrderGroup>) => void

  /**
   * 削除ボタンがクリックされた時に呼び出されるハンドラー
   */
  onClickDeleteButtonHandler: (skus: Array<DatabaseOrderGroup>) => void
}

export const OrderGroupsListTable: React.FC<OrderGroupsListTableProps> = ({
  orderGroups,
  selectableOperators,
  readonly,
  onlyOperatorsChangeable,
  onClickUpdateButtonHandler,
  onClickDeleteButtonHandler,
}) => {

  // 再レンダリングを行いたい場合に使う.
  // 全ての state を更新していると再レンダリングの負荷がかかりすぎるので、必要な時にのみ実行すること.
  const forceUpdate = useState(false)[1];

  const dropdownOptions: Array<DropdownOption<number>> = selectableOperators.map((operator) => ({
    label: operator.name,
    value: operator.id,
  }));

  const orderGroupsRef = useRef(cloneDeep(orderGroups));
  const lastEditedNCycleParameter = useRef<Array<LastEditedNCycleParameter>>(orderGroupsRef.current.map(() => 'leadtime'));
  const checkedOrderGroups = useRef<{ [key: number]: boolean }>({});


  useEffect(() => {
    orderGroupsRef.current = cloneDeep(orderGroups);
    lastEditedNCycleParameter.current = orderGroupsRef.current.map(() => 'leadtime');
    checkedOrderGroups.current = {};
  }, [orderGroups]);

  const isValidName = (name: string) => /^[0-9a-zA-Z-]{1,40}$/.test(name);

  // リードタイムの最大有効値は (52 - (サイクル回数 * サイクル週数)) * 7
  const calcMaxLeadtime = (
    numberOfOrderCycles: number,
    numberOfOrderCycleWeeks: number,
  ) => (52 - (numberOfOrderCycles * numberOfOrderCycleWeeks)) * 7;

  // サイクル回数の最大有効値は (52 - (リードタイム / 7)) / サイクル週数
  // リードタイムが7で割り切れない場合は切り上げる
  // サイクル週数で割って出た余りは切り捨てる（合計52を超えないようにする）
  const calcMaxNumberOfOrderCycles = (
    leadtime: number,
    numberOfOrderCycleWeeks: number,
  ) => Math.floor((52 - Math.ceil(leadtime / 7)) / numberOfOrderCycleWeeks);

  // サイクル週数の最大有効値は (52 - ((リードタイム / 7)) / サイクル回数
  // リードタイムが7で割り切れない場合は切り上げる
  // サイクル回数で割って出た余りは切り捨てる（合計52を超えないようにする）
  const calcMaxNumberOfOrderCycleWeeks = (
    leadtime: number,
    numberOfOrderCycles: number,
  ) => Math.floor((52 - Math.ceil(leadtime / 7)) / numberOfOrderCycles);

  const hasInvalidOrderGroup = (orderGroups: Array<DatabaseOrderGroup>) =>
    orderGroups.some((orderGroup) =>
      isValidName(orderGroup.name) === false ||
      orderGroup.leadtime < 1 ||
      orderGroup.leadtime > calcMaxLeadtime(orderGroup.numberOfOrderCycles, orderGroup.numberOfOrderCycleWeeks) ||
      orderGroup.numberOfOrderCycles < 1 ||
      orderGroup.numberOfOrderCycles > calcMaxNumberOfOrderCycles(orderGroup.leadtime, orderGroup.numberOfOrderCycleWeeks) ||
      orderGroup.numberOfOrderCycleWeeks < 1 ||
      orderGroup.numberOfOrderCycleWeeks >  calcMaxNumberOfOrderCycleWeeks(orderGroup.leadtime, orderGroup.numberOfOrderCycles)
    );

  return (
    <>
      <ButtonsContainer>
        <Button
          styleType='primary'
          label='更新'
          width={76}
          disabled={
            readonly ||
            JSON.stringify(orderGroups) === JSON.stringify(orderGroupsRef.current) ||
            hasInvalidOrderGroup(orderGroupsRef.current) === true
          }
          onClickHandler={() => {
            onClickUpdateButtonHandler(orderGroupsRef.current);
          }}
          data-testid='order-groups-list-table-update-button'
        />
        <DeleteButtonContainer>
          <Button
            styleType='tertiary'
            label='選択して削除'
            width={134}
            disabled={
              readonly ||
              onlyOperatorsChangeable ||
              orderGroupsRef.current.some((orderGroup) => checkedOrderGroups.current[orderGroup.id] === true) === false
            }
            onClickHandler={() => {
              onClickDeleteButtonHandler(orderGroupsRef.current.filter((orderGroup) => checkedOrderGroups.current[orderGroup.id] === true));
            }}
            data-testid='order-groups-list-table-delete-button'
          />
        </DeleteButtonContainer>
      </ButtonsContainer>
      <CountLabel>{orderGroups.length}件</CountLabel>
      <BaseOrderGroupsListTable>
        <thead>
          <tr>
            <OrderGroupsListTableHeader width={46}>
              <CheckBox
                id='order-groups-list-table-checkbox-all'
                defaultValue={
                  orderGroupsRef.current.some((orderGroup) =>
                    checkedOrderGroups.current[orderGroup.id] == null || checkedOrderGroups.current[orderGroup.id] === false
                  ) === false
                }
                disabled={
                  readonly ||
                  onlyOperatorsChangeable
                }
                onChangeHandler={(value) => {
                  orderGroupsRef.current.forEach((orderGroup) => {
                    // SKU持ちの発注グループは削除対象外
                    if (orderGroup.numberOfSKUs > 0) {
                      return;
                    }
                    checkedOrderGroups.current[orderGroup.id] = value;
                  });
                  forceUpdate(v => !v);
                }}
              />
            </OrderGroupsListTableHeader>
            <OrderGroupsListTableHeader width={295}>発注グループ</OrderGroupsListTableHeader>
            <OrderGroupsListTableHeader width={113}>リードタイム<br />（日）</OrderGroupsListTableHeader>
            <OrderGroupsListTableHeader width={113}>サイクル回数<br />（週）</OrderGroupsListTableHeader>
            <OrderGroupsListTableHeader width={113}>サイクル週数<br />（週）</OrderGroupsListTableHeader>
            <OrderGroupsListTableHeader width={254}>担当者</OrderGroupsListTableHeader>
            <OrderGroupsListTableHeader width={113}>SKU数</OrderGroupsListTableHeader>
            <OrderGroupsListTableHeader></OrderGroupsListTableHeader>
          </tr>
        </thead>
        <tbody>
          {orderGroups.map((orderGroup, idx) => {
            return (
              <OrderGroupsListTableBodyRow key={orderGroup.id}>
                <OrderGroupsListTableData>
                  <CheckBox
                    id={`order-groups-list-table-checkbox-${orderGroup.id}`}
                    defaultValue={checkedOrderGroups.current[orderGroup.id] === true}
                    disabled={
                      readonly ||
                      onlyOperatorsChangeable ||
                      orderGroup.numberOfSKUs > 0
                    }
                    onChangeHandler={(value) => {
                      checkedOrderGroups.current[orderGroup.id] = value;
                      forceUpdate(v => !v);
                    }}
                  />
                </OrderGroupsListTableData>
                <OrderGroupsListTableData>
                  <TextBoxContainer>
                    <TextBox
                      id='order-groups-list-table-name-input'
                      type='text'
                      width={265}
                      height={40}
                      defaultValue={orderGroup.name}
                      disabled={
                        readonly ||
                        onlyOperatorsChangeable
                      }
                      required={true}
                      forceValidate={true}
                      customErrorMessage={isValidName(orderGroup.name) === false? '半角英数字かハイフン(-)の40文字以内で入力して下さい。' : ''}
                      suppressErrorMessage={true}
                      highlightOnChange={true}
                      onChangeHandler={(value) => {
                        orderGroupsRef.current[idx].name = value;
                        forceUpdate(v => !v);
                      }}
                    />
                    {isValidName(orderGroupsRef.current[idx].name) === false? (
                      <Tooltip
                        id='order-groups-list-table-name-input-error'
                        text='半角英数字かハイフン(-)の40文字以内で入力して下さい。'
                        top={40}
                        width={380}
                      />
                    ) : null}
                  </TextBoxContainer>
                </OrderGroupsListTableData>
                <OrderGroupsListTableData>
                  <TextBoxContainer>
                    <TextBox
                      id='order-groups-list-table-leadtime-input'
                      type='number'
                      width={83}
                      height={40}
                      defaultValue={orderGroup.leadtime.toString()}
                      disabled={
                        readonly ||
                        onlyOperatorsChangeable
                      }
                      required={true}
                      min={1}
                      max={calcMaxLeadtime(orderGroup.numberOfOrderCycles, orderGroup.numberOfOrderCycleWeeks)}
                      forceValidate={true}
                      suppressErrorMessage={true}
                      highlightOnChange={true}
                      onChangeHandler={(value) => {
                        const maybeLeadtime = Number.parseInt(value);
                        const leadtime = Number.isNaN(maybeLeadtime) === true? 0 : maybeLeadtime;
                        if (orderGroupsRef.current[idx].leadtime !== leadtime) {
                          orderGroupsRef.current[idx].leadtime = leadtime;
                          lastEditedNCycleParameter.current[idx] = 'leadtime';
                          forceUpdate(v => !v);
                        }
                      }}
                    />
                    {lastEditedNCycleParameter.current[idx] === 'leadtime' && (
                      orderGroupsRef.current[idx].leadtime < 1 ||
                      orderGroupsRef.current[idx].leadtime > calcMaxLeadtime(orderGroupsRef.current[idx].numberOfOrderCycles, orderGroupsRef.current[idx].numberOfOrderCycleWeeks)
                    )? (
                        <Tooltip
                          id='order-groups-list-table-leadtime-input-error'
                          text='合計52週の半角数字で入力して下さい。'
                          top={40}
                          width={270}
                        />
                      ) : null}
                  </TextBoxContainer>
                </OrderGroupsListTableData>
                <OrderGroupsListTableData>
                  <TextBoxContainer>
                    <TextBox
                      id='order-groups-list-table-number-of-order-cycles-input'
                      type='number'
                      width={83}
                      height={40}
                      defaultValue={orderGroup.numberOfOrderCycles.toString()}
                      disabled={
                        readonly ||
                        onlyOperatorsChangeable
                      }
                      required={true}
                      min={1}
                      max={calcMaxNumberOfOrderCycles(orderGroup.leadtime, orderGroup.numberOfOrderCycleWeeks)}
                      forceValidate={true}
                      suppressErrorMessage={true}
                      highlightOnChange={true}
                      onChangeHandler={(value) => {
                        const maybeNumberOfOrderCycles = Number.parseInt(value);
                        const numberOfOrderCycles = Number.isNaN(maybeNumberOfOrderCycles) === true? 0 : maybeNumberOfOrderCycles;
                        if (orderGroupsRef.current[idx].numberOfOrderCycles !== numberOfOrderCycles) {
                          orderGroupsRef.current[idx].numberOfOrderCycles = numberOfOrderCycles;
                          lastEditedNCycleParameter.current[idx] = 'numberOfOrderCycles';
                          forceUpdate(v => !v);
                        }
                      }}
                    />
                    {lastEditedNCycleParameter.current[idx] === 'numberOfOrderCycles' && (
                      orderGroupsRef.current[idx].numberOfOrderCycles < 1 ||
                      orderGroupsRef.current[idx].numberOfOrderCycles > calcMaxNumberOfOrderCycles(orderGroupsRef.current[idx].leadtime, orderGroupsRef.current[idx].numberOfOrderCycleWeeks)
                    )? (
                        <Tooltip
                          id='order-groups-list-table-number-of-order-cycles-input-error'
                          text='合計52週の半角数字で入力して下さい。'
                          top={40}
                          width={270}
                        />
                      ) : null}
                  </TextBoxContainer>
                </OrderGroupsListTableData>
                <OrderGroupsListTableData>
                  <TextBoxContainer>
                    <TextBox
                      id='order-groups-list-table-number-of-order-cycle-weeks-input'
                      type='number'
                      width={83}
                      height={40}
                      defaultValue={orderGroup.numberOfOrderCycleWeeks.toString()}
                      disabled={
                        readonly ||
                        onlyOperatorsChangeable
                      }
                      required={true}
                      min={1}
                      max={calcMaxNumberOfOrderCycleWeeks(orderGroup.leadtime, orderGroup.numberOfOrderCycles)}
                      forceValidate={true}
                      suppressErrorMessage={true}
                      highlightOnChange={true}
                      onChangeHandler={(value) => {
                        const maybeNumberOfOrderCycles = Number.parseInt(value);
                        const numberOfOrderCycleWeeks = Number.isNaN(maybeNumberOfOrderCycles) === true? 0 : maybeNumberOfOrderCycles;
                        if (orderGroupsRef.current[idx].numberOfOrderCycleWeeks !== numberOfOrderCycleWeeks) {
                          orderGroupsRef.current[idx].numberOfOrderCycleWeeks = numberOfOrderCycleWeeks;
                          lastEditedNCycleParameter.current[idx] = 'numberOfOrderCycleWeeks';
                          forceUpdate(v => !v);
                        }
                      }}
                    />
                    {lastEditedNCycleParameter.current[idx] === 'numberOfOrderCycleWeeks' && (
                      orderGroupsRef.current[idx].numberOfOrderCycleWeeks < 1 ||
                      orderGroupsRef.current[idx].numberOfOrderCycleWeeks >  calcMaxNumberOfOrderCycleWeeks(orderGroupsRef.current[idx].leadtime, orderGroupsRef.current[idx].numberOfOrderCycles)
                    )? (
                        <Tooltip
                          id='order-groups-list-table-number-of-order-cycle-weeks-input-error'
                          text='合計52週の半角数字で入力して下さい。'
                          top={40}
                          width={270}
                        />
                      ) : null}
                  </TextBoxContainer>
                </OrderGroupsListTableData>
                <OrderGroupsListTableData>
                  <Dropdown
                    options={dropdownOptions}
                    defaultValue={orderGroup.operatorId}
                    width={200}
                    disabled={readonly}
                    highlightOnChange={true}
                    onChangeHandler={(value) => {
                      orderGroupsRef.current[idx].operatorId = value;
                      forceUpdate(v => !v);
                    }}
                  />
                </OrderGroupsListTableData>
                <OrderGroupsListTableData>{orderGroup.numberOfSKUs === 0? '-' : orderGroup.numberOfSKUs}</OrderGroupsListTableData>
                <OrderGroupsListTableData></OrderGroupsListTableData>
              </OrderGroupsListTableBodyRow>
            );
          }) }
        </tbody>
      </BaseOrderGroupsListTable>
    </>
  );
};
