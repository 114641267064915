import React from 'react';

import {
  BlackLogo,
  WhiteLogo
} from './style';

type LogoColor = 'black' | 'white';

export interface LogoProps {
  /**
   * ロゴの色
   */
  logoColor: LogoColor
}

export const Logo: React.FC<LogoProps> = ({
  logoColor
}) => logoColor === 'black' ? (
  <BlackLogo data-testid="logo" />
) : (
  <WhiteLogo data-testid="logo" />
);
