import styled from 'styled-components';

const bgcolors = (color: string): string => {
  switch (color) {
    case 'green':
      return '#d2f2ce';
    case 'yellow':
      return '#efedbc';
    case 'grey':
      return '#d9d9d9';
    case 'blue':
      return '#d8e7fa';
    case 'red':
      return '#f8d9d9';
    default:
      return 'inherit';
  }
};

const colors = (color: string): string => {
  switch (color) {
    case 'green':
      return '#1d721f';
    case 'yellow':
      return '#725915';
    case 'grey':
      return '#505050';
    case 'blue':
      return '#1e57b0';
    case 'red':
      return '#b72727';
    default:
      return 'inherit';
  }
};

const BaseLabel = styled.div<{color: string, width: number}>`
  background-color: ${props => bgcolors(props.color)};
  border-radius: 2px;
  color: ${props => colors(props.color)};
  font-weight: bold;
  height: 22px;
  text-align: center;
  width: ${props => `${props.width}px`};
`;

export {
  BaseLabel
};
