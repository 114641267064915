import styled from 'styled-components';

const BaseOrderGroupsTable = styled.table`
  border: solid 1px var(--di-table-border-color);
  width: 100%;
`;

const OrderGroupsTableBodyRow = styled.tr<{onClick: () => void}>`
  background-color: #fff;
  border-top: solid 1px var(--di-table-border-color);
  cursor: pointer;
  transition: background-color 0.3s 0s ease;

  &:hover {
    background-color: var(--di-table-body-bg-hover-color);
  }
`;

const OrderGroupsTableData = styled.td<{textalign?: string, width?: number}>`
  height: 48px;
  text-align: ${props => props.textalign? props.textalign : 'center'};
  width: ${props => props.width? `${props.width}px` : 'auto'};

  &:first-child {
    padding-left: 40px;
  }
`;

const OrderGroupsTableHeader = styled.th<{textalign?: string, width?: number}>`
  height: 48px;
  text-align: ${props => props.textalign? props.textalign : 'center'};
  width: ${props => props.width? `${props.width}px` : 'auto'};

  &:first-child {
    padding-left: 40px;
  }
`;

const UnorderedProductsLabel = styled.span`
  color: var(--di-text-error-color);
  padding-left: 30px;
`;

export {
  BaseOrderGroupsTable,
  OrderGroupsTableBodyRow,
  OrderGroupsTableData,
  OrderGroupsTableHeader,
  UnorderedProductsLabel,
};
