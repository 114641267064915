import React from 'react';

import styles from './SquareLabel.module.css';

export type SquareLabelColor = 'green' | 'yellow' | 'red' | 'grey';

export interface SquareLabelProps {
  /**
   * 色
   */
  color: SquareLabelColor

  /**
   * 文字
   */
  text: string

  /**
   * 幅
   */
  width: number
}

export const SquareLabel: React.FC<SquareLabelProps> = ({
  color,
  text,
  width,
}) => (
  // <BaseSquareLabel color={color} width={width}>{text}</BaseSquareLabel>
  <div className={`
    ${styles.base}
    ${color === 'red' ? styles.red :color === 'yellow' ? styles.yellow :color === 'grey'   ? styles.grey : styles.green}
  `}
  style={{ width: width }}
  >{text}</div>
);
