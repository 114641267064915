import styled from 'styled-components';

const BaseSKUsListTable = styled.table`
  border: solid 1px var(--di-table-border-color);
  table-layout: fixed;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Count = styled.div`
  margin-bottom: 8px;
  margin-left: 13px;
  margin-top: -14px;
`;

const DeleteButtonContainer = styled.div`
  margin-left: 20px;
`;

const M3 = styled.span`
  color: #999;
`;

const QuantityPerCase = styled.span`
  color: #999;
  margin-right: 14px;
`;

const SKUName = styled.div`
  width: 400px;
`;

const SKUsListTableBodyRow = styled.tr`
  background-color: #fff;
  border-top: solid 1px var(--di-table-border-color);
  height: 114px;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: var(--di-table-body-bg-hover-color);
  }
`;

const SKUsListTableHeader = styled.th<{width: number}>`
  background-color: #f1f1f1;
  box-sizing: border-box;
  height: 47px;
  text-align: left;
  width: ${props => `${props.width}px`};
`;

const SKUSpec = styled.div`
  width: 400px;
`;

const SupplierName = styled.div`
  color: #999;
`;

const TextBoxContainer = styled.div`
  position: relative;
`;

export {
  BaseSKUsListTable,
  ButtonsContainer,
  CheckBoxContainer,
  Count,
  DeleteButtonContainer,
  M3,
  QuantityPerCase,
  SKUName,
  SKUsListTableBodyRow,
  SKUsListTableHeader,
  SKUSpec,
  SupplierName,
  TextBoxContainer,
};
