/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteDatabaseOrderGroupsRequest,
    DeleteDatabaseOrderGroupsRequestFromJSON,
    DeleteDatabaseOrderGroupsRequestToJSON,
    DeleteSKUsRequest,
    DeleteSKUsRequestFromJSON,
    DeleteSKUsRequestToJSON,
    GetAlertSummaryResponse,
    GetAlertSummaryResponseFromJSON,
    GetAlertSummaryResponseToJSON,
    GetDatabaseOrderGroupsResponse,
    GetDatabaseOrderGroupsResponseFromJSON,
    GetDatabaseOrderGroupsResponseToJSON,
    GetDestockingOrderGroupResponse,
    GetDestockingOrderGroupResponseFromJSON,
    GetDestockingOrderGroupResponseToJSON,
    GetDestockingOrderGroupsResponse,
    GetDestockingOrderGroupsResponseFromJSON,
    GetDestockingOrderGroupsResponseToJSON,
    GetMdWeekResponse,
    GetMdWeekResponseFromJSON,
    GetMdWeekResponseToJSON,
    GetOwnOrderGroupsResponse,
    GetOwnOrderGroupsResponseFromJSON,
    GetOwnOrderGroupsResponseToJSON,
    GetSKUsResponse,
    GetSKUsResponseFromJSON,
    GetSKUsResponseToJSON,
    PostDatabaseOrderGroupRequest,
    PostDatabaseOrderGroupRequestFromJSON,
    PostDatabaseOrderGroupRequestToJSON,
    PostSKURequest,
    PostSKURequestFromJSON,
    PostSKURequestToJSON,
    PostUsersRequest,
    PostUsersRequestFromJSON,
    PostUsersRequestToJSON,
    PutDatabaseOrderGroupsRequest,
    PutDatabaseOrderGroupsRequestFromJSON,
    PutDatabaseOrderGroupsRequestToJSON,
    PutDestockingOrderGroupIncomingMdWeekBackwardRequest,
    PutDestockingOrderGroupIncomingMdWeekBackwardRequestFromJSON,
    PutDestockingOrderGroupIncomingMdWeekBackwardRequestToJSON,
    PutDestockingOrderGroupIncomingMdWeekForwardRequest,
    PutDestockingOrderGroupIncomingMdWeekForwardRequestFromJSON,
    PutDestockingOrderGroupIncomingMdWeekForwardRequestToJSON,
    PutDestockingOrderGroupOrderRequest,
    PutDestockingOrderGroupOrderRequestFromJSON,
    PutDestockingOrderGroupOrderRequestToJSON,
    PutDestockingOrderGroupRequest,
    PutDestockingOrderGroupRequestFromJSON,
    PutDestockingOrderGroupRequestToJSON,
    PutSKUMetaRequest,
    PutSKUMetaRequestFromJSON,
    PutSKUMetaRequestToJSON,
    PutSKUsRequest,
    PutSKUsRequestFromJSON,
    PutSKUsRequestToJSON,
    PutUserRequest,
    PutUserRequestFromJSON,
    PutUserRequestToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface DeleteDatabaseOrderGroupsOperationRequest {
    bodyParam: DeleteDatabaseOrderGroupsRequest;
}

export interface DeleteSKUsOperationRequest {
    bodyParam: DeleteSKUsRequest;
}

export interface DeleteUserRequest {
    userId: number;
}

export interface GetDatabaseOrderGroupsRequest {
    orderGroupName?: string;
    operatorId?: number;
}

export interface GetDestockingOrderGroupRequest {
    orderGroupId: number;
}

export interface GetDestockingOrderGroupCsvRequest {
    orderGroupId: number;
}

export interface GetSKUsRequest {
    hasEndOfSalesAt: boolean;
    orderGroupName?: string;
    code?: string;
    name?: string;
    specName?: string;
}

export interface GetUserByIDRequest {
    userId: number;
}

export interface PostDatabaseOrderGroupOperationRequest {
    bodyParam: PostDatabaseOrderGroupRequest;
}

export interface PostSKUOperationRequest {
    bodyParam: PostSKURequest;
}

export interface PostUsersOperationRequest {
    bodyParam: PostUsersRequest;
}

export interface PutDatabaseOrderGroupsOperationRequest {
    bodyParam: PutDatabaseOrderGroupsRequest;
}

export interface PutDestockingOrderGroupOperationRequest {
    orderGroupId: number;
    bodyParam: PutDestockingOrderGroupRequest;
}

export interface PutDestockingOrderGroupIncomingMdWeekBackwardOperationRequest {
    orderGroupId: number;
    bodyParam: PutDestockingOrderGroupIncomingMdWeekBackwardRequest;
}

export interface PutDestockingOrderGroupIncomingMdWeekForwardOperationRequest {
    orderGroupId: number;
    bodyParam: PutDestockingOrderGroupIncomingMdWeekForwardRequest;
}

export interface PutDestockingOrderGroupOrderOperationRequest {
    orderGroupId: number;
    bodyParam: PutDestockingOrderGroupOrderRequest;
}

export interface PutSKUMetaOperationRequest {
    productCode: string;
    bodyParam: PutSKUMetaRequest;
}

export interface PutSKUsOperationRequest {
    bodyParam: PutSKUsRequest;
}

export interface PutUserOperationRequest {
    userId: number;
    bodyParam: PutUserRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * 発注グループを一括削除する
     */
    async deleteDatabaseOrderGroupsRaw(requestParameters: DeleteDatabaseOrderGroupsOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling deleteDatabaseOrderGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/database-order-groups`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteDatabaseOrderGroupsRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 発注グループを一括削除する
     */
    async deleteDatabaseOrderGroups(requestParameters: DeleteDatabaseOrderGroupsOperationRequest): Promise<void> {
        await this.deleteDatabaseOrderGroupsRaw(requestParameters);
    }

    /**
     * SKUを一括削除する
     */
    async deleteSKUsRaw(requestParameters: DeleteSKUsOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling deleteSKUs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/skus`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteSKUsRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * SKUを一括削除する
     */
    async deleteSKUs(requestParameters: DeleteSKUsOperationRequest): Promise<void> {
        await this.deleteSKUsRaw(requestParameters);
    }

    /**
     * ユーザーを削除
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ユーザーを削除
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }

    /**
     * アラートのサマリー
     */
    async getAlertSummaryRaw(): Promise<runtime.ApiResponse<GetAlertSummaryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alert-summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAlertSummaryResponseFromJSON(jsonValue));
    }

    /**
     * アラートのサマリー
     */
    async getAlertSummary(): Promise<GetAlertSummaryResponse> {
        const response = await this.getAlertSummaryRaw();
        return await response.value();
    }

    /**
     * 発注グループ一覧
     */
    async getDatabaseOrderGroupsRaw(requestParameters: GetDatabaseOrderGroupsRequest): Promise<runtime.ApiResponse<GetDatabaseOrderGroupsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.orderGroupName !== undefined) {
            queryParameters['orderGroupName'] = requestParameters.orderGroupName;
        }

        if (requestParameters.operatorId !== undefined) {
            queryParameters['operatorId'] = requestParameters.operatorId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/database-order-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDatabaseOrderGroupsResponseFromJSON(jsonValue));
    }

    /**
     * 発注グループ一覧
     */
    async getDatabaseOrderGroups(requestParameters: GetDatabaseOrderGroupsRequest): Promise<GetDatabaseOrderGroupsResponse> {
        const response = await this.getDatabaseOrderGroupsRaw(requestParameters);
        return await response.value();
    }

    /**
     * 在庫調整画面の情報取得
     */
    async getDestockingOrderGroupRaw(requestParameters: GetDestockingOrderGroupRequest): Promise<runtime.ApiResponse<GetDestockingOrderGroupResponse>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling getDestockingOrderGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/destocking-order-groups/{OrderGroupId}`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDestockingOrderGroupResponseFromJSON(jsonValue));
    }

    /**
     * 在庫調整画面の情報取得
     */
    async getDestockingOrderGroup(requestParameters: GetDestockingOrderGroupRequest): Promise<GetDestockingOrderGroupResponse> {
        const response = await this.getDestockingOrderGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * 在庫調整画面の情報が入ったCSVファイルのダウンロード
     */
    async getDestockingOrderGroupCsvRaw(requestParameters: GetDestockingOrderGroupCsvRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling getDestockingOrderGroupCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/destocking-order-groups/{OrderGroupId}/csv`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * 在庫調整画面の情報が入ったCSVファイルのダウンロード
     */
    async getDestockingOrderGroupCsv(requestParameters: GetDestockingOrderGroupCsvRequest): Promise<Blob> {
        const response = await this.getDestockingOrderGroupCsvRaw(requestParameters);
        return await response.value();
    }

    /**
     * 発注グループ一覧の取得
     */
    async getDestockingOrderGroupsRaw(): Promise<runtime.ApiResponse<GetDestockingOrderGroupsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/destocking-order-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDestockingOrderGroupsResponseFromJSON(jsonValue));
    }

    /**
     * 発注グループ一覧の取得
     */
    async getDestockingOrderGroups(): Promise<GetDestockingOrderGroupsResponse> {
        const response = await this.getDestockingOrderGroupsRaw();
        return await response.value();
    }

    /**
     * 当週の情報
     */
    async getMdWeekRaw(): Promise<runtime.ApiResponse<GetMdWeekResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/md-week`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMdWeekResponseFromJSON(jsonValue));
    }

    /**
     * 当週の情報
     */
    async getMdWeek(): Promise<GetMdWeekResponse> {
        const response = await this.getMdWeekRaw();
        return await response.value();
    }

    /**
     * 一般ユーザーの一覧
     */
    async getOperatorsRaw(): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/operators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * 一般ユーザーの一覧
     */
    async getOperators(): Promise<Array<User>> {
        const response = await this.getOperatorsRaw();
        return await response.value();
    }

    /**
     * 担当している発注グループの一覧
     */
    async getOwnOrderGroupsRaw(): Promise<runtime.ApiResponse<GetOwnOrderGroupsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/own-order-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOwnOrderGroupsResponseFromJSON(jsonValue));
    }

    /**
     * 担当している発注グループの一覧
     */
    async getOwnOrderGroups(): Promise<GetOwnOrderGroupsResponse> {
        const response = await this.getOwnOrderGroupsRaw();
        return await response.value();
    }

    /**
     * SKUの一覧を取得する
     */
    async getSKUsRaw(requestParameters: GetSKUsRequest): Promise<runtime.ApiResponse<GetSKUsResponse>> {
        if (requestParameters.hasEndOfSalesAt === null || requestParameters.hasEndOfSalesAt === undefined) {
            throw new runtime.RequiredError('hasEndOfSalesAt','Required parameter requestParameters.hasEndOfSalesAt was null or undefined when calling getSKUs.');
        }

        const queryParameters: any = {};

        if (requestParameters.orderGroupName !== undefined) {
            queryParameters['orderGroupName'] = requestParameters.orderGroupName;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.specName !== undefined) {
            queryParameters['specName'] = requestParameters.specName;
        }

        if (requestParameters.hasEndOfSalesAt !== undefined) {
            queryParameters['hasEndOfSalesAt'] = requestParameters.hasEndOfSalesAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/skus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSKUsResponseFromJSON(jsonValue));
    }

    /**
     * SKUの一覧を取得する
     */
    async getSKUs(requestParameters: GetSKUsRequest): Promise<GetSKUsResponse> {
        const response = await this.getSKUsRaw(requestParameters);
        return await response.value();
    }

    /**
     * ログインユーザのアカウント情報を取得する
     */
    async getUserRaw(): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * ログインユーザのアカウント情報を取得する
     */
    async getUser(): Promise<User> {
        const response = await this.getUserRaw();
        return await response.value();
    }

    /**
     * ユーザーの情報を取得する
     */
    async getUserByIDRaw(requestParameters: GetUserByIDRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * ユーザーの情報を取得する
     */
    async getUserByID(requestParameters: GetUserByIDRequest): Promise<User> {
        const response = await this.getUserByIDRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザー一覧を取得する
     */
    async getUsersRaw(): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * ユーザー一覧を取得する
     */
    async getUsers(): Promise<Array<User>> {
        const response = await this.getUsersRaw();
        return await response.value();
    }

    /**
     * 発注グループを作成
     */
    async postDatabaseOrderGroupRaw(requestParameters: PostDatabaseOrderGroupOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling postDatabaseOrderGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/database-order-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDatabaseOrderGroupRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 発注グループを作成
     */
    async postDatabaseOrderGroup(requestParameters: PostDatabaseOrderGroupOperationRequest): Promise<void> {
        await this.postDatabaseOrderGroupRaw(requestParameters);
    }

    /**
     * SKUを登録する
     */
    async postSKURaw(requestParameters: PostSKUOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling postSKU.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/skus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSKURequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * SKUを登録する
     */
    async postSKU(requestParameters: PostSKUOperationRequest): Promise<void> {
        await this.postSKURaw(requestParameters);
    }

    /**
     * ユーザーを新規作成
     */
    async postUsersRaw(requestParameters: PostUsersOperationRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling postUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostUsersRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * ユーザーを新規作成
     */
    async postUsers(requestParameters: PostUsersOperationRequest): Promise<User> {
        const response = await this.postUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * 発注グループの情報を更新
     */
    async putDatabaseOrderGroupsRaw(requestParameters: PutDatabaseOrderGroupsOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putDatabaseOrderGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/database-order-groups`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutDatabaseOrderGroupsRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 発注グループの情報を更新
     */
    async putDatabaseOrderGroups(requestParameters: PutDatabaseOrderGroupsOperationRequest): Promise<void> {
        await this.putDatabaseOrderGroupsRaw(requestParameters);
    }

    /**
     * 在庫調整画面の情報更新
     */
    async putDestockingOrderGroupRaw(requestParameters: PutDestockingOrderGroupOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling putDestockingOrderGroup.');
        }

        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putDestockingOrderGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/destocking-order-groups/{OrderGroupId}`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutDestockingOrderGroupRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 在庫調整画面の情報更新
     */
    async putDestockingOrderGroup(requestParameters: PutDestockingOrderGroupOperationRequest): Promise<void> {
        await this.putDestockingOrderGroupRaw(requestParameters);
    }

    /**
     * 在庫調整画面の入庫週を前週に更新する
     */
    async putDestockingOrderGroupIncomingMdWeekBackwardRaw(requestParameters: PutDestockingOrderGroupIncomingMdWeekBackwardOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling putDestockingOrderGroupIncomingMdWeekBackward.');
        }

        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putDestockingOrderGroupIncomingMdWeekBackward.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/destocking-order-groups/{OrderGroupId}/incoming-md-week-backward`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutDestockingOrderGroupIncomingMdWeekBackwardRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 在庫調整画面の入庫週を前週に更新する
     */
    async putDestockingOrderGroupIncomingMdWeekBackward(requestParameters: PutDestockingOrderGroupIncomingMdWeekBackwardOperationRequest): Promise<void> {
        await this.putDestockingOrderGroupIncomingMdWeekBackwardRaw(requestParameters);
    }

    /**
     * 在庫調整画面の入庫週を前週に更新する
     */
    async putDestockingOrderGroupIncomingMdWeekForwardRaw(requestParameters: PutDestockingOrderGroupIncomingMdWeekForwardOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling putDestockingOrderGroupIncomingMdWeekForward.');
        }

        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putDestockingOrderGroupIncomingMdWeekForward.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/destocking-order-groups/{OrderGroupId}/incoming-md-week-forward`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutDestockingOrderGroupIncomingMdWeekForwardRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 在庫調整画面の入庫週を前週に更新する
     */
    async putDestockingOrderGroupIncomingMdWeekForward(requestParameters: PutDestockingOrderGroupIncomingMdWeekForwardOperationRequest): Promise<void> {
        await this.putDestockingOrderGroupIncomingMdWeekForwardRaw(requestParameters);
    }

    /**
     * 発注
     */
    async putDestockingOrderGroupOrderRaw(requestParameters: PutDestockingOrderGroupOrderOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling putDestockingOrderGroupOrder.');
        }

        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putDestockingOrderGroupOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/destocking-order-groups/{OrderGroupId}/order`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutDestockingOrderGroupOrderRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 発注
     */
    async putDestockingOrderGroupOrder(requestParameters: PutDestockingOrderGroupOrderOperationRequest): Promise<void> {
        await this.putDestockingOrderGroupOrderRaw(requestParameters);
    }

    /**
     * SKUの付加的な情報を更新する
     */
    async putSKUMetaRaw(requestParameters: PutSKUMetaOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling putSKUMeta.');
        }

        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putSKUMeta.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/skus/{productCode}/meta`.replace(`{${"productCode"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutSKUMetaRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * SKUの付加的な情報を更新する
     */
    async putSKUMeta(requestParameters: PutSKUMetaOperationRequest): Promise<void> {
        await this.putSKUMetaRaw(requestParameters);
    }

    /**
     * SKUの情報を一括更新する
     */
    async putSKUsRaw(requestParameters: PutSKUsOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putSKUs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/skus`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutSKUsRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * SKUの情報を一括更新する
     */
    async putSKUs(requestParameters: PutSKUsOperationRequest): Promise<void> {
        await this.putSKUsRaw(requestParameters);
    }

    /**
     * ユーザーを更新
     */
    async putUserRaw(requestParameters: PutUserOperationRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling putUser.');
        }

        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutUserRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * ユーザーを更新
     */
    async putUser(requestParameters: PutUserOperationRequest): Promise<User> {
        const response = await this.putUserRaw(requestParameters);
        return await response.value();
    }

}
