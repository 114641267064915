import styled from 'styled-components';

const EmailInputContainer = styled.div`
  margin-bottom: 12px;
`;

const PasswordInputContainer = styled.div`
  margin-bottom: 32px;
`;

export {
  EmailInputContainer,
  PasswordInputContainer,
};
