import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -44px;
  padding-right: 48px;
`;

const Container = styled.div`
  padding-bottom: 20px;
`;

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const SearchSKUsFormContainer = styled.div`
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 37px;
`;

const SKUsListTableContainer = styled.div`
  margin-left: 25px;
  margin-right: 32px;
  margin-top: 37px;
`;

const ToastContainer = styled.div`
  position: absolute;
  right: 22px;
  top: 107px;
  z-index: 399;
`;

export {
  ButtonContainer,
  Container,
  LoadingSpinnerContainer,
  SearchSKUsFormContainer,
  SKUsListTableContainer,
  ToastContainer,
};
