import { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import firebase from 'firebase/app';

import {
  Configuration,
  DefaultApi,
  DatabaseOrderGroup,
} from '../api-client';

export const useOwnOrderGroups = () => {
  const [ownOrderGroups, setOwnOrderGroups] = useState<DatabaseOrderGroup[] | undefined>(undefined);

  useEffect(() => {
    firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        return api.getOwnOrderGroups();
      })
      .then((response) => {
        setOwnOrderGroups(response.orderGroups);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, []);

  return ownOrderGroups;
};
