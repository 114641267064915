import { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import firebase from 'firebase/app';

import {
  DefaultApi,
  Configuration,
} from '../api-client';

export const useAlertSummary = () => {
  const [numberOfOrderNeededOrderGroups, setNumberOfOrderNeededOrderGroups] = useState<number | null | undefined>(undefined);
  const [numberOfAdjustmentNeededProducts, setNumberOfAdjustmentNeededProducts] = useState<number | null | undefined>(undefined);
  const [statusCode, setStatusCode] = useState<number | undefined>(undefined);

  useEffect(() => {
    firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        return api.getAlertSummary();
      })
      .then((response) => {
        setNumberOfOrderNeededOrderGroups(response.numberOfOrderNeededOrderGroups);
        setNumberOfAdjustmentNeededProducts(response.numberOfAdjustmentNeededProducts);

        // 正常系は200にしておく.
        setStatusCode(200);
      })
      .catch((err: Response) => {
        Sentry.captureException(err);
        setStatusCode(err.status);
      });
  }, []);

  return {
    numberOfOrderNeededOrderGroups,
    numberOfAdjustmentNeededProducts,
    statusCode,
  };
};
