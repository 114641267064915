/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatabaseOrderGroup
 */
export interface DatabaseOrderGroup {
    /**
     * 発注グループのID
     * @type {number}
     * @memberof DatabaseOrderGroup
     */
    id: number;
    /**
     * 発注グループの名前
     * @type {string}
     * @memberof DatabaseOrderGroup
     */
    name: string;
    /**
     * リードタイム（日）
     * @type {number}
     * @memberof DatabaseOrderGroup
     */
    leadtime: number;
    /**
     * サイクル回数(週)
     * @type {number}
     * @memberof DatabaseOrderGroup
     */
    numberOfOrderCycles: number;
    /**
     * サイクル週数(週)
     * @type {number}
     * @memberof DatabaseOrderGroup
     */
    numberOfOrderCycleWeeks: number;
    /**
     * 担当者のユーザーID
     * @type {number}
     * @memberof DatabaseOrderGroup
     */
    operatorId: number;
    /**
     * SKU数
     * @type {number}
     * @memberof DatabaseOrderGroup
     */
    numberOfSKUs: number;
}

export function DatabaseOrderGroupFromJSON(json: any): DatabaseOrderGroup {
    return DatabaseOrderGroupFromJSONTyped(json, false);
}

export function DatabaseOrderGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseOrderGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'leadtime': json['leadtime'],
        'numberOfOrderCycles': json['numberOfOrderCycles'],
        'numberOfOrderCycleWeeks': json['numberOfOrderCycleWeeks'],
        'operatorId': json['operatorId'],
        'numberOfSKUs': json['numberOfSKUs'],
    };
}

export function DatabaseOrderGroupToJSON(value?: DatabaseOrderGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'leadtime': value.leadtime,
        'numberOfOrderCycles': value.numberOfOrderCycles,
        'numberOfOrderCycleWeeks': value.numberOfOrderCycleWeeks,
        'operatorId': value.operatorId,
        'numberOfSKUs': value.numberOfSKUs,
    };
}


